import ItensService from "../services/ItensService";
// import { mapActions } from "vuex";
import opcoesMixins from "../mixins/opcoesMixins";


export default {
  data() {
    return {
      itemTipoOpcoes: [],
    };
  },

  beforeMount() {
    this.getItemTipo()
  },

  mixins:[opcoesMixins],

  methods: {
    async getItemTipo() {
      const response = await ItensService.getItemTipo();
      if (!response.error) {
        this.itemTipoOpcoes = this.trataOptions(response.data.data);
      }
    },


    verificarSeExisteItemTipo() {
      if (!this.$store.getters["opcoes/existe_item_tipo"]) {
        return this.getItemTipo();
      }
      this.itemTipoOpcoes = this.$store.state.opcoes.itemTipo;
    },
  },
};
