import MarcaService from "../../src/services/MarcaService";
// import { mapActions } from "vuex";
import opcoesMixins from "./opcoesMixins";


export default {
  data() {
    return {
      marcaOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getMarcas()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getMarcas() {
      const response = await MarcaService.getMarcas();
      if (!response.error) {
        this.marcaOpcoes = this.trataOptions(response.data.data);

        // this.ActionSetTipoAdmin(this.categoriaOpcoes);
      }
    },


    verificarSeExisteCategoria() {
      if (!this.$store.getters["opcoes/existe_tipo_tempo"]) {
        return this.getMarcas();
      }

      this.marcaOpcoes = this.$store.state.opcoes.marcas;
    },
  },
};
