export default {
  name: "CheckboxField",
  props: {
    vModel: {
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    responsive: {
      default: () => {
        return {
          cols: "12",
          sm: "12",
          md: "12",
          lg: "12",
        };
      },
      type: Object,
    },
  },

  mounted() {
    this.value = this.vModel;
  },

  watch: {
    value() {
      this.$emit("input", this.value);
    },

    vModel() {
      this.value = this.vModel;
    },
  },

  data: () => ({
    value: null,
  }),
};
