import api from "./Api"
import BaseService from "./BaseService";

export default {
  async getRecibos(params = "") {
    const config = {params: params}
    const response = await api.get(`/recibo`, config)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  async emitirReciboDownload(reciboid) {
    const response = await BaseService.fetchResponseFromAPIDirectGet(
      `recibo/${reciboid}`
    );

    return response;
  },

  async emitirRecibo(formData) {
    const response = await api.post(`recibo`,formData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async gerarRecibo(reciboid) {
    const response = await api.get(`recibo/${reciboid}`)
      .then((response) => response)
      .catch(err => err);

  return response;
  },
};
