import api from "./Api"

export default  {


    async getPessoa(params){
        const config = {params: params}
        const response = await api.get('/pessoa', config)
        .then((response) => response)
        .catch(err => err);
        return response;
    },

    async getTodasPessoa(){
        const response = await api.get('/pessoa/todas-usuarios')
        .then((response) => response)
        .catch(err => err);
        return response;
    },


    async logado(params){
        const config = {params: params}
        const response = await api.get('/pessoa/logado', config)
        .then((response) => response)
        .catch(err => err);
        return response;
    },

 
}