import TextField from "../../components/fields/textField/TextField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import SubCategoriasService from "../../services/SubCategoriasService";
import ConfirmPasswordDialog from "../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import { AssinaturaMixin, FiltroMixin, SnackbarMixin,VerificarTituloMixin } from "../../mixins";
import Vue from "vue";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "Subcategoria",

  components: { TextField, SelectField, FormDialog, ConfirmDialogButton, ConfirmPasswordDialog },

  data: () => ({
    headers: [
      { text: "Subcategoria", value: "nome", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    data: [],
    FuncionalidadeEnum: FuncionalidadeEnum,
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    form: {},
    showDialogName: false,
    filter: {},
    options: {
      administrador: [],
      subcategorias:[],
    },
    permissionsUser:[],
    formValid: true,
    formularioDialog: false,
    formTitle: "",
    formType: "",
    subcategoriaid: null,
    tituloExiste: false,
    tituloAnteriorValid: "",
    nomeOriginalEdit: "",
    loading: {
      table: false,
      fields: false,
      newItem: false,
    },
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
  }),

  mixins: [FiltroMixin, SnackbarMixin, AssinaturaMixin,VerificarTituloMixin],

  created() {
    this.initializeForm();
    this.initializeFilters();
    this.permissionsUser = Vue.prototype.$permissionsUser
  },
  mounted() {
    this.getSubcategorias();
  },

  watch:{
    page() {
      this.getSubcategorias();
    },
  },

  methods: {
    clear() {
      this.initializeForm();
      this.initializeFilters();
      this.getSubcategorias();
    },

    search() {
      this.getSubcategorias();
    },

    async getSubcategorias() {
      const params = {
        nome: this.filter.nome,
        page: this.page
      }
      this.loading.table = true;
      const response = await SubCategoriasService.getSubcategorias(params);
      this.data = response.data.data;
      this.pageCount = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      // Verifica se a página atual é maior que a última página após a exclusão
      if (this.page > this.lastPage) {
        this.page = 1; // Define a página atual como 1
        await this.getSubcategorias(); // Chama novamente getTags para recarregar os dados da página 1
      }
      this.loading.table = false;
    },

    activeFormDialog(title, type, subcategoriaId = null, nome) {
      this.formTitle = title;
      this.formType = type;
      if (type == "Edit") {
        this.subcategoriaid = subcategoriaId;
        this.form.nome = nome;
        this.nomeOriginalEdit = nome;
      }
      this.formularioDialog = !this.formularioDialog;
    },
  
    initializeForm() {
      this.form = {
        nome: "",
      };
    },

    initializeFilters() {
      this.filter = {
        nome: null
      };
    },

    async deletarSubcategoria(subcategoriaid) {
      try {
          this.loading.table = true
        const response = await SubCategoriasService.deleteSubcategoria(subcategoriaid);

        if (response.error) {
          this.showSnackbar(response.message.status.response, "error");
          return;
        } else {
          this.showSnackbar("A subcategoria foi deletada com sucesso!");
        }
          this.loading.table = true
      } finally {
        this.clear();
      }

    },
    reformatDate(dateStr) {
      let dateArray = dateStr.split("-");
      return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
    },

    async editarSubcategoria(subcategoriaId) {
      if(!this.$refs.form.validate()){
        return;
      }
    
      const response = await SubCategoriasService.editarSubcategoria(
        subcategoriaId,
        this.form,
      );

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }
      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A edição da subcategoria foi salva com sucesso");
      this.clear();
    },

    async cadastrarSubcategoria() {

  
      if(!this.$refs.form.validate() || this.loading.newItem){
        return;
      }
      this.loading.newItem = true
      
      const response = await SubCategoriasService.cadastrarSubcategoria(this.form);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.newItem = false
        return;
      }

      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A subcategoria foi cadastrada com sucesso");
      this.clear();
      this.loading.newItem = false

    },

    async verificarNomeIgual() {
      if (this.form.nome !== this.tituloAnteriorValid && this.form.nome !== this.nomeOriginalEdit) {
        const postman = { nome: this.form.nome };
        const response = await SubCategoriasService.verificaNome(postman);
        this.tituloExiste = response.data;
        
      } else {
        this.tituloExiste  = false
      }

      this.tituloAnteriorValid = this.form.nome; 
    },

    goTo(path) {
      this.$router.push(path);
    },

    save() {
      if (this.formType == "Edit") {
        this.editarSubcategoria(this.subcategoriaid);
      } else {
        this.cadastrarSubcategoria();
      }
    },
  },
};
