import Vue from "vue";
import PessoaPerfilTemporarioService from "../../../services/PessoaPerfilTemporarioService";
import PermutaService from "../../../services/PermutaService";
import { FiltroMixin} from "../../../mixins";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";

export default {
  name: "HistoricoSolicitacao",

  props: {
    activeDialog: {
      required: true,
      type: Boolean,
    },


  },

  mixins: [FiltroMixin],


  watch: {
    async activeDialog() {
      this.getPermutas();
      this.getSolicitacoesNivelAcesso();
      this.dialog = true;
    },

    permutaPage(){
      this.getPermutas()
    },

    acessoPage(){
      this.getSolicitacoesNivelAcesso()
    }

  },

  data: () => ({
    optionsPerfis: [],
    permissionsUser: [],
    dialog: false,
    permutaHeaders: [
      { text: "Material", value: "descricao", sortable: false },
      { text: "Tamanho que possui", value: "tam_tenho", sortable: false },
      { text: "Tamanho desejado", value: "tam_quero", sortable: false },
      { text: "Data de solicitação", value: "data_solicitacao", sortable: false },
      { text: "Usuário", value: "usuario_possui", sortable: false },
      { text: "Usuário status permuta", value: "status_permuta_usuario", sortable: false },
      { text: "Meu status permuta", value: "status_minha_permuta", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center"}
    ],
    FuncionalidadeEnum: FuncionalidadeEnum,
    permutaItems: [],
    permutaPage: 1,
    permutaPageCount: 0,

    acessoHeaders: [
      { text: "Perfil atual", value: "meu_perfis", sortable: false },
      { text: "Perfil Solicitado", value: "perfis_temporario", sortable: false },
      { text: "Data", value: "data_inicio", sortable: false },
      { text: "Data Limite", value: "data_fim", sortable: false },
      { text: "Justificativa", value: "justificativa", sortable: false },
      { text: "Data de solicitação", value: "created_at", sortable: false },
      { text: "Status", value: "status.nome", sortable: false },
    ],
    acessoItems: [],
    userprofile: [],
    acessoPage: 1,
    acessoPageCount: 0,
    loading: {
      tablePermutas: false,
      tableAcesso: false,
    }
  }),

  created(){
    this.permissionsUser = Vue.prototype.$permissionsUser
  },



  methods: {
    close() {
      this.$emit("on-close");
    },


    async getPermutas() {
      this.loading.tablePermutas = true;
      const response = await PermutaService.getPermuta({page:this.permutaPage});
      console.log('getPermutas', response.data)

      this.permutaPageCount = response.data.meta.last_page
      this.permutaItems = response.data.data;
      this.loading.tablePermutas = false;

    },

    async aceitaPermuta(item) {
      const response = await PermutaService.aceitaPermuta(item.id, item.permuta_id);
      if(!response.error){
        this.getPermutas();
      }
    },

    async getSolicitacoesNivelAcesso() {
      this.loading.tableAcesso = true;
      const response = await PessoaPerfilTemporarioService.getPessoaPerfilTemporario();
      this.acessoItems =  response.data.data
      this.acessoPageCount = response.data.meta.last_page
      this.loading.tableAcesso = false;
    },

  
  },
};
