import BaseService from "./BaseService";
import api from "./Api"

export default {
  async getLogRotaAlerta(page=1, query, withtrashed=false) {
    const endpoint =  query != null && query != '' ? `alerta?max=10&page=${page}&withtrashed=${withtrashed}&${query}` : `alerta?max=10&withtrashed=${withtrashed}&page=${page}`;

    const response = await BaseService.fetchResponseFromAPIGet(endpoint);

    const data = response.error ? [] : response.result;

    return data;
  },

  async getLog(formData) {  
      const response = await api.post(`log/search?`, formData)
        .then((response) => response)
        .catch(err => err);
      
        return response;
  },

  async getExportLogPDF(filter) {  
    const response = await api.post(`log/export-pdf`, filter)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
    
  async getExportLogCSV(filter) {  
    const response = await api.post(`log/export-csv`, filter)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
};
