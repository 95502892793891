import TextField from "../../../../components/fields/textField/TextField.vue";
import AutoCompleteField from "../../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
// import GrupoService from "../../../../services/GrupoService";
import MateriaisService from "../../../../services/MateriaisService";
import { AssinaturaMixin, SnackbarMixin } from "../../../../mixins";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import unidadesMixins from "../../../../mixins/unidadesMixins";
import TipoItemEnum from "../../../../enums/TipoItemEnum";
// import Vue from "vue";

export default {
  name: "DialogVincular",
  components: {
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    ConfirmPasswordDialog
  },

  props: {
    activeDialog: {
      default: true,
      type: Boolean
    },
    materiais: {
      default: () => [],
      type: Array
    }
  },

  mixins: [SnackbarMixin, AssinaturaMixin, unidadesMixins],

  data: () => ({
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },

    menorQuantidade: 0,
    dialog: false,
    formValid: true,
    form: {},
    options: {},
    loading: {
      users: false,
      unidades: false
    },
    TipoItemEnum: TipoItemEnum
  }),

  created() {
    this.initializeForm();
  },

  methods: {
    setMaterialInfo(){
      this.menorQuantidade = this.materiais.map(material => material.quantidade).reduce((a, b) => Math.min(a, b));
      const arrayDescricao = this.materiais.map(material => material.item.descricao);
      const descricoes = arrayDescricao.join(", ");
      this.form.material = descricoes;

      const arrayCodigos = this.materiais.map(material => material.codigo_catmas);
      const codigos = arrayCodigos.join(", ");
      this.form.idMaterial = this.materiais.map(material => material.id);
      this.form.codigo = codigos;
      this.form.item_tipo_id = this.materiais[0].item_tipo_id;
      this.form.item_tipo_nome = this.materiais[0].item_tipo_nome
    },

    initializeForm() {
      this.form = {
        material: "",
        codigo: "",
        patrimonio_serie: "",
        item_tipo_id: "",
        item_tipo_nome:"",
        unidade_id: "",
        idMaterial: [],
        quantidade: ""
      };
    },

    async prepareForm(){
      const postData = {
        ids: this.form.idMaterial,
        unidade_id: this.form.unidade_id,
        quantidade: this.form.quantidade,
        codigo: this.form.codigo,
        material: this.form.material
      }
      
      return postData;
    },
    
    async movimentarMaterial(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await MateriaisService.movimentarMaterial(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O material foi movimentado com sucesso");
      this.initializeForm();
      this.$refs.form.resetValidation();

      this.$emit("updateData");
      this.dialog = false;
    },
  },
};
