import ModeloService from "../services/ModeloService";
// import { mapActions } from "vuex";
import opcoesMixins from "./opcoesMixins";


export default {
  data() {
    return {
     modeloOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getModelos()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getModelos() {
      const response = await ModeloService.getModelos();
      if (!response.error) {
        this.modeloOpcoes = this.trataOptions(response.data.data);

        // this.ActionSetTipoAdmin(this.categoriaOpcoes);
      }
    },


    verificarSeExisteCategoria() {
      if (!this.$store.getters["opcoes/existe_tipo_tempo"]) {
        return this.getModelos();
      }

      this.modeloOpcoes = this.$store.state.opcoes.modelos;
    },
  },
};
