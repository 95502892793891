// import Vue from "vue";
import BaseService from "./BaseService";

export default {
  async getGrupos(query= null) {
    let response;

    const url = query != null ? `keycloak/grupos?querry=${query}` : `keycloak/grupos`;

    response = await BaseService.fetchResponseFromAPIGet(url);

    const data = response.error ? [] : response.result;

    return data;
  },

  async getGruposArvoreRaiz() {

    const url = `public/grupo/arvoreraiz` 

    let response = await BaseService.fetchResponseFromAPIGet(url);

    const data = response.error ? [] : response.result;

    return data;
  },

  async getGruposTodos() {

    const url = `public/grupo/todos` 

    let response = await BaseService.fetchResponseFromAPIGet(url);

    const data = response.error ? [] : response.result;

    return data;
  },

  async getGruposPorNome(gruponame) {
    let response;

    response = await BaseService.fetchResponseFromAPIGet(`keycloak/grupos/${gruponame}`);

    const data = response.error ? [] : response.result;

    return data;
  },

  async getUserGruposPorID(grupoid) {
    let response;

    response = await BaseService.fetchResponseFromAPIGet(`keycloak/grupos/${grupoid}/users`);

    const data = response.error ? [] : response.result;

    return data;
  },

  async getUserGruposArvoreGroup() {
    // const keycloak = Vue.prototype.$keycloak;
    // const userinfo = await keycloak.loadUserInfo();
    // const unidade = userinfo.groups[0];

    // let response = await BaseService.fetchResponseFromAPIGet(`public/grupo/arvore/${unidade}`);

    // const data = response.error ? [] : response.result;

    // return data;
  },
};
