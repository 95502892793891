import BaseService from "./BaseService";
import api from "./Api"

export default {


  async getMapCalor(params = "") {  
    const config = {params: params}
    const response = await api.get(`mapa-calor/search?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async activeCron() {
    const response = await BaseService.fetchResponseFromAPIGet(`calor/cron`);

    const data = response.error ? [] : response.result;

    return data;
  },


};
