import TextField from "../../../components/fields/textField/TextField.vue";
import FileField from "../../../components/fields/file-input-field/FileInputField.vue";
import AutoCompleteField from "../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../components/fields/selectField/SelectField.vue";
import ItensService from "../../../services/ItensService";
import MateriaisService from "../../../services/MateriaisService";
import { SnackbarMixin, FiltroMixin, AssinaturaMixin } from "../../../mixins";
import FormDialog from "../../../components/dialog/form/FormDialog.vue";
import DialogItensSelecionados from "./dialogItensSelecionados/DialogItensSelecionados.vue";
import DialogMovimentacao from "./dialogMovimentacao/DialogMovimentacao.vue";
import DialogVincular from "./dialogVincular/DialogVincular.vue";
import DialogDesvincular from "./dialogDesvincular/DialogDesvincular.vue";
import DialogEmitirRecibo from "./dialogEmitirRecibo/DialogEmitirRecibo.vue";
import DialogDisponibilizar from "./dialogDisponibilizar/DialogDisponibilizar.vue";
import DialogDescarga from "./dialogDescarga/DialogDescarga.vue";
import DialogDescarte from "./dialogDescarte/DialogDescarte.vue";
import DialogAutosoma from "./dialogAutosoma/DialogAutosoma.vue";
import TextCheckboxField from "../../../components/fields/text-checkbox-field/TextCheckboxField.vue";
import SelectCheckboxField from "../../../components/fields/select-checkbox-field/SelectCheckboxField.vue";
import CheckboxAutoCompleteField from "../../../components/fields/checkboxAutoComplete/CheckboxAutoCompleteField.vue";
// import moment from "moment";
import ReciboService from "../../../services/ReciboService";
import ConfirmPasswordDialog from "../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import FormularioAlertas from "../../alertas/formulario/FormularioAlertas.vue";
import ModeloDialog from "../../../components/dialog/modelo/ModeloDialog.vue";
import MarcaDialog from "../../../components/dialog/marca/MarcaDialog.vue";
import uploadCsvPatrimonio from "../../../components/dialog/uploadCsvPatrimonio/uploadCsvPatrimonio.vue";
import categoriaMixins from "../../../mixins/categoriaMixins";
import marcasMixins from "../../../mixins/marcasMixins";
import subcategoriaMixins from "../../../mixins/subcategoriaMixins";
import tagsMixins from "../../../mixins/tagsMixins";
import modelosMixins from "../../../mixins/modelosMixins";
import templatesMixins from "../../../mixins/templatesMixins";
import tipoAdminMixins from "../../../mixins/tipoAdminMixins";
import statusMaterialMixins from "../../../mixins/statusMaterialMixins";
import estadosMaterialMixins from "../../../mixins/estadosMaterialMixins";
import itemTipoMixins from "../../../mixins/itemTipoMixins";
import itensMixins from "../../../mixins/itensMixins";
import CampoTemplateEnum from "../../../enums/CampoTemplateEnum";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";
import TipoItemEnum from "../../../enums/TipoItemEnum";

export default {

  mixins: [SnackbarMixin, FiltroMixin, AssinaturaMixin, categoriaMixins, marcasMixins, subcategoriaMixins, tagsMixins, modelosMixins,
           templatesMixins, tipoAdminMixins, statusMaterialMixins, estadosMaterialMixins, itemTipoMixins, itensMixins],
  
  name: "EntradaMateriais",
  components: {
    uploadCsvPatrimonio,
    ModeloDialog,
    MarcaDialog,
    TextCheckboxField,
    CheckboxAutoCompleteField,
    SelectCheckboxField,
    FormDialog,
    FileField,
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    DialogItensSelecionados,
    DialogMovimentacao,
    DialogVincular,
    DialogDesvincular,
    DialogDisponibilizar,
    DialogDescarga,
    DialogDescarte,
    DialogEmitirRecibo,
    ConfirmPasswordDialog,
    DialogAutosoma,
    FormularioAlertas
  },

  data: () => ({
    FuncionalidadeEnum: FuncionalidadeEnum,
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
    formularioDialogAlert: false,
    form: {},
    options: {},
    formValid: false,
    loading: {
      itens: false,
      fieldsItens: false,
      tableMaterial: false,
      tableItens: false,
    },
    infoItem: {},
    formularioDialog: false,
    formularioDialogRecibos: false,
    showFilters: true,
    materialId: null,
    formTitle: "",
    formType: "",
    filter: {},
    itemsClear: [],
    selected: [],
    activeDialogVincular: false,
    headersItems: [],
    headersMateriais: [],
    items: [],
    itemsRecibos:[],
    itemsMateriais:[],
    materiaisSemVinculo: [],
    materiaisComVinculo: [],
    materiaisDisponibilizar: [],
    materiaisDescarga: [],
    materiaisDescarte: [],
    materiaisMovimentacao: [],
    permissionsUser: [],
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    initialForm: {},
    materialIdAlerta: null,
    itemId: "",
    expanded:[],
    materialEmitirRecibo: [],
    materiaisItem: [],
    unidadeUser: "",
    emitirReciboModal:false,
    btnTextFormDialog: "Voltar",
    expandedItemId: null,
    expandedLength: 0,
    pageMaterial: 1,
    pageCountMaterial: 0,
    lastPageMaterial: 1,
    itemsPerPageMaterial: 10,
    tipo_item_id: null,
    CampoTemplateEnum: CampoTemplateEnum,
    TipoItemEnum: TipoItemEnum
  }),

  async mounted(){
    this.initializeAlertForm();
  },

  created() {
    // this.permissionsUser = Vue.prototype.$permissionsUser;
    this.initializeFilters();
    this.initializeHeaders()
    this.getItens();
    this.initializeInfoItem();
    this.initializeForm();
  },

  watch: {
    selected(value){
      if(value.length > 0){
        this.verificaVinculados();
        this.verificaDisponibilizados();
        this.verificaDescarteDescarga();
        this.verificarMovimentacao();
      }
    },   

    expanded(value) {
      this.buscaMateriaisParaItem(value)
    },

    page() {
      this.getItens();
    },
  },

  // computed:{
  //   materialEmitirReciboComputed(){
  //     if(this.materialEmitirRecibo.id != this?.selected[this.selected.length-1]?.id){
  //       this.materialEmitirRecibo = this.selected[this.selected.length-1]
  //     }

  //     return this.materialEmitirRecibo
  //   }
  // },

  methods: {
    initializeAlertForm(){
      this.initialForm = {
        id: null,
        materialID: null,
        tipo: null,
        observacao: null,
        formula: null,
        comparativo: null,
        titulo: null,
        unidade: null,
        dataBase: null,
        utilizaDataBase: null,
        auxDataQuantidade: null,
        auxTipoData: null,
        unidadeData: null,
        data_fabricacao: null,
        data_validade: null,
        created_at: null,
        quantidade: null,
        demanda_ideal: null
      }
    },

    csvPatrimonio(patrimonio){
      this.form.patrimonio_serie = patrimonio.join(";");
      this.form.isBloco = true;
    },
    
    async verificarMovimentacao(){
      let materiaisConsumo = [];
      let materiaisPermanente = [];

      this.materiaisMovimentacao = [];

      await this.selected.forEach(material => {
        if(material.item.tipo_id == 1){
          materiaisPermanente.push(material);
        } else if(material.quantidade > 0) {
          materiaisConsumo.push(material);
        }
      });

      if(materiaisConsumo.length === this.selected.length) {
        this.materiaisMovimentacao = materiaisConsumo;
      } else if (materiaisPermanente.length === this.selected.length) {
        this.materiaisMovimentacao = materiaisPermanente;
      }
    },
    
    async verificaDisponibilizados(){
      let materiaisConsumo = [];
      let materiaisPermanente = [];

      this.materiaisDisponibilizar = [];

      await this.selected.forEach(material => {
        if(material.item.tipo == 1){
          materiaisPermanente.push(material);
        } else {
          materiaisConsumo.push(material);
        }
      });

      if(materiaisConsumo.length === this.selected.length) {
        this.materiaisDisponibilizar = materiaisConsumo;
      } else if (materiaisPermanente.length === this.selected.length && materiaisPermanente.length == 1) {
        this.materiaisDisponibilizar = materiaisPermanente;
      }
    },

    verificaDescarteDescarga() {
      this.materiaisDescarga = [];
      this.materiaisDescarte = [];

      let materiaisDescarte = [];
      let materiaisDescarga = [];

      this.materiaisDisponibilizar = [];

      this.selected.forEach(material => {
        if(material.item_tipo_id == 1){
          materiaisDescarga.push(material);
        } else {
          materiaisDescarte.push(material);
        }
      });

      if(materiaisDescarte.length === this.selected.length) {
        this.materiaisDescarte = materiaisDescarte;
      } else if (materiaisDescarga.length === this.selected.length) {
        this.materiaisDescarga = materiaisDescarga;
      }
    },

    verificaVinculados() {
      this.materiaisSemVinculo = [];
      this.materiaisComVinculo = [];

      this.selected.forEach(material => {
        if(material.vinculado != 1){
          this.materiaisSemVinculo.push(material);
        } else {
          this.materiaisComVinculo.push(material);
        }
      });
    },

    activeFormDialog(title, type, itemid = null, material = null) {
      this.initializeForm();
      this.initializeInfoItem();
      this.$refs.form.resetValidation()
      this.formTitle = title;
      this.formType = type;
      this.btnTextFormDialog = "Salvar"
  
      if (type == "Edit") {
        this.getInfoItem(itemid, material, type);
      }

      if (type == "Detalhe") {
        this.btnTextFormDialog = "Voltar"
        this.getInfoItem(itemid, material, type);
      }
      
      this.formularioDialog = !this.formularioDialog;
    },

    cadastrarAlerta(title, type, item = null, ) {
      this.initializeForm();
      this.initializeInfoItem();
      this.initializeAlertForm()
      this.$refs.form.resetValidation()
      this.formTitle = title;
      this.formType = type;
     
      this.formatFormAlert(item)
      this.materialIdAlerta =  this.initialForm;
      // if(this.selected.length != 0){
      //   this.materialIdAlerta  = this.selected.map(({ id }) => id)
      // }
           
      this.formularioDialogAlert = !this.formularioDialogAlert;
    },

    formatFormAlert(alertaSelecionado) {   
      this.initialForm.id = [alertaSelecionado.id];
      this.initialForm.materialID = [alertaSelecionado.id];
      this.initialForm.demanda_ideal = alertaSelecionado.demanda_ideal ?? null;
      this.initialForm.quantidade = alertaSelecionado.quantidade ?? null;
      this.initialForm.data_fabricacao = alertaSelecionado.data_fabricacao ?? null;
      this.initialForm.data_validade = alertaSelecionado.data_validade ?? null;
      this.initialForm.created_at = alertaSelecionado.created_at ?? null;   
    },

    emitirReciboOpen(item){
      this.materialEmitirRecibo = [item]
      this.emitirReciboModal = !this.emitirReciboModal;
    },
       
    async search() {
      this.expanded = [];
      this.getHeaders();  
      this.getItens();
    },

    getHeaders() {
      this.initializeHeaders();
      Object.keys(this.filter).forEach((key) => {
        const indexAux = this.headersItems.length - 1;
        if (this.filter[key]?.check) {
            this.headersItems.splice(indexAux, 0, {
              text: this.filter[key].headerName,
              value: `${key}[nome]`,
              sortable: false,
              width: 120,
          });
        }
      });
    },
      
    
    clear() {
      this.initializeFilters();
      this.getHeaders();
      this.getItens();
      // this.selected = []
    },

    async visualizarRecibo(materialId) {
      try{
        this.loading.recibo = true;
        const filter = {
         idMaterial: {
          operator: "E",
          value: materialId,
        }
      };
        const query =  this.createUrlFiltersCustom(filter);
        const data = await ReciboService.getRecibos(query);
        this.itemsRecibos = data;
        this.formularioDialogRecibos = true;
      
        // this.emitirRecibo(data[0].id); 
      } finally {
        
        this.loading.recibo = false;
      }
    },

    async emitirRecibo(reciboid) {
      this.loading.recibo = true;
      const data = await ReciboService.emitirRecibo(reciboid);
      
      this.exportBlob(data.result);

      this.initializeForm();
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.loading.recibo = false;
    },

    exportBlob(base64str){
      const url = this.geraBlob(base64str);
      window.open(url);
    },

    geraBlob(base64str){
      var binary = atob(base64str.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
      }
         
      var blob = new Blob( [view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);

      return url;
    },

    async prepareForm(){
      let campos = [];
      for (const campo of this.infoItem.campos) {
             campos.push({
            campo_template_id: campo.id,
            resposta: campo.resposta,
          })
      }
      this.form.campo_template_respostas = campos;

      if(this.tipo_item_id == TipoItemEnum.TIPO_ITEM_PERMANENTE) {
          this.form.quantidade = 1;
      }

      return this.form;
    },

    validaForm(){
      if(!this.$refs.form.validate()){
        return;
      }
      this.validaAssinatura(this.saveNew)
    },
    
    async saveNew(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await MateriaisService.cadastrarMaterial(postData);
      if (response.error) {
        const errorMessage = response.message?.status?.response || "Não foi possível completar a ação";
        this.showSnackbar(errorMessage, "error");
        return;
      }

      this.showSnackbar("O material foi cadastrado com sucesso");
      this.formularioDialog = !this.formularioDialog;
      this.clear();
    },

    async deletaMaterial(itemid) {
      this.loading.table = true;
      const response = await MateriaisService.deletaMaterial(itemid);
      
      if (response.error) {
        this.showSnackbar(response.message, "error");
        this.loading.table = false;
        return;
      }

      this.showSnackbar("O material foi deletado com sucesso!");
  
    },

    async saveEdit(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await MateriaisService.editarMaterial(postData, this.materialId);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O material foi editado com sucesso");
      this.formularioDialog = !this.formularioDialog;
      this.clear();
    },

    goTo(path) {
      this.$router.push(path);
    },

    validaTamanhoArquivo(value, maxLength) {
      if (value ) {
        let totalSize = 0;

        totalSize += value.size;

        return totalSize/1024/1000 <= maxLength;
      }
      return true;
    },

    validaRegrasArquivo(resposta, campo) {

      const size = this.validaTamanhoArquivo(resposta, campo.tamanho_maximo)

      if(!size){
        return `Os arquivos não podem superar ${campo.tamanho_maximo} MB`
      }

      if (campo.obrigatorio == 1) {
        return resposta != null ? true : 'É necessário selecionar um arquivo';
      }

      return true

    },

    async getItens() {
      this.loading.itens = true;
      const params = {
        categoria_id: this.filter.categoria.value,
        subcategoria_id: this.filter.subcategoria.value,
        codigo_catmas: this.filter.codigo_catmas.value,
        descricao_catmas: this.filter.descricao_catmas.value,
        tag_id: this.filter.tags.value,
        tipo_administrador_id: this.filter.tipo_administrador.value,
        modelo_id: this.filter.modelo.value,
        marca_id: this.filter.marca.value,
        template_id: this.filter.template.value,
        patrimonio_serie: this.filter.patrimonio_serie.value,
        unidade: this.filter.unidade.value,
        data_entrada: this.filter.dataEntrada.value,
        data_validade: this.filter.dataVal.value,
        page: this.page
      } 
      const data = await ItensService.getItens(params);
      this.items = this.trataItems(data.data.data);
      
      const arrayItens = data.data.data.map((item) => {
        return { text: item.codigo_catmas.descricao_catmas, value: item.id };
      });
      this.pageCount = data.data.meta.total;
      this.lastPage = data.data.meta.last_page;
      this.options.itens = this.geraOptionsDinamic(arrayItens, "Selecione");
      this.loading.itens = false;
    },

    trataItems(data) {
      let dataTratada = data.map(item => {
        item.materiais = null
        const arrayTags = [];
        item.tags.forEach(tagItem => {
            arrayTags.push(tagItem.nome);
        });
        item.tagsString = { nome: arrayTags.join(", ") };
        item.template = { nome: item.templates?.map(template => template.nome).join(", ") };
        item.descricao_catmas = { nome: item.codigo_catmas.codigo_catmas + ' - ' + item.codigo_catmas.descricao_catmas };
        item.marcaString = { nome: item?.marcas?.map(marca => marca.nome).join(", ") };
        item.modeloString = { nome: item.modelos?.map(modelo => modelo.nome).join(", ") };

        item.dataEntrada = { nome: item.created_at };    
        item.tipo = item.item_tipo.nome;
        item.pageMaterial = 1;
        return item;
      });
      return dataTratada;
    },

    async getInfoItem(itemid, material, type) {
      if (!itemid) return;
      this.initializeInfoItem();
      const response = await ItensService.getItemByID(itemid);
      const data = response.data.data[0];
      this.populateBasicInfo(data);
      if (type == 'Edit' || type == "Detalhe") {
        this.getEditData(material)
        this.form.administrador_id = material.administrador_id;
        this.form.codigo = itemid;
        this.materialId = data.id;
      }
      this.infoItem.campos = this.extractCamposTemplate(data, material?.campo_template_respostas, type);
      

      this.form.item_id = itemid;
      this.tipo_item_id = data.item_tipo.id;
      // this.form.codigo = itemid;
      this.loading.fieldsItens = false;
     
    },

    populateBasicInfo(data) {
      this.infoItem.administrador_id = data.tipo_administrador.id;
      this.infoItem.administrador_nome = data.tipo_administrador.nome;
      this.infoItem.tipo_id = data.item_tipo.id;
      this.infoItem.tipo_nome = data.item_tipo.nome;
      this.infoItem.marcas = this.extractIds(data.marcas);
      this.infoItem.modelos = this.extractIds(data.modelos);
      this.infoItem.tags_id = this.extractIds(data.tags);
      this.infoItem.categoria_id = data.categoria?.nome;
      this.infoItem.subcategoria_id = data.subcategoria?.nome;
      this.infoItem.catmas_id = data.codigo_catmas.codigo_catmas;
      this.infoItem.descricao_catmas = data.codigo_catmas.descricao_catmas;
      this.infoItem.descricao = data.descricao;
    },

    extractIds(array) {
      return (array || []).map(item => item.nome).join(', ');
    },

    extractCamposTemplate(data, campo_template_respostas, type) {
      return data.templates.flatMap(template =>
        template.campo_template.map(campo => this.transformCampoTemplate(campo, campo_template_respostas, type))
      );
    },

    transformCampoTemplate(campo, campo_template_respostas, type) {
      const transformedCampo = {
        id: campo.id,
        template_id: campo.template_id,
        tipo_campo_template_id: campo.tipo_campo_template_id,
        nome: campo.nome,
        texto_ajuda: campo.texto_ajuda,
        tamanho_maximo: campo.tamanho_maximo,
        mascara_id: campo.mascara_id,
        obrigatorio: campo.obrigatorio,
      };

      if ([CampoTemplateEnum.TIPO_CAMPO_TEMPLATE_SELECAO_UNICA,
        CampoTemplateEnum.TIPO_CAMPO_TEMPLATE_SELECAO_MULTIPLA].includes(campo.tipo_campo_template_id)) {
            transformedCampo.options = campo.opcoes.map(opcao => ({ value: opcao.id, text: opcao.nome }));
      }

      if (campo.tipo_campo_template_id === CampoTemplateEnum.TIPO_CAMPO_TEMPLATE_ARQUIVO) {
        transformedCampo.formatosString = this.formatosToString(campo.formatos);
      }

      if (type === "Edit"|| type == "Detalhe") {
        this.montaCampoResapostaMaterial(transformedCampo, campo_template_respostas)

      }

      return transformedCampo;
    },

    montaCampoResapostaMaterial(transformedCampo,campo_template_respostas) {
        // Encontra o campo correspondente pelo ID no array item.campo_template_respostas
      const campoCorrespondente = campo_template_respostas.find(campo_template_resposta => campo_template_resposta.campo_template_id === transformedCampo.id);
        // Se encontrou o campo correspondente, adiciona a resposta
        if (campoCorrespondente) {
          transformedCampo.resposta = campoCorrespondente.resposta;
        }
    },

    formatosToString(formatos) {
      return formatos.map(formato => this.getFormatoName(formato.tipo_template_formato_id)).join(', ');
    },

    getFormatoName(tipo_template_formato_id) {
      const formatoNames = {
        1: 'JPG', 2: 'PNG', 3: 'BMP', 4: 'DOC', 5: 'PDF', 6: 'MP3', 7: 'MP4',
        8: 'DOCX', 9: 'XLS', 10: 'XLSX', 11: 'PPT', 12: 'PPS', 13: 'SVG',
        14: 'CSV', 15: 'TXT',
      };
      return formatoNames[tipo_template_formato_id] || '';
    },

    async getEditData(material) {
      this.loading.fieldsItens = true;
      this.form.patrimonio = material.patrimonio;
      this.form.administrador_id = material.administrador_id;
      this.form.data_fabricacao = material.data_fabricacao;
      this.form.data_validade = material.data_validade;
      this.form.estado_id = material.estado_id;
      this.form.quantidade = material.quantidade
      this.form.demanda_ideal = material.demanda_ideal
      this.form.status_id = material.status_id;
      this.form.isBloco = false,

      this.loading.fieldsItens = false;
    },

    validaRules(resposta, campo) {
      if (campo.obrigatorio == 1 && !resposta && resposta != 0 && resposta == "") {
        return `Preencha o campo ${campo.nome.toLowerCase()}`;
      }

      if(campo?.op_aux?.mascara == 'email'){
        const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
        if(!pattern.test(resposta)){
          return "Campo é um email";
        }
      }

      return true;
    },

    initializeInfoItem() {
      this.infoItem = {
        administrador_id: "",
        images: "",
        marcas: "",
        modelos: "",
        templates: [],
        tags_id: "",
        tipo_id: "",
        categoria_id: "",
        subcategoria_id: "",
        catmas_id: "",
        descricao: "",
        descricao_catmas: "",
        item_id: "",
      };
    },

    geraOptionsDinamic(arrayOptions, defaultOption = "Selecione") {
      let options = [];
      options.push({ text: defaultOption, value: "" });

      arrayOptions.forEach((option) => {
        options.push({ text: option.text, value: option.value });
      });

      return options;
    },

    initializeHeaders() {
      this.headersItems = [
        { text: "Código", value: "codigo_catmas.codigo_catmas", sortable: false},
        { text: "Descrição", value: "descricao", sortable: false},

      ];

      this.headersMateriais = [
        { text: "Patrimônio/Série", value: "patrimonio", sortable: false, width: 120 },
        { text: "Quantidade", value: "quantidade", sortable: false},
        { text: "Status material", value: "status_id", sortable: false, width:120},
        { text: "Vínculo", value: "vinculo_sigrh_id", sortable: false, width: 120 },
        { text: "Disponibilizado", value: "disponibilizadoInfo", sortable: false, width: 120 },
        { text: "Ações", value: "actions", sortable: false, align: "center"}
      ];

      this.headersRecibos = [
        { text: "Id", value: "id", sortable: false},
        { text: "Entregue pelo Usuário", value: "userEntrega", sortable: false},
        { text: "Recebido pelo Usuário", value: "userRecebe", sortable: false},
        { text: "Entregue pela Unidade", value: "unidadeEntrega", sortable: false},
        { text: "Recebido pela Unidade", value: "unidadeRecebe", sortable: false},
        { text: "Ações", value: "actions", sortable: false, align: "center"}
      ]
    },

    async getMateriais(idItem, page) {
      this.loading.itens = true;
      const params = {page: page}
      this.materiaisItem = await MateriaisService.getMateriaisIdItem(idItem, params);
      this.pageCountMaterial = this.materiaisItem.data.total;
      this.lastPageMaterial =  this.materiaisItem.data.last_page;

      // Verifica se a página atual é maior que a última página após a exclusão
      if (page > this.lastPageMaterial) {
        page = 1; // Define a página atual como 1
        await this.getMateriais(idItem, page); // Chama novamente getTags para recarregar os dados da página 1
      }

      this.loading.itens = false;
      return this.materiaisItem
    },

    async atualizaMateriais(idItem, page) {
      this.loading.itens = true;
      const params = {page: page}
      this.materiaisItem = await MateriaisService.getMateriaisIdItem(idItem, params);
      this.pageCountMaterial = this.materiaisItem.data.total;
      this.lastPageMaterial =  this.materiaisItem.data.last_page;
      this.loading.itens = false;
      return this.materiaisItem
    },

    

    initializeFilters() {
      this.filter = {
        item_tipo: {
          filterName: "nome",
          headerName: "Tipo",
          check: false,
          value: null,
        },
        tipo_administrador: {
          filterName: "admType",
          headerName: "Administrador",
          check: false,
          value: null,
        },
        categoria: {
          filterName: "idCategoria",
          headerName: "Categoria",
          check: false,
          value: null,
        },
        codigo_catmas: {
          value: null,
          filterName: "codigo_catmas"
        },
        subcategoria: {
          filterName: "idSubcategoria",
          headerName: "Subcategoria",
          check: false,
          value: null,
        },
        tags: {
          filterName: "tags",
          check: false,
          value: null,
        },
        marca: {
          value: null,
        },
        modelo: {
          value: null,
        },
        descricao_catmas: {
          filterName: "descricao_catmas",
          headerName: "Descrição CATMAS",
          check: false,
          value: null,
        },
        descricao: {
          filterName: "descricao",
          value: null,
        },
        marcaString: {
          headerName: "Marca",
          check: false,
        },
        modeloString: {
          headerName: "Modelo",
          check: false,
        },
        tagsString: {
          headerName: "Tags",
          check: false,
        },
        template: {
          headerName: "Template",
          check: false,
          value: null,
        },
        patrimonio_serie: {
          value: null,
        },
        unidade: {
          headerName: "Unidade",
          check: false,
          value: null,
        },
        dataEntrada: {
          headerName: "Data de entrada",
          check: false,
          value: null,
        },
        dataVal: {
          headerName: "Data de validade",
          check: false,
          value: null,
        },
      };
    },

    initializeForm() {
      this.form = {
        item_id: "",
        campo_template_respostas: [],
        isBloco: false,
        patrimonio: "",
        administrador_id: "",
        data_fabricacao: "",
        data_validade: "",
        estado_id: "",
        quantidade: "",
        demanda_ideal: "",
        status_id: "",
      };
    },

    // toggleAll () {
    //   this.selected = this.selected.length === this.items.length
    //     ? []
    //     : this.items
    // },

    geraOptions(arrayOptions, defaultOption) {
      let options = [];
      options.push({ text: defaultOption, value: "" });

      arrayOptions.forEach((option, index) => {
        options.push({ text: option, value: index + 1 });
      });

      return options;
    },

    expandRow(item, event) {
      if(event.isExpanded) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item);
      }
    },
  
    async buscaMateriaisParaItem(value) {
      if (value.length > this.expandedLength) {
        const item = value[value.length - 1];
        try {
          if (item.materiais == null) {
            const materiais = await this.getMateriais(item.id);
            if (materiais.data.data.length > 0) {
              this.items.forEach(item => {
                if (materiais.data.data[0].item_id === item.id) {
                  item.materiais = materiais.data
                  item.lastPageMaterial = materiais.data.last_page
                  item.pageCountMaterial = materiais.data.total
                }
              });
            } else {
              item.materiais = [];
            }
          }
        } catch (error) {
          console.error('Erro ao obter materiais:', error);
          // Lide com o erro conforme necessário
        }
      }
    },

    async mudaPagina(page,value) {
     try {  
      const materiais = await this.getMateriais(value.id, page)
       this.items.forEach(item => {
              if (materiais.data.data[0].item_id === item.id) {
                item.materiais = materiais.data
              }
            });         
        } catch (error) {
          console.error('Erro ao obter materiais:', error);
          // Lide com o erro conforme necessário
        }
    },

    validatePatrimonio(value) {
      console.log(value, !/^\d+(;\d+)*$/.test(value))
      if (this.form.isBloco && value && !/^\d+(;\d+)*$/.test(value)) {
          return 'Formato inválido. Separe os números com ";" sem espaços.';
      }
      return true;
  },
    }
};