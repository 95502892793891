import TextField from "../../../../components/fields/textField/TextField.vue";
import AutoCompleteField from "../../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
import { AssinaturaMixin, SnackbarMixin } from "../../../../mixins";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import ConfirmPasswordTwoSteps from "../../../../components/dialog/ConfirmPasswordTwoSteps.vue/ConfirmPasswordTwoSteps.vue";
import ReciboService from "../../../../services/ReciboService";
import unidadesMixins from "../../../../mixins/unidadesMixins";
import pessoaMixins from "../../../../mixins/pessoaMixins";
// import Vue from "vue";

export default {
  name: "DialogEmitirRecibo",
  components: {
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    ConfirmPasswordDialog,
    ConfirmPasswordTwoSteps
  },

  props: {
    activeDialog: {
      default: true,
      type: Boolean
    },
    materiais: {
      default: () => [],
      type: Array
    },

    showBtn:{
      default: true
    }
  },

  mixins: [SnackbarMixin, AssinaturaMixin, unidadesMixins, pessoaMixins],

  data: () => ({
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    showDialogConfirmPasswordTwoSteps: false,
    formConfirmTwoSteps: {
      password: "",
      user: "",
    },
    send: {
      function: () => { },
      params: null,
    },
    permissionsUser:[],

    dialog: false,
    formValid: true,
    form: {},
    options: {},
    loading: {
      users: false,
      unidades: false,
      recibo: false
    },
  }),

  watch: {
    activeDialog() {
      this.dialog = !this.dialog;
      this.initializeForm();
      // this.permissionsUser = Vue.prototype.$permissionsUser
      this.setMaterialInfo();
    },
  },



  methods: {
    setMaterialInfo(){
      console.log(this.materiais, 'dilgog')
      const arrayDescricao = this.materiais.map(material => material.item.descricao);
      const descricoes = arrayDescricao.join(", ");
      this.form.material = descricoes;
      const arrayCodigos = this.materiais.map(material => material.codigo_catmas);
      const codigos = arrayCodigos.join(", ");
      this.form.idMaterial = this.materiais.map(material => material.id);
      this.form.codigo = codigos
      console.log(  this.form)
    },

    close() {
      this.$emit("closed");
      this.dialog = !this.dialog;
    },

    initializeForm() {
      this.form = {
        material: "",
        codigo: "",
        unidade_militar: "",
        sigrh_receber_id: "",
        bm_militar: "",
        idMaterial: ""
      };
    },

    findBmMilitar(){
      const sigrhRecebe = this.pessoasOpcoes.find((pessoa) => pessoa.value == this.form.sigrh_receber_id);
      if(sigrhRecebe){
        this.form.bm_militar = sigrhRecebe.numero;
      } else {

        this.form.bm_militar = false;
      }
    },

    async prepareForm(){
      const postData = {
        material_id: this.form.idMaterial[0],
        sigrh_receber_id: this.form.sigrh_receber_id,
        unidade_receber_id: this.form.unidade_militar
      }
    
      return postData;
    },
    
    async gerarRecibo(){
      if(!this.$refs.form.validate()){
        return;
      }

      this.loading.recibo = true;
      
     const postData = await this.prepareForm();

      const response = await ReciboService.emitirRecibo(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O recibo foi gerado com sucesso");

      this.loading.recibo = false;

      this.emitirRecibo(response.data.id);
      this.close();
    },

    async emitirRecibo(reciboid) {
      this.loading.recibo = true;
      try {
        const response = await ReciboService.gerarRecibo(reciboid);
    
        // Criar um blob com o conteúdo do PDF
        const blob = new Blob([response.data], { type: 'application/pdf' });
    
        // Criar um URL temporário para o blob
        const url = window.URL.createObjectURL(blob);
    
        // Criar um link
        const link = document.createElement('a');
        link.href = url;
    
        // Definir o nome do arquivo
        link.setAttribute('download', 'recibo.pdf');
    
        // Adicionar o link à página e acioná-lo para iniciar o download
        document.body.appendChild(link);
        link.click();
    
        // Limpar o URL temporário após o download ser concluído
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Erro ao emitir recibo:', error);
      } finally {
        this.loading.recibo = false;
      }
    },
  },
};
