import api from "./Api"

export default {

  async getTipoAdmin(){
    const response = await api.get(`tipo-admin`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

};
