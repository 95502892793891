import Templates from "@/views/templates/Templates.vue";
import ConsultaTemplates from "@/views/templates/consultar/ConsultarPage.vue";
import ItemTemplates from "@/views/templates/item/ItemPage.vue";
import IdentificacaoTemplates from "@/views/templates/item/identificacao/IdentificacaoPage.vue";
import CamposTemplates from "@/views/templates/item/campos/CamposPage.vue";

const path = "/templates";
const templateRoutes = {
  path,
  name: "Templates",
  redirect: `${path}/consultar`,
  component: Templates,
  children: [
    {
      path: `${path}/consultar`,
      component: ConsultaTemplates,
      meta: {
        permissionRequired: []
      }
    },
    {
      path: `${path}/cadastrar`,
      component: IdentificacaoTemplates,
      meta: {
        permissionRequired: []
      }
    },
    {
      path: `${path}/item`,
      component: ItemTemplates,
      children: [
        {
          name: "TemplateIdentificacao",
          path: `${path}/:templateid/editar`,
          component: IdentificacaoTemplates,
          meta: {
            permissionRequired: []
          }
        },
        {
          name: "TemplateCampos",
          path: `${path}/:templateid/campos`,
          component: CamposTemplates,
          meta: {
            permissionRequired: []
          }
        },
      ],
    },
  ],
};

export default templateRoutes;
