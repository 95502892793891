import api from "./Api"

export default  {


    async getPerfil(params){
        const config = {params: params}
        const response = await api.get(`/pessoa_perfil/${params.id}`, config)
        .then((response) => response)
        .catch(err => err);
        return response;
    },

    async insertPessoaPerfil(postData){
        
        const response = await api.post('/pessoa_perfil', postData)
        .then((response) => response)
        .catch(err => err);
 

        return response;
    },



 
}