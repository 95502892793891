export default {
  name: "FileField",
  props: {
    rules: {
      default: () => [],
      type: Array,
    },
    responsive: {
      default: () => {
        return {
          cols: "12",
          sm: "12",
          md: "12",
          lg: "12",
        };
      },
      type: Object,
    },
    vModel: {
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: "mdi-paperclip",
      type: String,
    },
    accept: {
      default: "*/*",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
  },

  mounted() {
    if (this.vModel) {
      this.value = this.vModel;
    }
  },

  watch: {
    value(input) {
      this.$emit("input", input ?? []);
    },
    
    vModel(input) {
      this.value = input ?? [];
    },
  },

  methods: {
    deleteChip(index) {
      this.value.splice(index, 1);
    },
  },

  data: () => ({
    value: [],
  }),
};
