export default {
  name: "Snackbar",

  props: {
    snackbar: {
      required: true,
      type: Object,
    },
  },
};
