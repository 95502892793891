import CodigoCatmasService from "../services/CodigoCatmasService";
// import { mapActions } from "vuex";
import opcoesMixins from "./opcoesMixins";


export default {
  data() {
    return {
      codigoCatmasOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getCodigoCatmas()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getCodigoCatmas() {
      const response = await CodigoCatmasService.getCodigoCatmas();
      console.log(response.data.data, 'catmas')
      if (!response.error) {
        this.codigoCatmasOpcoes = this.trataOptionsParaCodigoCatmas(response.data.data);

        // this.ActionSetTipoAdmin(this.categoriaOpcoes);
      }
    },


    verificarSeExisteCodigoCatmas() {
      if (!this.$store.getters["opcoes/existe_tipo_tempo"]) {
        return this.getCodigoCatmas();
      }

      this.codigoCatmasOpcoes = this.$store.state.opcoes.codigoCatmas;
    },
  },
};
