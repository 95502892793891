import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dialog: {
      show: false,
      menssage: "",
      color: "success",
    },
    admGlobal:false,
    funcionalidades:[],
    user:{},
  },

  mutations: {
    async showDialog(state, payload) {
      const payloadData = await payload;
      state.dialog.menssage = payloadData.menssage;
      state.dialog.color = payloadData.type;
      state.dialog.show = true;
    },

    async setAdmGlobal(state, payload){
      state.admGlobal = payload;
    },

    async setFuncionalidades(state, payload){
      state.funcionalidades = payload;
    },

    async setUser(state, payload){
      state.user = payload;
    }
  },
});
