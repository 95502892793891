import TextField from "../../components/fields/textField/TextField.vue";
import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import ConfirmDialog from "../../components/dialog/confirm/ConfirmDialog.vue";
import TagsService from "../../services/TagsService";
import { FiltroMixin, SnackbarMixin, AssinaturaMixin, VerificarTituloMixin } from "../../mixins";
import ConfirmPasswordDialog from "../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import Vue from "vue";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "Tag",
  components: {
    TextField,
    FormDialog,
    ConfirmDialogButton,
    ConfirmPasswordDialog,
    ConfirmDialog
  },
  data: () => ({
    headers: [
      { text: "Título", value: "nome", sortable: false },
      { text: "Data de modificação", value: "updated_at", sortable: false },
      // { text: "Modificado por", value: "usuario", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    FuncionalidadeEnum: FuncionalidadeEnum,
    data: [],
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    filter: {},
    form: {},
    formularioDialog: false,
    formValid: true,
    tagid: null,
    showDialogName:false,
    formTitle: "",
    formType: "",
    loading: {
      table: false,
      fields: false,
      newItem:false,
    },
    tituloExiste: false,
    tituloAnteriorValid: "",
    nomeOriginalEdit: "",
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    permissionsUser:[],
    send: {
      function: () => { },
      params: null,
    },
  }),

  mixins: [FiltroMixin, SnackbarMixin, AssinaturaMixin,VerificarTituloMixin],

  created() {
    this.initializeFilter();
    this.initializeForm();
    this.permissionsUser = Vue.prototype.$permissionsUser;

  },

  mounted() {
    this.getTags();
  },

  watch:{
    page(){
      this.getTags();
    }
  },

  methods: {
    search() {
      this.getTags();
    },

    async getTags() {
      this.loading.table = true;      
      const params = {
        nome: this.filter.nome,
        page: this.page
      }

      const response = await TagsService.getTags(params);
      this.data = response.data.data;
      this.pageCount = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      // Verifica se a página atual é maior que a última página após a exclusão
      if (this.page > this.lastPage) {
        this.page = 1; // Define a página atual como 1
        await this.getTags(); // Chama novamente getTags para recarregar os dados da página 1
      }
      this.loading.table = false;
    
    },

    activeFormDialog(title, type, item = null) {
      this.initializeForm();
      this.$refs.form.resetValidation();
      this.formTitle = title;
      this.formType = type;

      if (type == "Edit") {
        this.tagid = item.id;
        this.getEditData(item);
      } else {
        this.$refs.form.resetValidation();
      }
      this.formularioDialog = !this.formularioDialog;
    },

     getEditData(item = null) {
      this.loading.fields = true;

      this.form.nome = item.nome;

      this.nomeOriginalEdit = item.nome;
      this.loading.fields = false;
    },

    async deletaTag(tagid) {
      const response = await TagsService.deleteTag(tagid);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      
      await this.getTags();
      this.showSnackbar("A tag foi deletada  com sucesso!");
      this.clear();
    },

    initializeFilter() {
      this.filter = {
        nome: null,
      };
    },


    initializeForm() {
      this.form = {
        nome: "",
      };
    },

    clear() {
      this.initializeForm();
      this.getTags();
    },

    reformatDate(dateStr) {
      let dateArray = dateStr.split("-");
      return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
    },


    async editarTag(tagid) {
      if(!this.$refs.form.validate()){
        return;
      }
      
      const response = await TagsService.editarTag(tagid, this.form);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }
      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A edição da tag foi salva com sucesso");
      this.clear();
      this.loading.newItem = false
    },

    async cadastrarTag() {
      if(!this.$refs.form.validate() || this.loading.newItem){
        return;
      }
      this.loading.newItem = true
      
    
      const response = await TagsService.cadastrarTag(this.form);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.newItem = false

        return;
      }

      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A tag foi cadastrada com sucesso");
      this.clear();
      this.loading.newItem = false

    },

    async verificarNomeIgual() {
      console.log(this.form.nome , this.nomeOriginalEdit, this.tituloAnteriorValid)
      if (this.form.nome !== this.tituloAnteriorValid && this.form.nome !== this.nomeOriginalEdit) {
        const postman = { nome: this.form.nome };
        const response = await TagsService.verificaNome(postman);  

        this.tituloExiste = response.data;
  
      } else {
        this.tituloExiste  = false
      }

      this.tituloAnteriorValid = this.form.nome; 
    },

    save() {
      if (this.formType == "Edit") {
        this.editarTag(this.tagid);
      } else {
        this.cadastrarTag();
      }
    },
  },
};
