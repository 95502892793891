export default {
  methods: {
    exportBlob(base64str){
      const url = this.geraBlob(base64str);
      window.open(url);
    },

    geraBlob(base64str){
      var binary = atob(base64str.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
      }
         
      var blob = new Blob( [view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);

      return url;
    },
  },
};
