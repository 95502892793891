export default {
  methods: {
    trataOptions(arrayOptions) {
      let options = [];

      arrayOptions.forEach((option) => {
        options.push({ text: option.nome, value: option.id});
      });
      return options;
    },

    trataOptionsParaCategoria(arrayOptions) {
      let options = [];

      arrayOptions.forEach((option) => {
        options.push({ text: option.nome, value: option.id, subcategoria_id: option?.subcategoria_id });
      });
      return options;
    },

    trataOptionsParaCodigoCatmas(arrayOptions) {
      let options = [];

      arrayOptions.forEach((option) => {
        options.push({ text: option.codigo + ' - ' + option.descricao, value: option.id, });
      });
      return options;
    },

    trataOptionsParaMateriais(arrayOptions) {
      let options = [];

      arrayOptions.forEach((option) => {
        options.push({
          text: option.patrimonio + ' - ' + option.item.descricao, value: option.id, quantidade: option.quantidade,
          demanda: option.demanda_ideal, fabricacao: option.data_fabricacao, vencimento: option.data_validade,
          entrada: option.created_at
        });
      });

      return options;
    },

    trataOptionsParaPessoas(arrayOptions) {
      let options = [];

      arrayOptions.forEach((option) => {
        options.push({ text: option.nome, value: option.id, numero: option.numero});
      });
      return options;
    },

    trataOptionsParaItens(arrayOptions) {
      let options = [];

      arrayOptions.forEach((option) => {
        options.push({ text: option.codigo_catmas, value: option.id, catmas_id: option.catmas_id,
           descricao: option.descricao, descricao_catmas: option.descricao_catmas});
      });
      return options;
    },
  },
};
