import api from "./Api"

export default  {


    async getFuncionalidade(params){
        const config = {params: params}
        const response = await api.get('/funcionalidade/search', config)
        .then((response) => response)
        .catch(err => err);
        return response;
    },



 
}