export const TEMPLATES = {
  options: {
    IMPUT: ["Texto", "Seleção única", "Seleção Múltipla", "Data", "Arquivo"],
  },
}

export const LOG = {
  options: {
    ACOES: [
      {
        text: "Cadastro Material",
        value: "material.store",
      },
      {
        text: "Edição Material",
        value: "material.update",
      },
      {
        text: "Deletar Material",
        value: "material.destroy",
      },
      {
        text: "Vincular Material",
        value: "material.vincular",
      },
      {
        text: "Disponibilizar Material",
        value: "material.disponibilizar",
      },
      {
        text: "Vincular Disponibilização do Material",
        value: "material.vinculardisponibilizado",
      },

      {
        text: "Listar Material Disponibilizado",
        value: "public.material.listardisponibilizado",
      },    
      {
        text: "Descarga/Descarte Material",
        value: "material.descarga",
      },
      {
        text: "Desvincular Material",
        value: "material.desvincular",
      },
      {
        text: "Cadastro Item",
        value: "item.store",
      },
      {
        text: "Edição Item",
        value: "item.update",
      },
      {
        text: "Edição em Lote",
        value: "item.update.lote",
      },
      {
        text: "Deletar Item",
        value: "item.destroy",
      },

      {
        text: "Cadastro Categoria",
        value: "categoria.store",
      },

      {
        text: "Edição Categoria",
        value: "categoria.update",
      },

      {
        text: "Deletar Categoria",
        value: "categoria.destroy",
      },

      {
        text: "Cadastro Perfil",
        value: "perfil.store",
      },

      {
        text: "Edição Perfil",
        value: "perfil.update",
      },

      {
        text: "Deletar Perfil",
        value: "perfil.destroy",
      },

      
      {
        text: "Cadastro Template",
        value: "template.store",
      },

      {
        text: "Edição Template",
        value: "template.update",
      },
      
      {
        text: "Deletar Template",
        value: "template.destroy",
      },
      {
        text: "Cópia Template",
        value: "template.clone",
      },
      {
        text: "Cadastro Tag",
        value: "tag.store",
      },

      {
        text: "Edição Tag",
        value: "tag.update",
      },
      {
        text: "Deletar Tag",
        value: "tag.destroy",
      },

      {
        text: "Cadastro Alerta",
        value: "alerta.store",
      },

      {
        text: "Edição Alerta",
        value: "alerta.update",
      },
      {
        text: "Deletar Alerta",
        value: "alerta.destroy",
      },
      {
        text: "Perfil Adicionado",
        value: "perfil.addToUser",
      },
      {
        text: "Edição Perfil",
        value: "perfil.update",
      },

      {
        text: "Aprovar Disponibilização de Material",
        value: "material.aprovar.disponibilizado",
      },


      {
        text: "Cancelar Disponibilização de Material",
        value: "material.cancelar.disponibilizacao",
      },

      {
        text: "Finalizar Alerta",
        value: "alerta.finalizar",
      },

      {
        text: "Adicionar Perfil ao Usuário",
        value: "perfil.addToUser",
      },

      {
        text: "Aprovar Movimentação Material",
        value: "material.aprovar.movimentacao",
      },
      {
        text: "Aprovação Vinculo Material",
        value: "material.aprovarvincular",
      },
      {
        text: "Confirmar Solicitação",
        value: "solicitacao.confirmar",
      },

    ]
  }
}

export const ALERTA = {
  enum:{
    status: {'0': 'Excluído', '1': 'Ativo', '2': 'Alterado', '3': 'Finalizado'},
    tipo: ["Quantidade", "Vencimento", "Manutenção", "Tempo Uso"]

  },
  options:{ 
    status: [
    {    
      text:'Excluído',
      value:'0'
    },
    {
      text:'Ativo',
      value:'1'
    },
    {
      text:'Alterado',
      value:'2'
    },
    {
      text:'Finalizado',
      value:'3'
    },

  ],
  tipo: [
    {
      text:'Quantidade',
      value:1
    },
    {
      text:'Vencimento',
      value:2
    },
    {
      text:'Manutenção',
      value:3
    },
    {
      text:'Tempo Uso',
      value:4
    },

  ]
},

  
};


export const ITENS = {
  options: {
    TIPO: ["Permanente", "Consumo (Controlado)", "Consumo (descartável)"],
    ESTADO_CONSERVACAO: ["Novo", "Bom estado", "Usado", "Velho"],
    STATUS_MATERIAL: ["Em uso", "Em estoque", "Em manutenção", "Aguardando descarga", "Aguardando descarte"],

  },

  cursos: {
    CRITERIOS_ORDENACAO: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Ordem crescente por data de inscrição",
        value: 1,
      },
      {
        text: "Ordem descrescente por hierarquia de posto ou graduação",
        value: 2,
      },
      {
        text: "Ordem alfabética",
        value: 3,
      },
    ],
    TIPOS_FORM: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Interno",
        value: 1,
      },
      {
        text: "Externo",
        value: 2,
      },
      {
        text: "Híbrido",
        value: 3,
      },
      {
        text: "Processo seletivo",
        value: 4,
      },
    ],
    TIPOS_FILTRO: [
      {
        text: "Todas",
        value: "",
      },
      {
        text: "Criado",
        value: "1",
      },
      {
        text: "Ativo",
        value: "2",
      },
      {
        text: "Encerrado",
        value: "3",
      },
      {
        text: "Suspenso",
        value: "4",
      },
      {
        text: "Excluído",
        value: "5",
      },
    ],
    TIPOS_FASES_FORM: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Inscrição",
        value: 1,
      },
      {
        text: "Recurso",
        value: 2,
      },
      {
        text: "Análise",
        value: 3,
      },
      {
        text: "Execução",
        value: 4,
      },
      {
        text: "Avaliação",
        value: 5,
      },
      {
        text: "Resultado",
        value: 6,
      },
    ],
    TIPOS_FASES_FILTRO: [
      {
        text: "Todas",
        value: "",
      },
      {
        text: "Inscrição",
        value: 1,
      },
      {
        text: "Recurso",
        value: 2,
      },
      {
        text: "Análise",
        value: 3,
      },
      {
        text: "Execução",
        value: 4,
      },
      {
        text: "Avaliação",
        value: 5,
      },
      {
        text: "Resultado",
        value: 6,
      },
    ],
    TIPOS_DISCIPLINA_FORM: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Teórica",
        value: 1,
      },
      {
        text: "Prática",
        value: 2,
      },
    ],
  },
  templates: {
    TIPOS_FORM: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Inscrição",
        value: 1,
      },
      {
        text: "Recurso",
        value: 2,
      },
    ],
    TIPOS_FILTRO: [
      {
        text: "Todos",
        value: "",
      },
      {
        text: "Inscrição",
        value: "1",
      },
      {
        text: "Recurso",
        value: "2",
      },
    ],
    CAMPOS_TIPO: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Seleção Múltipla",
        value: "Seleção Múltipla",
      },
      {
        text: "Texto",
        value: "Texto",
      },
      {
        text: "Seleção Única",
        value: "Seleção Única",
      },
      {
        text: "Upload de arquivo",
        value: "Upload de arquivo",
      },
    ],
    CAMPOS_TEXT_MASCARA: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Nenhuma",
        value: "text",
      },
      {
        text: "Data",
        value: "date",
      },
      {
        text: "E-mail",
        value: "email",
      },
      {
        text: "Telefone",
        value: "tel",
      },
    ],
    CAMPOS_OBRIGATORIO: [
      {
        text: "Selecione",
        value: "",
      },
      {
        text: "Sim",
        value: "Sim",
      },
      {
        text: "Não",
        value: "Não",
      },
    ],
    CAMPOS_FILES_FORMAT: [
      "jpg",
      "png",
      "bmp",
      "doc",
      "pdf",
      "mp3",
      "mp4",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pps",
      "svg",
      "csv",
      "txt",
    ],
  },
};
