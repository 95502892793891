import BaseService from "./BaseService";
import api from "./Api"

export default {


    async getPermuta(params){
      const config = {params: params}
      const response = await api.get(`permuta/search`, config)
      .then((response) => response)
      .catch(err => err);
      return response;
  },




  async aceitaPermuta(id, permutaId) {
    return await BaseService.fetchResponseFromAPIGet(`${id}/permuta/aceitar/${permutaId}`);
  },

  async cadastrarPermuta(formData) {
    const postData = JSON.stringify(formData);
    const response = await BaseService.fetchResponseFromAPIPost(
      `permuta`,
      postData
    );

    return response;
  },

  async excluirPermuta(permutaid) {
    const response = await api.delete(`permuta/${permutaid}`)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
};
