import TextField from "../../../../components/fields/textField/TextField.vue";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import { AssinaturaMixin } from "../../../../mixins";
import PerfilService from "../../../../services/PerfilService";
import FormDialog from "../../../../components/dialog/form/FormDialog.vue";
import { SnackbarMixin } from "../../../../mixins";
import FuncionalidadeService from "../../../../services/FuncionalidadeService";

export default {
  name: "DialogFuncionalidades",
  components: {
    TextField,
    ConfirmPasswordDialog,
    FormDialog,
  },
  props: {
    activeDialog: {
      type: Boolean,
      default: false,
    },
    perfil: {
      required: true,
    },
  },
  mixins: [SnackbarMixin, AssinaturaMixin],
  data: () => ({
    headers: [
      { text: "Funcionalidade", value: "nome", sortable: false },
      { text: "", value: "atribuir", sortable: false, width: "100" },
    ],
    data: [],
    errors: [],
    disabled: {
      nome: false,
      funcionalidades: false,
    },
    form: {},
    formValid: true,
    loading: {
      table: false,
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: null,
    showFormDialog: false,
    showDialogConfirmPassword: false,
    formConfirmPassword: {},
    send: {
      function: () => {},
      params: null,
    },

    funcionalidades: [],
  }),

  watch: {
    activeDialog() {
      if (this.perfil) {
        this.showFormDialog = !this.showFormDialog;
        this.initializeForm();
        this.getEditData();
      }
    },

    page(){
      this.getFuncionalidades()
    }
  },

  created() {
    this.getFuncionalidades();
    this.initializeFormConfirmPassword();
    this.initializeForm();
  },

  methods: {
    goTo(path) {
      this.$router.push(path);
    },

    async getEditData() {
      this.disabled.funcionalidades = true;
  
      if(this.perfil.funcionalidades){
        this.form.id_funcionalidade = this.perfil.funcionalidades.map((item) =>{ return item.id});
      }

      this.disabled.funcionalidades = false;
    },

    async getFuncionalidades() {
      this.loading.table = true;

      const params = {
        page: this.page,
        nome: this.search
      }

      const response = await FuncionalidadeService.getFuncionalidade(params);

      if(!response.error){
        this.funcionalidades = response.data.data
        this.loading.table = false;
        this.pageCount = response.data.last_page
      }
    },

    async searchFuncionalidades() {
      this.page = 1;
      await this.getFuncionalidades();
    },

    initializeForm() {
      this.form = {
        id_funcionalidade: [],
      };
    },

    async save() {
      this.errors = [];

      const postData = {
        funcionalidade_id: this.form.id_funcionalidade
      };

      const response = await PerfilService.updatePerfil(
        postData,
        this.perfil.id,
      );

      if (!response.error) {
        this.showSnackbar(
          "As funcionalidades do perfil foram atualizadas com sucesso",
        );
        this.showFormDialog = !this.showFormDialog;
        this.$emit("funcionalidadeCriado");
      } else {
        const message = await response.response;
        this.showSnackbar(message, "error");
        this.errors.push(message);
      }
    },
  },
};
