<template>
  <div class="d-contents">
    <v-menu v-model="menu" bottom offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon color="primary" :disabled="loading || notifications.length === 0" v-on="on" @click="menu = !menu">
          <v-icon v-if="notifications.length === 0" color="primary">mdi-bell-outline</v-icon>
          <v-icon v-if="notifications.length !== 0" color="primary">mdi-bell-badge</v-icon>
          {{ notifications.length }}
        </v-btn>
      </template>

      <v-card class="mx-auto" min-width="400" tile max-height="200">
        <v-list style="overflow:auto;display: flex; flex-direction: column;">
          <v-list-item v-for="(notificacao, index) in notifications" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                <h4 class="text-truncate">
                  {{ notificacao.descricao }}

                  <v-btn icon color="primary" :disabled="loading" @click="deleteNotify(notificacao.id, index)">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-btn>
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div class="d-flex justify-end">
                  <h6>
                    {{ notificacao.dataCriacao }}
                  </h6>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import PerfilService from "@/services/PerfilService.js";

export default {
  name: "Notify",

  data: () => ({
    loading: false,
    notifications: [],
    menu: false, // Adicionando estado para controlar a exibição do menu
  }),

  mounted() {
    this.getNotify();

    setInterval(() => {
      this.getNotify();
    }, 90000);
  },

  methods: {
    async getNotify() {
      this.loading = true;
      const response = await PerfilService.getNotificacao();
      this.notifications = Object.values(response.data);
      this.notifications = this.notifications.flat();

      this.notifications.forEach(notifion => {
        notifion.dataCriacao = moment(notifion.created_at).format("DD/MM/YYYY");
      });

      this.notifications.sort(function (o1, o2) {
        return o1.created_at ? -1 : o2.created_at ? 1 : 0;
      });

      this.notifications = this.notifications.filter(function (a) {
        return !this[JSON.stringify(a.id)] && (this[JSON.stringify(a.id)] = true);
      }, Object.create(null));

      this.loading = false;
    },

    async deleteNotify(id, index) {
      this.loading = true;
      const response = await PerfilService.deleteNotificacao(id);

      if (response) {
        this.notifications.splice(index, 1);
      }

      this.loading = false;
    },
  },
};
</script>