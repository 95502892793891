import VueApexCharts from 'vue-apexcharts'
import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";

import TextCheckboxField from "../../components/fields/text-checkbox-field/TextCheckboxField.vue";
import TextField from "../../components/fields/textField/TextField.vue";
import { SnackbarMixin, FiltroMixin } from "../../mixins";
import PainelService from '../../services/PainelService';
import itemTipoMixins from '../../mixins/itemTipoMixins';
import tipoAdminMixins from '../../mixins/tipoAdminMixins';
import categoriaMixins from '../../mixins/categoriaMixins';
import subcategoriaMixins from "../../mixins/subcategoriaMixins";
import tagsMixins from "../../mixins/tagsMixins";
import marcasMixins from "../../mixins/marcasMixins";
import modelosMixins from "../../mixins/modelosMixins";
import templatesMixins from "../../mixins/templatesMixins"
import statusMaterialMixins from '../../mixins/statusMaterialMixins';
import unidadesMixins from '../../mixins/unidadesMixins';
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "Painel",

  data: () => ({
    FuncionalidadeEnum: FuncionalidadeEnum,
    loading: {
      itens: false,
      fieldsItens: false,
      table: false,
      progressbar: false,
    },
    form:{
      unidade_militar:[]
    },
    options:{
      unidades:[],
      modelo:[],
      categoriaMaterial:[],
      subcategoriaMaterial:[],
      subcategoria:[],
      categoria:[],
      admType: [],
      formAdmType:[],
      templates:[],
      tags:[],
    },
    filter:{},
    options1: {
      chart: {
        id: 'vuechart-example',
        toolbar: {
          show: false
        }
      },
      title: {
        text: "Quantidade de materiais",
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238'
        },
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series1: [{
      name: 'series-1',
      data: [30, 40, 45, 50, 49, 60, 70, 91]
    }],
    series2: [],
    options2: {
      title: {
        text: "Quantidade de materiais por item",
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238'
        },
      },
      labels: [],
      chart: {
        type: 'polarArea',
      },
      stroke: {
        colors: ['#fff']
      },
      fill: {
        opacity: 0.8
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }),

  mixins: [SnackbarMixin, FiltroMixin, tipoAdminMixins, categoriaMixins, subcategoriaMixins, tagsMixins, marcasMixins, 
    modelosMixins, templatesMixins, statusMaterialMixins, unidadesMixins, itemTipoMixins],


  components: {
    apexchart: VueApexCharts,
    AutoCompleteField,
    TextCheckboxField,
    TextField,
    SelectField
  },

  created() {
    this.initializeFilter();
  },

  mounted() {
    this.getMateriais();
  },

  methods: {    
    geraBar(data){
      const categories = data.materiais_por_item.map(material => {
        const itemName = material.descricao;
        return itemName
      });

      const series = data.materiais_por_item.map(material => material.quantidade);

      this.series1 = [{
        name: 'Quantidade',
        data: series,
      }],

      this.options1 = {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          }
        },
        
        height: 'auto',
        width: 'auto',

        dataLabels: {
          enabled: false
        },

        grid: {
          padding: {
            left: 90, 
            bottom:120
          }
        },
        series,
        
        title: {
          text: "Quantidade de materiais",
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        xaxis: {
          categories,
        }
      }
    },

    geraRadialBar(data){
      this.series2 = data.materiais_por_item.map(item => item.quantidade);
      const labels2 = data.materiais_por_item.map(item => item.descricao);

      this.options2 = {
        title: {
          text: "Quantidade de materiais por item",
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        series: this.series2,
        labels: labels2,
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },

        yaxis: {
          show: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },

    trataData(data){
      this.geraRadialBar(data);
      this.geraBar(data);
    },

    groupBy(data, key) { 
      return data.reduce(function(storage, item) {
        var group = item[key];
        storage[group] = storage[group] || [];    
        storage[group].push(item);   
  
        return storage; 
      }, {});
    },

    initializeFilter() {
      this.filter = {
        codigo_catmas: null,
        status_material_id: null,
        descricao: null,
        patrimonio_serie: null,
        item_tipo_id: null,
        tipo_administrador_id:  null,
        categoria_id:  null,
        subcategoria_id:  null,
        tag_id:  null,
        descricao_catmas:  null,
        marca_id:  null,
        modelo_id:  null,
        template_id:  null,
        unidade_id:null,
        data_entrada:  null,
        data_validade: null,
      };
    },

    clear() {
      this.initializeFilter();
      this.getMateriais();
    },

    async getMateriais() {
      this.loading.progressbar =  true;
      const params = {
        categoria_id: this.filter.categoria_id,
        subcategoria_id: this.filter.subcategoria_id,
        codigo_catmas: this.filter.codigo_catmas,
        descricao_catmas: this.filter.descricao_catmas,
        descricao: this.filter.descricao,
        item_tipo_id: this.filter.item_tipo_id,
        tag_id: this.filter.tag_id,
        tipo_administrador_id: this.filter.tipo_administrador_id,
        modelo_id: this.filter.modelo_id,
        marca_id: this.filter.marca_id,
        template_id: this.filter.template_id,
        status_material_id: this.filter.status_material_id,
        patrimonio_serie: this.filter.patrimonio_serie,
        unidade_id: this.filter.unidade_id,
        data_entrada: this.filter.data_entrada,
        data_validade: this.filter.data_validade,
      }
      const data = await PainelService.getPainel(params);

      this.trataData(data.data.data);
      this.loading.progressbar =  false;
    },

    search() {
      this.getMateriais();
    },
  }
};
