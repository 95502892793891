import RichTextEditor from "../../components/richTextEditor/RichTextEditor.vue";
import { SnackbarMixin, FiltroMixin } from "../../mixins";
import PaginaService from "../../services/PaginaService";
import funcionalidadeMixins from "../../mixins/funcionalidadeMixins";
import funcionalidadePermissaMixin from "../../mixins/funcionalidadePermissaMixin";

export default {
  name: "Home",
  components: {
    RichTextEditor,
  },
  data: () => ({
    content: "",
    loading: false,
    isSaved: false,
    idPagina: null,
  }),

  mixins: [SnackbarMixin, FiltroMixin, funcionalidadeMixins, funcionalidadePermissaMixin],

  async mounted(){
    this.getText()
  },

  methods: {
    async getText(){
      this.loading = true;
      const response = await PaginaService.getPagina();

      if(response.data.length == 0){
        this.idPagina = null;
        this.content = "";
      } else {
        this.idPagina =  response.data[0]?.id;
        this.content = response.data[0]?.html ? response.data[0]?.html : "";
      }

      this.loading = false;
    },
    
    async save(content){
      this.loading = true;
      const postData = {
        html: content,
      }

      let response;
      if(this.idPagina){
        response = await PaginaService.editarPagina(this.idPagina, postData);
        this.loading = false;
      } else {
        response = await PaginaService.cadastrarPagina(postData);
        this.loading = false;
      }

      if(!response.error){
        this.isSaved = !this.isSaved;

        if(!this.idPagina){
          this.getText();
        }

        this.showSnackbar("As informações foram salvas com sucesso!");
      } else {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }
    },
  }
};
