import api from "./Api"

export default {

  async getUnidades(){
    const response = await api.get(`unidade`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  
  async getUnidadesComSubordinadas(){
    const response = await api.get(`unidade/subordinadas`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

};
