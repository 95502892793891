import AlertaService from "../services/AlertaService";
// import { mapActions } from "vuex";
import opcoesMixins from "./opcoesMixins";


export default {
  data() {
    return {
      statusAlerta: [],
    };
  },

  beforeMount() {
    this.getStatusAlerta()
  },

  mixins:[opcoesMixins],

  methods: {
    async getStatusAlerta() {
      const response = await AlertaService.getStatusAlerta();
      if (!response.error) {
        this.statusAlerta = this.trataOptions(response.data);
      }
    },

    verificarSeExisteStatusAlerta() {
      if (!this.$store.getters["opcoes/existe_status_alerta"]) {
        return this.getMateriais();
      }

      this.materiaisOpcoes = this.$store.state.opcoes.materiais;
    },
  },
};
