import api from "./Api"

export default {
  async getAlertas(params = "") {
    const config = {params: params}
    const response = await api.get(`alerta/search?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async cadastrarAlerta(postData) {
    const response = await api.post('/alerta', postData)
        .then((response) => response)
        .catch(err => err);
         return response;
  },

  async concluirAlerta(postData) {
    const response = await api.post(`alerta/concluir`, postData)
    .then((response) => response)
    .catch(err => err);
      return response;
  },

  async deletaAlerta(alertaId, deletarObservacao = "") {
    const postData = JSON.stringify(deletarObservacao);
    const response = await api.delete(`/alerta/${alertaId}`, postData)
        .then((response) => response)
        .catch(err => err); 

    return response;
  },

  async editarAlerta(alertaID, postData) {
    const response = await api.put(`alerta/${alertaID}`, postData)
      .then((response) => response)
      .catch(err => err);
        return response;
  },

  async getTipoAlertas(){
    const response = await api.get(`alerta/tipo`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  async getStatusAlerta(){
    const response = await api.get(`alerta/status_alerta`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },
};
