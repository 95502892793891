import TextAreaField from "../../../../components/fields/textAreaField/TextAreaField.vue";
import TextField from "../../../../components/fields/textField/TextField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
// import TemplatesService from "../../../../services/TemplatesService";
import { SnackbarMixin } from "../../../../mixins";
import TemplatesService from "../../../..//services/TemplatesService";
import itemTipoMixins from "../../../../mixins/itemTipoMixins";

export default {
  name: "IdetificacaoPage",
  mixins: [SnackbarMixin, itemTipoMixins],
  components: {
    TextAreaField,
    TextField,
    SelectField,
  },
  data: () => ({
    form: {},
    errors: [],
    options: {
      formTipo: [],
    },
    templateid: null,
    nome: "",
    formValid: true,
    loadingFields: false,
    editMode: false,
    item: {
      templateid: null,
      id: null,
      nome: null,
      descricao: null,
      tipo_id: null
    }
  }),

  created() {
    this.initializeForm();
  },

  mounted() {
    if (this.$route.params.templateid) {
      this.templateid = this.$route.params.templateid;
      this.item = this.$route.params
      this.editMode = true;
      this.getDataForm();
    }

    this.$emit("atualizaRotas", this.$route.params);
  },

  methods: {
    goTo(url) {
      this.$router.push(url);
    },

    async getDataForm() {
      this.loadingFields = true;

      this.form.nome = await this.item.nome
      this.form.tipo_id = await this.item.tipo_id;
      this.form.descricao = await this.item.descricao;
      this.loadingFields = false;
    },

    async save() {
      this.errors = [];
      if (this.$refs.formTemplate.validate()) {
        let response;
        if (this.editMode) {
          // Se estiver no modo de edição
          response = await TemplatesService.editarTemplate(
            this.templateid,
            this.form
          );
    
          if (response.error) {
            this.showSnackbar("Não foi possível completar a ação", "error");
            return;
          }
          this.showSnackbar("A edição do template foi salva com sucesso");
          // Redireciona para a página de campos
          this.$router.push({
            name: "TemplateCampos",
            params: {
              templateid: this.templateid,
              nome: response.data.nome,
              descricao: response.data.descricao,
              tipo_id:  response.data.tipo_id
            },
          });

          this.form.nome = response.data.nome
          this.form.descricao = response.data.descricao,
          this.form.tipo_id = response.data.tipo_id
        } else {
          // Se estiver no modo de criação
          response = await TemplatesService.cadastrarTemplate(this.form);
    
          this.showSnackbar("O template foi cadastrado com sucesso");
    
          // Extrai o ID do novo template do response
          const templateid = response.data.id;

          // Redireciona para a página de campos
          this.$router.push({
            name: "TemplateCampos",
            params: {
              templateid,
              nome: this.form.nome,
              descricao: this.form.descricao,
              tipo_id: this.form.tipo_id
            },
          });
        }
        return;
      }
    
      this.errors.push("Preencha os campos obrigatórios");
    },

    backConsulta() {
      this.$router.push("/templates/consultar");
    },

    initializeForm() {
      this.form = {
        nome: "",
        tipo_id: "",
        descricao: "",
      };
    },
  },
};
