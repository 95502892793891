import api from "./Api"

export default {
  async getPainel(params) { 
    const config = {params: params}
    const response = await api.get(`painel/search?`, config)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
};
