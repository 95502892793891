import TextField from "../../../components/fields/textField/TextField.vue";
import AutoCompleteField from "../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../components/fields/selectField/SelectField.vue";

export default {
  name: "Form",
  components: {
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
  },

  data: () => ({
    form: {},
    options: {},
    editMode: false,
    itemid: null,
  }),

  created() {
    if (this.$route.params.itemid) {
      this.editMode = true;
      this.itemid = this.$route.params.itemid;
    }

    this.initializeForm();
    this.getOptions();
  },

  methods: {
    getOptions() {
      const tipoOptions = [
        "Permanente",
        "Consumo (descartável)",
        "Consumo (controlado)",
      ];
      this.options.tipo = this.geraOptions(tipoOptions, "Selecione");
    },

    geraOptions(arrayOptions, defaultOption = "Selecione") {
      let options = [];
      options.push({ text: defaultOption, value: "" });

      arrayOptions.forEach((option) => {
        options.push({ text: option, value: option.toLowerCase() });
      });

      return options;
    },

    initializeForm() {
      this.form = {
        administrador: "",
        descricao: "",
        tipo: "",
        consumo: {
          tipo: "",
          numSerie: "",
          numSerieBloco: false,
        },
        permanente: {
          quantidade: "",
          numPatrimonio: "",
          numPatrimonioBloco: false,
          demandaAtual: "",
        },
        dataFabricacao: "",
        validade: "",
        codigo: "",
        unidadeMovimentacao: "",
        categoria: "",
        subcategoria: "",
        estadoConcervacao: "",
        tags: [],
        marca: "",
        modelo: "",
      };
    },
  },
};
