import TextField from "../../components/fields/textField/TextField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import CheckboxField from "../../components/fields/checkboxField/CheckboxField.vue";
import AlertaService from "../../services/AlertaService";
import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import {FiltroMixin } from "../../mixins";
import materiaisMixins from "../../mixins/materiaisMixins";
import tipoAlertasMixins from "../../mixins/tipoAlertasMixins";
import statuAlertaMixins from "../../mixins/statuAlertaMixins";

export default {
  name: "GerenciamentoAlertas",

  components: { TextField, SelectField, FormDialog, ConfirmDialogButton,CheckboxField },
  mixins:[FiltroMixin, materiaisMixins, tipoAlertasMixins, statuAlertaMixins],

  data: () => ({
    headers: [
      { text: "Material", value: "patrimonio", sortable: false },
      { text: "Tipo de Alerta", value: "tipo_alerta.nome", sortable: false },
      { text: "Unidade", value: "unidade.codigo", sortable: false },
      { text: "Data", value: "JsonObject.tipoDataTratada", sortable: false },
      { text: "Status", value: "status_alerta.nome", sortable: false },
      // { text: "Modificado por", value: "usuario", sortable: false },
      { text: "Data criação", value: "data_criacao", sortable: false },
    ],
    data: [],
    itemsClear:[],
    searchReset:false,
    withtrashed:false,
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    form: {},
    filter: {},
    options: {
      administrador: [],
    },
    loading: {
      alerta: false,
    },
    formularioDialog: false,
    formTitle: "",
    formType: "",
    
  }),

  created() {
    this.initializeForm();
    this.initializeFilters();
  },

  mounted(){
    this.getAlertas();
  },

  watch:{
    page() {
      this.getAlertas();
    },
  },

  methods: {
    async getAlertas() {
      this.loading.table = true;
      const params = {
        lista_nao_concluido: false,
        material_id: this.filter.material_id,
        unidade: this.filter.unidade,
        tipo_alerta: this.filter.tipo_alerta,
        status_alerta: this.filter.status_alerta,
        usuario: this.filter.usuario,
        created_at: this.filter.created_at,
        page: this.page
      }

      const response = await AlertaService.getAlertas(params);
      this.data = this.trataJson(response.data.data);
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;
      this.loading.table = false;
    },

    trataJson(alertas) {
      alertas.forEach(alerta => {
        alerta.JsonObject = JSON.parse(alerta.json)
        alerta.JsonObject.tipoDataTratada =  this.formatDate(alerta.JsonObject.tipoData)
        alerta.data_criacao =  this.formatDate(alerta.created_at)
      });
        
      return alertas
    },
    search() {
      this.getAlertas()
    },

    activeFormDialog(title, type) {
      this.formTitle = title;
      this.formType = type;
      this.formularioDialog = !this.formularioDialog;
    },

    initializeForm() {
      this.form = {
        titulo_categoria: "",
        subcategoria: "",
        administrador: "",
      };
    },

    initializeFilters() {
      this.filter = {
        material_id: null,
        unidade: null,
        tipo_alerta: null,
        status_alerta: null,
        usuario: null,
        created_at:null,
      };
    },

    formatDate(dateString) {
      // Crie um objeto de data a partir da string
      const dateObject = new Date(dateString);
      
      // Adicione um dia à data atual
      dateObject.setDate(dateObject.getDate() + 1);
      
      // Extraia o ano, mês e dia
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adiciona um zero à esquerda se o mês for menor que 10
      const day = String(dateObject.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se o dia for menor que 10
      
      const formattedDate = `${day}/${month}/${year}`;
      
      return formattedDate;
    }
  },
};
