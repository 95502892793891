import MateriaisService from "../../../services/MateriaisService";
import TextField from "../../../components/fields/textField/TextField.vue";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";

export default {
  name: "HistoricoDescarteDescarga",
  components: {
    TextField
  },

  computed: {
    descarteHeader () {
      return [
        { text: "Material", value: "material.item.descricao", sortable: false },
        { text: "Código do Material", value: "material.item.catmas.codigo", sortable: false },
        { text: "Patrimônio", value: "material.patrimonio", sortable: false },
        { text: "Material ID", value: "material_id", sortable: false },
        // { text: "Justificativa", value: "justificativa", sortable: false },
        { text: "Unidade", value: "material.unidade.codigo", sortable: false, filter: value => {
          if (!this.filter.unidade_descarte) return true

          const filterText = value.toUpperCase().indexOf(this.filter.unidade_descarte.toUpperCase()) > -1;
          
          return filterText; 
        }},
        { text: "Usuário", value: "material.vinculo.nome", sortable: false },
        { text: "Data", value: "created_at_formatado", sortable: false, align: "center" },
      ]
    },

    descargaHeader () {
      return [
        { text: "Material", value: "material.item.descricao", sortable: false },
        { text: "Código do Material", value: "material.item.catmas.codigo", sortable: false },
        { text: "Patrimônio", value: "material.patrimonio", sortable: false },
        { text: "Material ID", value: "material_id", sortable: false },
        { text: "N° Processo (SEI)", value: "SSID", sortable: false },
        { text: "N° Processo (SIAD)", value: "SEAD", sortable: false },
        { text: "Observações", value: "observacao", sortable: false },
        { text: "Unidade", value: "material.unidade.codigo", sortable: false, filter: value => {
          if (!this.filter.unidade_descarga) return true

          const filterText = value.toUpperCase().indexOf(this.filter.unidade_descarga.toUpperCase()) > -1;
          
          return filterText; 
        }},
        { text: "Usuário", value: "material.vinculo.nome", sortable: false },
        { text: "Data", value: "created_at_formatado", sortable: false, align: "center" },
      ]
    }
  },

  data: () => ({
    FuncionalidadeEnum: FuncionalidadeEnum,
    dialog: false,
    loading: {
      table: false,
    },
    
    filter: {
      unidade_descarte: null,
      unidade_descarga: null,
    },

    descarteTable: {
      items: [],
      page: 1,
      pageCount: 0,
      lastPage:1,
      itemsPerPage: 10,
    },
    descargaTable: {
      items: [],
      page: 1,
      pageCount: 0,
      lastPage:1,
      itemsPerPage: 10,
    },
  }),

  created(){},

  mounted() {
    this.getDescarga();
    this.getDescarte();
  },

  watch:{
    'descargaTable.page'(){
      this.getDescarga();
    },
    'descarteTable.page'(){
      this.getDescarte();
    },
  },

  methods: {
    async getDescarga() {
      this.loading.table = true;
      const params = {
        descarga: 1,
        unidade_descarga: this.filter.unidade_descarga,
        page: this.descargaTable.page
      }
      const response = await MateriaisService.getDescarteOuDescargaMaterial(params);
      this.descargaTable.items = this.trataItems(response.data.data)
      this.descargaTable.lastPage = response.data.last_page;
      this.descargaTable.pageCount = response.data.total;
      this.loading.table = false;
    },
    async getDescarte() {
      this.loading.table = true;
      const params = {
        descarte: 1,
        unidade_descarte: this.filter.unidade_descarte,
        page: this.descarteTable.page
      }
      const response = await MateriaisService.getDescarteOuDescargaMaterial(params);
      this.descarteTable.items = this.trataItems(response.data.data)
      this.descarteTable.lastPage = response.data.last_page;
      this.descarteTable.pageCount = response.data.total;
      this.loading.table = false;
    },

    trataItems(items){
      items.forEach(item => {
        item.created_at_formatado =  this.formatDate(item.created_at)
      });
        
      return items
    },

    formatDate(dateString) {
      // Crie um objeto de data a partir da string
      const dateObject = new Date(dateString);
      
      // Adicione um dia à data atual
      dateObject.setDate(dateObject.getDate() + 1);
      
      // Extraia o ano, mês e dia
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adiciona um zero à esquerda se o mês for menor que 10
      const day = String(dateObject.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se o dia for menor que 10
      
      const formattedDate = `${day}/${month}/${year}`;
      
      return formattedDate;
    }
  },
};
