// import moment from "moment";
import TextField from "../../../components/fields/textField/TextField.vue";
import MateriaisService from "../../../services/MateriaisService";
import { FiltroMixin, SnackbarMixin } from "../../../mixins";
import StatusMovimentacoesEnum from "../../../enums/StatusMovimentacoesEnum";
// import html2pdf from "html2pdf.js";
import Vue from "vue";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";
import emitirPDFMixins from "../../../mixins/emitirPDFMixins";

export default {
  name: "HistoricoDescarteDescarga",
  components: {
    TextField
  },

  mixins: [SnackbarMixin,FiltroMixin, emitirPDFMixins],

  computed: {
    logMovimentacaoHeader () {
      return [
        { text: "Material", value: "material.item.descricao", sortable: false },
        { text: "Código do Material", value: "material.item.catmas.codigo", sortable: false },
        { text: "Quantidade", value: "material.quantidade", sortable: false },
        { text: "Unidade", value: "material.unidade.codigo",  sortable: false },
        { text: "Destinatário", value: "destinatario_unidade_codigo", sortable: false },
        { text: "Data", value: "created_at_formatado", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ]
    },

    logAllMovimentacoesHeader () {
      return [
        // { text: "Material", value: "nmMaterial", sortable: false },
        // { text: "Código do Material", value: "cdMaterial", sortable: false },
        // { text: "Patrimônio", value: "cdMaterial", sortable: false },
        { text: "Material", value: "material.item.descricao", sortable: false },
        { text: "Código do Material", value: "material.item.catmas.codigo", sortable: false },
        // { text: "Atividade", value: "atividade", sortable: false },
        { text: "Unidade", value: "material.unidade.codigo",  sortable: false },
        { text: "Usuário", value: "material.vinculo.nome", sortable: false },
        { text: "Data", value: "created_at_formatado", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ]
    }
  },

  data: () => ({
    dialog: false,
    FuncionalidadeEnum: FuncionalidadeEnum,
    loading: {
      tableMovimentacoes: false,
      tableHistoricoMovimentacoes: false,
      tableAll: false,
      recibo: false,
    },
    
    filter: {
      unidade_descarte: "",
      unidade_descarga: "",
    },

    movimentacao: {
      items: [],
      page: 1,
      pageCount: 1,
      lastPage:1,
      itemsPerPage: 10,
    },
    permissionsUser:[],
    historicoMovimentacoes: {
      items: [],
      page: 1,
      pageCount: 1,
      lastPage:1,
      itemsPerPage: 10,
    },
    StatusMovimentacoesEnum: StatusMovimentacoesEnum
  }),

  created() {
    this.permissionsUser = Vue.prototype.$permissionsUser

  },

  mounted() {
    this.getMovimentacao();
    this.getHistoricoMovimentacoes();

  },

  watch:{
    "historicoMovimentacoes.page"(){
      this.getHistoricoMovimentacoes();
    },
    "movimentacao.page" (){
      this.getMovimentacao();
    }
  },

  methods: {
    async getMovimentacao() {
      this.loading.tableMovimentacoes = true;

      const params = {
        movimentacao_status_id: StatusMovimentacoesEnum.EM_ANALISE,
        page: this.movimentacao.page
      }
      const response = await MateriaisService.getMovimentacoes(params);
      this.movimentacao.items = this.trataItems(response.data.data)
      this.movimentacao.lastPage = response.data.last_page;
      this.movimentacao.pageCount = response.data.total;
      this.loading.tableMovimentacoes = false;
    },

    async getHistoricoMovimentacoes() {
       this.loading.tableHistoricoMovimentacoes = true;

      const params = {
        historico_status_id: StatusMovimentacoesEnum.EM_ANALISE,
        page: this.historicoMovimentacoes.page
      }
      const response = await MateriaisService.getMovimentacoes(params);
      this.historicoMovimentacoes.items = this.trataItems(response.data.data)
      this.historicoMovimentacoes.lastPage = response.data.last_page;
      this.historicoMovimentacoes.pageCount = response.data.total;
      this.loading.tableHistoricoMovimentacoes = false;
    },

    async aprovarMovimentacao(materialID) {
      this.loading.table = true;
      const postData = {
        status_id: StatusMovimentacoesEnum.ACEITAR,
      };

      const response = await MateriaisService.alteraStatusMaterialMovimentar(postData, materialID);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.table = false;
        return;
      }
      
      this.showSnackbar("A aprovação da movimentação foi realizada com sucesso");
      this.loading.table = false;
      
      this.getMovimentacao();
      this.getHistoricoMovimentacoes();
    },

    async recusarMovimentacao(materialID) {
      this.loading.table = true;
      const postData = {
        status_id: StatusMovimentacoesEnum.REJEITAR,
      };

      const response = await MateriaisService.alteraStatusMaterialMovimentar(postData, materialID);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.table = false;
        return;
      }
      
      this.showSnackbar("A recusa da movimentação foi realizada com sucesso");
      this.loading.table = false;

      this.getMovimentacao();
      this.getHistoricoMovimentacoes();
    },

    trataItems(items){
      items.forEach(item => {
        item.created_at_formatado =  this.formatDate(item.created_at)
      });
        
      return items
    },

    formatDate(dateString) {
      // Crie um objeto de data a partir da string
      const dateObject = new Date(dateString);
      
      // Adicione um dia à data atual
      dateObject.setDate(dateObject.getDate() + 1);
      
      // Extraia o ano, mês e dia
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adiciona um zero à esquerda se o mês for menor que 10
      const day = String(dateObject.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se o dia for menor que 10
      
      const formattedDate = `${day}/${month}/${year}`;
      
      return formattedDate;
    },

    async gerarPDF() {
      try{
        this.loading.recibo = true;
        const data = await MateriaisService.getExportLogPDF(this.filter);

        this.exportBlob(data.data); 
      } finally {
        
        this.loading.recibo = false;
      }
    },

    // trataDataAllMovimentacoes(data){ 
    //   data = data.filter(dado=> {
    //     if(this.isJson(dado.resultado)){
    //       const result = JSON.parse(dado.resultado);
    //       return result?.status?.code == "200";
    //     }

        
    //     if(typeof dado.resultado == 'object'){
    //       const result = dado.resultado;
    //       return result?.status?.code == "200";
    //     }
    //   });
    //   const dadosMovimentacao = [];

    //   data.forEach(log => {
    //     let atividade = "";
    //     switch (log.rota) {
    //       case 'material.store':
    //         atividade = "Cadastrar material"
    //         break;
    //       case 'material.update':
    //         atividade = "Edição material"
    //         break;
    //       case 'material.aprovar.movimentacao':
    //         atividade = "Movimentou material"
    //         break;
    //       case 'material.disponibilizar':
    //         atividade = "Disponibilizar material"
    //         break;
    //       case 'material.vinculardisponibilizado':
    //         atividade = "Movimentou material"
    //         break;
    //       case 'material.descarga':
    //         atividade = "Descarga/Descarte material"
    //         break;
    //       case 'material.vincular':
    //         atividade = "Vincular material"
    //         break;
    //       default:
    //         atividade = ""
    //     }



    //     if(log.rota == "material.store"){
    //       const result = JSON.parse(log.resultado);
          
    //       if(Array.isArray(log.request.patrimonio_serie)){
    //         const material = result?.result?.material.toString();
    //         const codigo = result?.result?.codigo.toString();

    //         const uniqMaterial = material == undefined  ? "" : [...new Set(result?.result?.material.replaceAll(" ", "").split(","))];
    //         const uniqCodigo   = codigo == undefined    ? "" : [...new Set(result?.result?.codigo.replaceAll(" ", "").split(","))];
            
    //         log.request.patrimonio_serie.forEach(() => {
    //           const logTratado = {
    //             id: log.id,
    //             dataCriacao: moment(log.created_at).format("DD/MM/YY HH:mm"),
    //             unidade: log.unidades,
    //             codigo: uniqCodigo,
    //             material: uniqMaterial,
    //             user: log.usuario,
    //             atividade,
    //           };
    //           dadosMovimentacao.push(logTratado);
    //         })
    //       } else {
    //         const material = result?.result?.material.toString();
    //         const codigo = result?.result?.codigo.toString();

    //         const uniqMaterial = material == undefined  ? "" : [...new Set(result?.result?.material.replaceAll(" ", "").split(","))];
    //         const uniqCodigo   = codigo == undefined    ? "" : [...new Set(result?.result?.codigo.replaceAll(" ", "").split(","))];
            
    //         const logTratado = {
    //           id: log.id,
    //           dataCriacao: moment(log.created_at).format("DD/MM/YY HH:mm"),
    //           unidade: log.unidades,
    //           codigo: uniqCodigo,
    //           material: uniqMaterial,
    //           user: log.usuario,
    //           atividade,
    //         };
    //         dadosMovimentacao.push(logTratado);
    //       }
    //     } else {
    //       const material = log.request?.material.toString();
    //       const codigo = log.request?.codigo.toString();

    //       const uniqMaterial = material == undefined  ?  "" : [...new Set(material.replaceAll(" ", "").split(","))];
    //       const uniqCodigo   = codigo   == undefined  ?  "" : [...new Set(codigo.replaceAll(" ", "").split(","))];

    //       const logTratado = {
    //         id: log.id,
    //         dataCriacao: moment(log.created_at).format("DD/MM/YY HH:mm"),
    //         unidade: log.unidades,
    //         codigo: uniqCodigo,
    //         material: uniqMaterial,
    //         user: log.usuario,
    //         atividade,
    //       };
    //       dadosMovimentacao.push(logTratado);
    //     }

    //   });

    //   return dadosMovimentacao;
    // },

    // trataData(data){ 
    //   data = data.filter((dado)=> {
    //     if(this.isJson(dado.resultado)){
    //       const result = JSON.parse(dado.resultado);
    //       return result?.status?.code == "200";
    //     }

    //     if(typeof dado.resultado == 'object'){
    //       const result = dado.resultado;
    //       return result?.status?.code == "200";
    //     }
    //   });

    //   console.log('data filtrado', data);

    //   const dadosMovimentacao = [];
    //   data.forEach(log => {
    //     const uniqMaterial =  [...new Set(log.request.material.replaceAll(" ", "").split(","))];
    //     const uniqCodigo =  [...new Set(log.request.codigo.replaceAll(" ", "").split(","))];
    //     const logTratado = {
    //       id: log.request.id[0],
    //       logId: log.id,
    //       // id: log.id,
    //       dataCriacao: moment(log.created_at).format("DD/MM/YY HH:mm"),
    //       unidadeDestino: log.request.unidade,
    //       unidadeMandante: log.unidades,
    //       quantidade: log.request.quantidade,
    //       codigo: uniqMaterial,
    //       material: uniqCodigo,
    //     };
    //     dadosMovimentacao.push(logTratado);
    //   });


    //   this.logMovimentacao.items = dadosMovimentacao;
    // },

    // exportToPDF (item) {
    
    // },

  },
};
