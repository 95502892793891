import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#1C2C3B",
                secondary: "#8B0000",
                tertiary: "E0E1E2",
            },
        },
    },
});
