export default {
  name: "ItemPage",
  data: () => ({
    items: [],
    templateID: null,
    template: {
      id: null,
      nome: null,
      descricao: null,
      tipo_id: null}
  }),

  mounted() {
    this.items = [
      {
        text: "Identificação",
        to: {
          name: "TemplateIdentificacao",
          params: {
            templateid: this.templateID,
            nome: this.template.nome,
            descricao: this.template.descricao,
            tipo_id: this.template.tipo_id
          },
        },
      },
      {
        text: "Campos",
        to: {
          name: "TemplateCampos",
          params: {
            templateid: this.templateID,
            nome: this.template.nome,
            descricao: this.template.descricao,
            tipo_id: this.template.tipo_id
          },
        },
      },
    ];
  },

  methods: {
    atualizaRotas(e) {
      this.templateID = e.templateid;
      this.template.nome = e.nome
      this.template.descricao = e.descricao
      this.template.tipo_id = e.tipo_id
    },
  },
};
