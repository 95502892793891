import TextField from "../../../../components/fields/textField/TextField.vue";
import AutoCompleteField from "../../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
import MateriaisService from "../../../../services/MateriaisService";
import unidadesMixins from "../../../../mixins/unidadesMixins";
import pessoaMixins from "../../../../mixins/pessoaMixins";
import { AssinaturaMixin, SnackbarMixin } from "../../../../mixins";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";


export default {
  name: "DialogVincular",
  components: {
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    ConfirmPasswordDialog
  },

  props: {
    activeDialog: {
      default: true,
      type: Boolean
    },
    materiais: {
      default: () => [],
      type: Array
    }
  },

  mixins: [SnackbarMixin, AssinaturaMixin, unidadesMixins, pessoaMixins],

  data: () => ({
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },

    dialog: false,
    formValid: true,
    form: {},
    options: {},
    loading: {
      users: false,
      unidades: false
    },
  }),

  created() {
    this.initializeForm();
  },

  methods: {
    setMaterialInfo() {
      const arrayDescricao = this.materiais.map(material => material.item.descricao);
      const descricoes = arrayDescricao.join(", ");
      this.form.material = descricoes;

      const arrayCodigos = this.materiais.map(material => material.codigo_catmas);
      const codigos = arrayCodigos.join(", ");
      this.form.idMaterial = this.materiais.map(material => material.id);
      this.form.codigo = codigos;

      const arrayPatrimonioSerie = this.materiais.map(material => material.patrimonio);
      const patrimonioSerie = arrayPatrimonioSerie.join(", ");
      this.form.patrimonio = patrimonioSerie;
    },
    
  

    // async getUsers() {
    //   if(this.form.unidade_militar){
    //     this.loading.users = true;

    //     const data = await GrupoService.getUserGruposPorID(this.form.unidade_militar);
    //     this.form.nome_militar = "";

    //     const users = data.map((user) => {
    //       const nome = `${user.username} - ${user.firstName} ${user.lastName}` 
    //       return { text: nome, value: user.id };
    //     });
    //     users.unshift({text: "Selecione", value: ""})
    //     this.options.users = users;

    //     this.loading.users = false;
    //   }
    // },

    initializeForm() {
      this.form = {
        material: "",
        codigo: "",
        patrimonio_serie: "",
        unidade_militar: "",
        nome_militar: "",
        bm_militar: "",
        idMaterial: []
      };
    },

    async prepareForm(){
      const postData = {
        id: this.form.idMaterial,
        usuario: this.form.nome_militar,
        codigo: this.form.codigo,
        material: this.form.material
      }
      
      return postData;
    },
    
    async vincularMaterial(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await MateriaisService.vincularMaterial(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O material foi vinculado com sucesso");
      this.initializeForm();
      this.$refs.form.resetValidation();

      this.$emit("updateData");
      this.dialog = false;
    },
  },
};
