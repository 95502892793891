const CampoTemplateEnum = {
    TIPO_CAMPO_TEMPLATE_TEXTO: 1,
    TIPO_CAMPO_TEMPLATE_SELECAO_UNICA: 2,
    TIPO_CAMPO_TEMPLATE_SELECAO_MULTIPLA: 3,
    TIPO_CAMPO_TEMPLATE_DATA: 4,
    TIPO_CAMPO_TEMPLATE_ARQUIVO: 5,

    TEMPLATE_MASCARA_NENHUMA: 1,
    TEMPLATE_MASCARA_EMAIL: 2,
    TEMPLATE_MASCARA_TELEFONE: 3,

    TIPO_CAMPO_TEMPLATE_FORMATOS_JPG: 1,
    TIPO_CAMPO_TEMPLATE_FORMATOS_PNG: 2,
    TIPO_CAMPO_TEMPLATE_FORMATOS_BMP: 3,
    TIPO_CAMPO_TEMPLATE_FORMATOS_DOC: 4,
    TIPO_CAMPO_TEMPLATE_FORMATOS_PDF: 5,
    TIPO_CAMPO_TEMPLATE_FORMATOS_MP3: 6,
    TIPO_CAMPO_TEMPLATE_FORMATOS_MP4: 7,
    TIPO_CAMPO_TEMPLATE_FORMATOS_DOCX: 8,
    TIPO_CAMPO_TEMPLATE_FORMATOS_XLS: 9,
    TIPO_CAMPO_TEMPLATE_FORMATOS_XLSX: 10,
    TIPO_CAMPO_TEMPLATE_FORMATOS_PPT: 11,
    TIPO_CAMPO_TEMPLATE_FORMATOS_PPS: 12,
    TIPO_CAMPO_TEMPLATE_FORMATOS_SVG: 13,
    TIPO_CAMPO_TEMPLATE_FORMATOS_CSV: 14,
    TIPO_CAMPO_TEMPLATE_FORMATOS_TXT: 15,

}

export default CampoTemplateEnum