import MateriaisService from "../../../../services/MateriaisService";
import { SnackbarMixin, AssinaturaMixin } from "../../../../mixins";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";


export default {
  name: "DialogDesvincular",
  components: {

    ConfirmPasswordDialog
  },

  props: {
    materiais: {
      default: () => [],
      type: Array
    }
  },
  

  mixins: [SnackbarMixin, AssinaturaMixin],

  data: () => ({
    dialog: false,
    formValid: true,
    descricoes: "",

    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
  }),

  methods: {
    setMaterialInfo(){
      const arrayDescricao = this.materiais.map(material => material.item.descricao);
      this.descricoes = arrayDescricao.join(", ");
    },

    async prepareForm(){
      const material_ids = this.materiais.map((material)=> material.id)
      const postData = {
        material_ids,
      }
      
      return postData;
    },
    
    async desvincularMaterial(){
      const postData = await this.prepareForm();

      const response = await MateriaisService.desvincularMaterial(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O material foi desvinculado com sucesso");
      this.$emit("updateData");
      this.dialog = false;
    },
  },
};
