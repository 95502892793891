import { SnackbarMixin, CSV } from "../../../mixins";
import ModeloService from "../../../services/ModeloService";
import MarcaService from "../../../services/MarcaService";

import TextField from "../../fields/textField/TextField.vue";

export default {
  name: "uploadCsvModeloMarca",
  components: {
    TextField,
  },
  data: () => ({
    dialog: false,
    loading: false,
    form: {
      marca: [],
      modelo: [],
    },
    file: [],
    arrayCsv: [],
  }),
  mixins: [SnackbarMixin, CSV],

  methods: {
    initializeForm() {
      this.file = [];
      this.arrayCsv = [];
      this.form = {
        marca: [],
        modelo: [],
      };
    },

    async readTextFile() {
      const input = document.getElementById("fileCsvMarcaModelo").files[0];

      const reader = new FileReader();
      reader.readAsText(input);

      reader.onload = async (e) => {
        const text = e.target.result;
        this.arrayCsv = await this.csvToArray(text);
      };
    },

    async saveMarca() {
      const formData = { nome: [] };

      if (this.form.marca.length != 0) {
        this.form.marca.forEach((element) => {
          formData.nome.push(element);
        });

        const response = await MarcaService.cadastrarMarca(formData);

        if (!response.error) {
          return true
        }

        let message = "Não foi possível completar a ação, marca não registrada";

        if (response.message.results && response.message.results.nome) {
          message = "O modelo já está registrada, escolha um nome diferente";
        }

        this.showSnackbar(message, "error");
        return false;
      }
    },

    async saveModelo() {
      const formData = { nome: [] };

      if (this.form.modelo.length != 0) {
        this.form.modelo.forEach((element) => {
          formData.nome.push(element);
        });
        const response = await ModeloService.cadastrarModelo(formData);
        if (!response.error) {
          return true
        }

        let message = "Não foi possível completar a ação, marca não registrada";

        if (response.message.results && response.message.results.nome) {
          message = "A marca já está registrada, escolha um nome diferente";
        }

        this.showSnackbar(message, "error");
        return false;

      }
    },

    async save() {
      this.loading = true;
      await this.readTextFile();

      this.arrayCsv.map((value) => {
        if (value.marca != null && !this.form.marca.includes(value.marca)) {
          this.form.marca.push(value.marca);
        }
        if (value.modelo != null && !this.form.modelo.includes(value.modelo)) {
          this.form.modelo.push(value.modelo);
        }
      });

      const saveMarca = await this.saveMarca();
      const saveModelo = await this.saveModelo();

      this.loading = false;
      this.$emit("update");

      if(saveMarca && saveModelo ){
        this.formularioDialog = !this.formularioDialog;
        this.showSnackbar("O modelo e marca foram cadastrado com sucesso");
        this.initializeForm();
        this.dialog = false;
      }


    },
  },
};
