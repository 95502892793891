import api from "./Api"

export default {

  async getFuncionalidade(){  
    const response = await api.get(`funcionalidade`)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

};
