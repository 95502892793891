<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
// import Vue from "vue";
import PessoaService from "../services/PessoaService";
export default {
  name: "App",

  async beforeMount() {
    await this.logado();
    // this.$store.commit("setAdmGlobal",Vue.prototype.$keycloak.realmAccess.roles.includes("ADM_GLOBAL"));
    // console.log(`aqui`, this.user.funcionalidades)
    this.$store.commit("setFuncionalidades", this.user.funcionalidades);
    this.$store.commit("setUser", this.user);
  },

  data() {
    return {
      user: [],
    };
  },

  methods: {
    async logado() {
      const response = await PessoaService.logado();

      if (!response.error) {
        this.user = response.data;
      }
    },
  },
};
</script>

<style>
@import "../style.css";
</style>
