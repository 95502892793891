<template>
  <div class="d-contens">
    <Header />
    <v-main>
      <Snackbar :snackbar="$store.state.dialog" />

      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import Snackbar from "../components/snackbar/Snackbar.vue";

export default {
  name: "App",

  components: {
    Header,
    Snackbar,
  },
};
</script>

<style>
@import "../style.css";
</style>
