import FormDialog from "../../../components/dialog/form/FormDialog.vue";
import SelectField from "../../../components/fields/selectField/SelectField.vue";
import TextAreaField from "../../../components/fields/textAreaField/TextAreaField.vue";
import TextField from "../../../components/fields/textField/TextField.vue";
import AutoCompleteField from "../../../components/fields/auto-complete/AutoCompleteField.vue";
// import MateriaisService from "../../../services/MateriaisService";
// import GrupoService from "../../../services/GrupoService";
import AlertaService from "../../../services/AlertaService";
import { SnackbarMixin } from "../../../mixins";
import materiaisMixins from "../../../mixins/materiaisMixins";
import tipoAlertasMixins from "../../../mixins/tipoAlertasMixins";
import unidadesMixins from "../../../mixins/unidadesMixins";
import moment from "moment";
// import Vue from "vue";

export default {
  name: "FormularioAlertas",

  components: {
    TextField,
    FormDialog,
    SelectField,
    AutoCompleteField,
    TextAreaField
  },

  mixins: [SnackbarMixin, materiaisMixins, tipoAlertasMixins, unidadesMixins],

  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    materialId: {
      default: null,
      required: false,
    },
    initialForm: {
      type: Object,
      required: true,
    }
  },

  watch: {
    open() {
      this.$refs.form.resetValidation();
      this.formularioDialog = !this.formularioDialog;
      this.form = this.initialForm;
      console.log(this.initialForm,"this.this.materialIdthis.materialId")
      if(this.materialId != null){
        this.form.materialID = this.materialId.id 
        this.disabledMaterial = true;
      }

      if(this.type == 'Edit' || this.type == 'Duplicar' ){
        this.montaEditMaterialInfo();
      }
    },
  },

  created() {
    this.initializeForm();
  },

  data: () => ({
    utilizarDtAtual: null,
    form: {},
    materialInfo: {},
    materiais: [],
    disabledMaterial: false,
    options: {
      materiais: [],
      tipoAlerta: [],
      unidades: []
    },
    loading:{
      materiais: false,
    },
    formValid: true,
    formularioDialog: false,

    tipoAlerta: "Quantidade",

    codigoOptions: [],
    visivelOptions: [],
    materialOptions: [],
    periodoOptions: [],
    inicioOptions: [],
    tipoDataOptions: [],

    tipoAlertaOptions: [],
  }),

  mounted(){
    if(this.type == 'New' || this.type == 'Duplicar'){
      this.form = this.initialForm;
    }
  }, 

  methods: {
    initializeForm(){
      this.form = {
        id:null,
        materialID:null,
        tipo:null,
        observacao:null,
        condicao:null,
        comparativo:null,
        titulo:null,
        unidade:null,
        dataBase:null,
        utilizaDataBase:null,
        auxDataQuantidade:null,
        auxTipoData:null,
        unidadeData:null,
        data_fabricacao:null,
        data_validade:null,
        created_at:null,
        quantidade:null,
        demanda_ideal:null,
      }
    },

   
    calculaDiasAteAlerta(){
      const diaAtual = moment().startOf('day');
      let dataBase = moment(this.form.dataBase).endOf('day');

      if(this.form.utilizaDataBase){
        this.form.comparativo = dataBase.diff(diaAtual, "days");
      } else {
        dataBase.add(this.form.auxDataQuantidade, this.form.auxTipoData)

        this.form.comparativo = dataBase.diff(diaAtual, "days");
      }
     this.form.unidadeData = this.defineData(this.form.auxTipoData)
      console.log( this.form.auxTipoData)
    },

    defineData(data) {
      if (data == 'days') {
          return 'dias'
      }
      if (data == 'months') {
        return 'meses'
      }
      if (data == 'years') {
        return 'anos'
      }
    },

    updateMaterialInfo(materialId) {
      // this.materialInfo = this.materiaisOpcoes?.find(material => material?.id == material[0]);
      
      let materialObj;
      if(materialId){
      this.materiaisOpcoes.forEach(material => {
          if(material.value == materialId[0]){
            materialObj = material
          }
        });
      }
      console.log(materialId);
      // Formatar a data de entrada para "DD/MM/YY"
      this.materialInfo.entrada = materialObj.entrada ? this.formatDate(materialObj.entrada) : null;
      this.materialInfo.entrada_sem_tratar = materialObj.entrada ? this.formatDate(materialObj.entrada) : null;
      // Formatar a data de vencimento para "DD/MM/YY", se não for nula
      this.materialInfo.vencimento = materialObj.vencimento ? this.formatDate(materialObj.vencimento) : null;
      this.materialInfo.vencimento_sem_tratar = materialObj.vencimento ? this.formatDate(materialObj.vencimento) : null;
      // Formatar a data de fabricação para "DD/MM/YY", se não for nula
      this.materialInfo.fabricacao = materialObj.fabricacao ? this.formatDate(materialObj.fabricacao) : null;
      this.materialInfo.fabricacao_sem_tratar = materialObj.fabricacao ? materialObj.fabricacao : null;
      this.materialInfo.quantidade = materialObj.quantidade;
      this.materialInfo.demanda = materialObj.demanda;
      
    },
    // Função para formatar a data
    formatDate(dateString) {
      let data = new Date(dateString);
      let dia = data.getDate().toString().padStart(2, '0');
      let mes = (data.getMonth() + 1).toString().padStart(2, '0');
      let ano = data.getFullYear().toString();
      return `${dia}/${mes}/${ano}`;
    },

    async prepareForm(){
      if(this.form.tipo != "1"){
        this.form.condicao = 2;
        this.calculaDiasAteAlerta();
      }

      if ( this.type == 'Edit'){
        this.form.materialID =  this.form.materialID[0]
      }

      const json = this.montaJson(this.form.tipo);
      const observacao_var = this.form.tipo == "1" ? this.form.observacao : null;
      const postData = {
        material_id: this.form.materialID,
        tipo_alerta_id: this.form.tipo,
        observacao: observacao_var,
        json: json,
        titulo: this.form.titulo,
        unidade_id: this.form.unidade,
        status_id: '1' // Ativo 
      }
      
      return postData;
    },

    async cadastrarAlerta(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();
      const response = await AlertaService.cadastrarAlerta(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.$emit("success");
      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("O alerta foi criado com sucesso");
      this.initializeForm();
      this.$refs.form.resetValidation();
    },

    montaJson(tipoAlerta) {
      if (tipoAlerta == 1) {
        const json = {
          tipoData: this.materialInfo.fabricacao_sem_tratar,
          condicao: this.form.condicao,
          valor: this.form.comparativo,
        }
        
        return  JSON.stringify(json);
      } else {
        const json = {
          tipoData: this.form.dataBase,
          dataInformada: this.form.dataBase,
          periodoApos: {
            quantidade: this.form.comparativo,
            unidade: this.form.unidadeData,
          }
        }
    
       return  JSON.stringify(json);
      }
    },

    async editarAlerta(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await AlertaService.editarAlerta(this.form.id, postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.$emit("success");
      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("O alerta foi editado com sucesso");
      // this.initializeForm();
      this.$refs.form.resetValidation();
    },

    resetForm(){
      this.$refs.form.resetValidation();
    },

    montaEditMaterialInfo(){
      this.materialInfo.entrada = this.form?.created_at ? this.formatDate(this.form.created_at) : null;
      // Formatar a data de vencimento para "DD/MM/YY", se não for nula
      this.materialInfo.vencimento = this.form?.data_validade ? this.formatDate(this.form.data_validade) : null;
      // Formatar a data de fabricação para "DD/MM/YY", se não for nula
      this.materialInfo.fabricacao = this.form?.data_fabricacao ? this.formatDate(this.form.data_fabricacao) : null;
      this.materialInfo.quantidade = this.form.quantidade;
      this.materialInfo.demanda = this.form.demanda_ideal;

    }
  }
};
