import TextField from "../../../../components/fields/textField/TextField.vue";
import TextAreaField from "../../../../components/fields/textAreaField/TextAreaField.vue";
import AutoCompleteField from "../../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
import MateriaisService from "../../../../services/MateriaisService";
import { SnackbarMixin } from "../../../../mixins";

export default {
  name: "DialogDescarte",
  components: {
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    TextAreaField
  },

  mixins: [SnackbarMixin],

  data: () => ({
    dialog: false,
    formValid: true,
    form: {},
    options: {},
  }),

  props: {
    activeDialog: {
      default: true,
      type: Boolean
    },
    materiais: {
      default: () => [],
      type: Array
    }
  },

  created() {
    this.initializeForm();
  },

  methods: {
    setMaterialInfo(){
      const arrayDescricao = this.materiais.map(material => material.item.descricao);
      const descricoes = arrayDescricao.join(", ");
      this.form.material = descricoes;

      const arrayCodigos = this.materiais.map(material => material.codigo_catmas);
      const codigos = arrayCodigos.join(", ");
      this.form.idMaterial = this.materiais.map(material => material.id);
      this.form.codigo = codigos;
    },

    initializeForm() {
      this.form = {
        material: "",
        observacao: "",
        codigo: "",
        idMaterial: [],
      };
    },

    async prepareForm(){
      const postData = {
        id: this.form.idMaterial,
        observacao: this.form.observacao,
      }
      
      return postData;
    },
    
    async descartarMaterial(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await MateriaisService.descartarMaterial(postData);

      if (response.error) {
        if(response.message.status.code == 502){
          this.showSnackbar("Material possue alertas vinculados", "error");
          return
        }
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O material foi descartado com sucesso");
      this.initializeForm();
      this.$refs.form.resetValidation();
      this.$emit("updateData");

      this.dialog = false;
    },
  },
};
