import TextField from "../../components/fields/textField/TextField.vue";
import TextAreaField from "../../components/fields/textAreaField/TextAreaField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import FormDialog from "../../components/dialog/form/FormDialog.vue";
import HistoricoSolicitacao from "./historico-solicitacao/HistoricoSolicitacao.vue";
import VinculoMaterial from "./vinculo-material/VinculoMaterial.vue";
import Vue from "vue";
import ReciboService from "../../services/ReciboService";
import PermutaService from "../../services/PermutaService";
import { AssinaturaMixin, FiltroMixin, SnackbarMixin } from "../../mixins";
import MateriaisService from "../../services/MateriaisService";
// import { ITENS } from "../../constants";
// import moment from "moment";
import PerfilService from "../../services/PerfilService";
import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
import ConfirmPasswordDialog from "../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import PessoaPerfilTemporarioService from "../../services/PessoaPerfilTemporarioService";
import materiaisMixins from "../../mixins/materiaisMixins";
import funcionalidadePermissaMixin from "../../mixins/funcionalidadePermissaMixin";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "MeusDados",

  components: {
    TextField,
    FormDialog,
    SelectField,
    TextAreaField,
    HistoricoSolicitacao,
    VinculoMaterial,
    AutoCompleteField,
    ConfirmPasswordDialog
  },

  mixins: [SnackbarMixin, AssinaturaMixin, FiltroMixin,materiaisMixins,funcionalidadePermissaMixin],
  

  methods: {
    initializePermutaForm() {
      this.permuta.form = {
        material: "",
        tamanho: "",
        tamanhoPossuido: "",
      };
    },

    initializeAcessoForm() {
      this.acesso.form = {
        perfil_atual: "",
        perfil_solicitado: [],
        data_inicio: "",
        data_limite: "",
        justificativa: "",
        validation: {
          dataInicioMaior: true
        }
      };
    },


    geraOptionsDinamic(arrayOptions, defaultOption = "Selecione") {
      let options = [];
      options.push({ text: defaultOption, value: "" });

      arrayOptions.forEach((option) => {
        options.push({ text: option.text, value: option.value });
      });

      return options;
    },

    async getPerfis() {
      this.loading.perfis = true;
      const response = await PerfilService.getPerfilOptions();

      if(!response.error){

        const arrayPerfis = await response.data.data.map((item) => {
          return { text: item.nome, value: item.id };
        });
        
        this.options.perfis = this.geraOptionsDinamic(arrayPerfis, "Selecione");
        this.options.perfis.shift();

      }
      this.loading.perfis = false;
    },

   
    async getMateriaisVinculado() {
      this.loading.materiais = true;
      const data = await MateriaisService.getMateriaisVinculado();

      console.log('data', data.data.data)


      this.epiVincular.items = data.data.data.map(obj => {
        const tagsTransformadas = obj.tags.map(tag => tag.nome).join(', ');
        obj.tags = tagsTransformadas
        

        return { ...obj };
      })
      this.epiVincular.pageCount = data.data.meta.last_page
      this.loading.materiais = false;
      

    },

    async savePermuta(){
      if (this.$refs.form.validate()) {
        const postData = {
          material_id: this.permuta.form.material,
          tam_quero: this.permuta.form.tamanho,
          tam_tenho: this.permuta.form.tamanhoPossuido,
        };

        let response = await PermutaService.cadastrarPermuta(postData);

        if (!response.error) {
          this.showSnackbar("A permuta foi cadastrada com sucesso");
          this.getMateriaisVinculado();

          this.permuta.showForm = !this.permuta.showForm;
        } else {
          this.showSnackbar("Não foi possível completar a solicitação", "error");
        }
        return;
      }
    },

    async saveAcesso(){

      if(this.validarDataInicio()){
        return this.showSnackbar("Data inicio tem que ser menor que data limite", "error");
      }

      if (this.$refs.formAcesso.validate()) {
        const postData = {
          justificativa: this.acesso.form.justificativa,
          data_inicio: this.acesso.form.data_inicio,
          data_fim: this.acesso.form.data_limite,
          perfil: [...this.acesso.form.perfil_solicitado],
        };

        const response = await PessoaPerfilTemporarioService.insertPessoaPerfilTemporario(postData);
        if(!response.error){
          this.showSnackbar("A solicitação de perfil foi realizada com sucesso");
          this.acesso.showForm = !this.acesso.showForm;
          return
        }
        this.showSnackbar("Não foi possível completar a solicitação", "error");

      }
    },

    validarDataInicio() {
      const dataInicio = new Date(this.acesso.form.data_inicio);
      const dataLimite = new Date(this.acesso.form.data_limite);
    
      const anoInicio = dataInicio.getFullYear();
      const mesInicio = dataInicio.getMonth();
      const diaInicio = dataInicio.getDate();
    
      const anoLimite = dataLimite.getFullYear();
      const mesLimite = dataLimite.getMonth();
      const diaLimite = dataLimite.getDate();
    
      const dataInicioMaior =
        anoInicio > anoLimite ||
        (anoInicio === anoLimite && mesInicio > mesLimite) ||
        (anoInicio === anoLimite && mesInicio === mesLimite && diaInicio > diaLimite);
    
      return dataInicioMaior;
    },



    async getLogRecibo() {
      this.loading.table = true;
      
      const data = await ReciboService.getRecibos();

      this.recibo.items = data.data.data;
      this.recibo.pageCount = data.data.last_page
      this.loading.table = false;
    },



    async emitirRecibo(reciboid) {
      try{
        this.loading.recibo = true;
        const data = await ReciboService.emitirReciboDownload(reciboid);
        
        this.exportBlob(data.result); 
      } finally {
        
        this.loading.recibo = false;
      }
    },

    exportBlob(base64str){
      const url = this.geraBlob(base64str);
      window.open(url);
    },

    geraBlob(base64str){
      var binary = atob(base64str.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
      }
         
      var blob = new Blob( [view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);

      return url;
    },

 
    async aprovarVinculacao(materialID) {
      this.loading.table = true;

      const postData = {
        id:materialID,
        status_id: 2

      };

      const response = await MateriaisService.aprovarVinculacao(postData);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.table = false;
        return;
      }
      
      this.showSnackbar("A aprovação da movimentação foi realizada com sucesso");
      this.loading.table = false;
      this.getMateriaisVinculado();
    },

    async desvincularMaterial(materialID) {
      this.loading.table = true;

      const postData = {
        id:[materialID]
        
      };
      const response = await MateriaisService.desvincularMaterial(postData);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.table = false;
        return;
      }
      
      this.showSnackbar("A reprovação foi realizada com sucesso");
      this.loading.table = false;
      this.getMateriaisVinculado();

    },
  },

  created() {
    this.initializePermutaForm();
    this.initializeAcessoForm();
    this.user = this.$store.state.user
    const nomesDosPerfis = this.user.perfis.map(perfil => perfil.nome).join(', ');
    this.user.perfis = nomesDosPerfis

  },

  async mounted(){
    const keycloak = Vue.prototype.$keycloak;
    this.userinfo = await keycloak.loadUserInfo();
    // await this.getUserInfo();
    this.getLogRecibo();


    this.getMateriaisVinculado();
    this.getPerfis();
  },

  data: () => ({
    options: {
      materiais: [],
      perfis: [],
    },
    FuncionalidadeEnum: FuncionalidadeEnum,
    user: {
      id: "",
      name: "",
      photo: "",
      perfis: ""
    },
    permissionsUser:[],
    recibo: {
      headers: [
        { text: "Número do recibo", value: "id", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
      items: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },

    epiVincular: {
      headers: [
        { text: "Tipo de material", value: "item_tipo.nome", sortable: false },
        { text: "Material", value: "descricao", sortable: false },
        { text: "Código", value: "codigo_catmas.codigo_catmas", sortable: false },
        { text: "Categoria", value: "categoria.nome", sortable: false },
        { text: "Subcategoria", value: "subcategoria.nome", sortable: false },
        { text: "TAGs", value:"tags", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
      items: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
    permuta: {
      showForm: false,
      form: {},
    },
    acesso: {
      showForm: false,
      form: {},
      options: {
        perfil: [],
      },
    },
    loading: {
      table: false,
      recibo: false,
      perfil: false,
    },
    formValid: false,
    formValidAcesso: false,
    historicoSolicitacaoDialog: false,
    validarVinculoDialog: false,

    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
  }),
};
