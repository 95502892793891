import Vue from "vue";
import VueRouter from "vue-router";

import TemplatesRoutes from "./templates.js";

import Home from "../views/home/Home.vue";
import RelacaoItens from "../views/relacao-itens/RelacaoItens.vue";
import RelatorioGeral from "../views/relatorio-geral/RelatorioGeral.vue";
import RelatorioAuditoria from "../views/relatorio-auditoria/RelatorioAuditoria.vue";
import RelacaoItensForm from "../views/relacao-itens/form/Form.vue";
import Controle from "../views/controle/Controle.vue";
import Movimentacoes from "../views/controle/movimentacoes/Movimentacoes";
import MateriaisDisponibilizadosSemAprovacao from "../views/disponibilizacao-material/materias-disponibilizados-sem-aprovacao/MateriaisDisponibilizadosSemAprovacao";
import HistoricoMovimentacoes from "../views/controle/historico-movimentacoes/HistoricoMovimentacoes";
import HistoricoDescarteDescarga from "../views/controle/historico-descarte-descarga/HistoricoDescarteDescarga";
import Tag from "../views/tag/Tag.vue";
import Categoria from "../views/categoria/Categoria.vue";
import Subcategoria from "../views/subcategoria/Subcategoria.vue";
import Disponibilizacao from "../views/disponibilizacao-material/Disponibilizacao.vue";
import DisponibilizacaoMaterial from "../views/disponibilizacao-material/consultar/DisponibilizacaoMaterial.vue";
import SolicitacaoMateriais from "../views/disponibilizacao-material/solicitacao-materiais/SolicitacaoMateriais.vue";
import EPISDisponibilizadas from "../views/disponibilizacao-material/epi-disponibilizada/EPIDisponibilizadas.vue";
import MeusDados from "../views/meus-dados/MeusDados.vue";
import Painel from "../views/painel/Painel.vue";
import MapaCalor from "../views/mapa-calor/MapaCalor.vue";
import Alertas from "../views/alertas/Alertas.vue";
import Layout from "../views/Layout.vue";
import GerenciarPerfis from "../views/gerenciar-usuarios/gerenciar-perfis/GerenciarPerfis.vue";
import GerenciarUsuarios from "../views/gerenciar-usuarios/GerenciarUsuarios.vue";
import GerenciamentoAlertas from "../views/gerenciamento-alertas/GerenciamentoAlertas.vue";
// import PerfilService from "../services/PerfilService.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/",
    meta: {
      //permissionRequired: []
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
          //permissionRequired: []
        }
      },
      {
        path: "/relacao-itens",
        name: "RelacaoItems",
        component: RelacaoItens,
        meta: {
          //permissionRequired: ['item.show']
        }
      },
      {
        path: "/relatorio-geral",
        name: "RelatorioGeral",
        component: RelatorioGeral,
        meta: {
          //permissionRequired: ['relatorio.geral']
        }
      },
      {
        path: "/relatorio-auditoria",
        name: "RelatorioAuditoria",
        component: RelatorioAuditoria,
        meta: {
          //permissionRequired: ['relatorio.auditoria']
        }
      },
      {
        path: "/gerenciamento-alertas",
        name: "GerenciamentoAlertas",
        component: GerenciamentoAlertas,
        meta: {
          //permissionRequired: ['alerta.index']
        }
      },
      {
        path: "/relacao-itens/cadastrar",
        name: "RelacaoItensCadastrar",
        component: RelacaoItensForm,
        meta: {
          //permissionRequired: []
        }
      },
      {
        path: "/relacao-itens/:itemid/editar",
        name: "RelacaoItensEditar",
        component: RelacaoItensForm,
        meta: {
          //permissionRequired: []
        }
      },
      {
        path: "/controle",
        name: "Controle",
        redirect: "/controle/movimentacoes",
        component: Controle,
        meta: {
          //permissionRequired: ['material.show']
        },
        children: [
          {
            path: "/controle/movimentacoes",
            name: "Movimentacoes",
            component: Movimentacoes,
            meta: {
              //permissionRequired: ['material.show']
            }
          },
          {
            path: "/controle/historico-descarte-descarga",
            name: "HistoricoDescarteDescarga",
            component: HistoricoDescarteDescarga,
            meta: {
              //permissionRequired: ['logs.getRota']
            }
          },
          {
            path: "/controle/historico-movimentacoes",
            name: "HistoricoMovimentacoes",
            component: HistoricoMovimentacoes,
            meta: {
              //permissionRequired: ['logs.getRota']
            }
          },
        ],
      },
      {
        path: "/tags",
        name: "Tag",
        component: Tag,
        meta: {
          //permissionRequired: ['tag.show']
        }
      },
      {
        path: "/categorias",
        name: "Categoria",
        component: Categoria,
        meta: {
          //permissionRequired: ['categoria.show']
        }
      },
      {
        path: "/subcategorias",
        name: "Subcategoria",
        component: Subcategoria,
        meta: {
          //permissionRequired: ['subcategoria.show']
        }
      },
      {
        path: "/disponibilizacao-materiais",
        name: "Disponibilizacao",
        meta: {
          //permissionRequired: ['logs.getRota"']
        },
        redirect: "/disponibilizacao-materiais/consultar",
        component: Disponibilizacao,
        children: [
          {
            path: "/disponibilizacao-materiais/consultar",
            name: "DisponibilizarMateriais",
            component: DisponibilizacaoMaterial,
            meta: {
              //permissionRequired: []
            }
          },
          {
            path: "/disponibilizacao-materiais/solicitacao-materiais",
            name: "SolicitacaoMateriais",
            component: SolicitacaoMateriais,
            meta: {
              //permissionRequired: []
            }
          },
          {
            path: "/disponibilizacao-materiais/materias-disponibilizados-sem-aprovacao",
            name: "MateriaisDisponibilizadosSemAprovacao",
            component: MateriaisDisponibilizadosSemAprovacao,
            meta: {
              //permissionRequired: ['material.show']
            }
          },
          {
            path: "/disponibilizacao-materiais/epis-disponibilizadas",
            name: "EPISDisponibilizadas",
            component: EPISDisponibilizadas,
            meta: {
              //permissionRequired: []
            }
          },
        ],
      },
      {
        path: "/meus-dados",
        name: "MeusDados",
        component: MeusDados,
        meta: {
          //permissionRequired: ['ver.dados']
        }
      },
      {
        path: "/alertas",
        name: "Alertas",
        component: Alertas,
        meta: {
          //permissionRequired: ['alerta.index']
        }
      },
      {
        name: "CadastrarPerfis",
        path: "/gerenciar-perfis",
        component: GerenciarPerfis,
        meta: {
          //permissionRequired: []
        }
      },
      {
        name: "EditarPerfis",
        path: "/gerenciar-perfis/:perfilid",
        component: GerenciarPerfis,
        meta: {
          //permissionRequired: ['perfil.update']
        }
      },
      {
        name: "GerenciarUsuarios",
        path: "/gerenciar-usuarios",
        component: GerenciarUsuarios,
        meta: {
          //permissionRequired: ['menu.perfil']
        }
      },
      {
        name: "Painel",
        path: "/painel",
        component: Painel,
        meta: {
          //permissionRequired: ['visualizar.painel']
        }
      },
      {
        name: "MapaCalor",
        path: "/mapa-calor",
        component: MapaCalor,
        meta: {
          //permissionRequired: ['mapa.calor']
        }
      },
    ]
  },
];

routes[0].children.push(TemplatesRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   verifcaPermission().then(() => {
//     getRoles().then(() => {
//       console.log('aqui', to)
//       // console.log(Vue.prototype.$permissionsUser.includes)

//       // if(to.meta.permissionRequired.length == 0){
//       //   return next();
//       // }
//       // const permissionAcessRoute = Vue.prototype.$permissionsUser.includes(to.meta.permissionRequired[0]);

//       // return permissionAcessRoute ? next() : next('/')
//       next();

    
//     });
//   });

// });

// function verifcaPermission() {
//   return new Promise((resolve) => {
//     if(!Vue.prototype.$permissionsUser){
//       PerfilService.getMyPermissions().then(response => {
//         Vue.prototype.$permissionsUser = response;
//         resolve();
//       })
//     } else {
//       resolve();
//     }
//   })
// }

// function getRoles() {
//   return new Promise((resolve) => {
//     if(!Vue.prototype.$rolesUser){
//       PerfilService.getMyRoles().then(response => {
//         Vue.prototype.$rolesUser = response;
//         resolve();
//       })
//     } else {
//       resolve();
//     }
//   })
// }

export default router;
