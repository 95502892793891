import TextField from "../../../components/fields/textField/TextField.vue";
import SelectField from "../../../components/fields/selectField/SelectField.vue";
import FormDialog from "../../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import moment from "moment";
import Vue from "vue";
import { AssinaturaMixin,SnackbarMixin,FiltroMixin } from "../../../mixins";
import ConfirmPasswordDialog from "../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import MateriaisService from "../../../services/MateriaisService";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";

export default {
  name: "DisponibilizacaoMaterial",

  components: { TextField, SelectField, FormDialog, ConfirmDialogButton,ConfirmPasswordDialog },
  mixins: [SnackbarMixin, AssinaturaMixin, FiltroMixin],
  
  data: () => ({
    headers: [
      { text: "Material", value: "material_patrimonio", sortable: false },
      { text: "Código Catmas", value: "codigo_catmas", sortable: false },
      { text: "Quantidade", value: "quantidade", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      { text: "Data", value: "created_at", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    items: [],
    FuncionalidadeEnum: FuncionalidadeEnum,
    itemsClear:[],
    page: 1,
    pageCount: 0,
    lastPage:1,
    itemsPerPage: 10,
    form: {},
    filter: {},
    options: {
      administrador: [],
    },
    materiais:[],
    formValid: false,
    formularioDialog: false,
    limiteQuantidade: "",
    formTitle: "",
    formType: "",
    loading: {
      table: false
    },
    searchReset:false,
    permissionsUser:[],
    
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
  }),

  created() {
    this.initializeForm();
    this.permissionsUser = Vue.prototype.$permissionsUser;

  },
  
  async mounted() {
    this.getDisponibilizarMaterial();
  },

  watch: {
    page(){
      this.getDisponibilizarMaterial();
    }
  },

  methods: {
    async getDisponibilizarMaterial() {
      this.loading.table = true;
      const params = {
        material: this.filter.material,
        codigo: this.filter.codigo,
        quantidade: this.filter.quantidade,
        unidade: this.filter.unidade,
        data_criacao: this.filter.data_criacao,
        page: this.page
      };
    
      const response = await MateriaisService.disponibilizarMaterial(params);
      this.items = this.trataData(response.data.data);
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;
      this.loading.table = false;
    },

    trataData(data){
      let dataTratada = [];

      data.forEach((material) => {
        dataTratada.push(
          {
            material_id: material.material_id,
            material_quantidade: material.material_quantidade,
            material: material.descricao_catmas,
            material_disponibilizados_id: material.material_disponibilizados_id,
            codigo_catmas_id: material.catmas_id,
            codigo_catmas: material.codigo_catmas,
            descricao_catmas: material.descricao_catmas,
            unidade_id: material.unidade_id,
            unidade: material.unidade_codigo,
            created_at: moment(material.created_at).format("DD/MM/YYYY HH:mm"),
            material_patrimonio: material.material_patrimonio ? material.material_patrimonio : "",
            quantidade: material.quantidade
          }
        );
      });
      return dataTratada;
    },
    
    search() {
      this.getDisponibilizarMaterial();
    },

    activeFormDialog(title, type, item) {
      console.log(item,'itemitem')
      this.formTitle = title;
      this.formType = type;
      this.form.material = item.material_patrimonio
      // this.form.quantidade = item.quantidade
      this.form.material_disponibilizados_id = item.material_disponibilizados_id
      this.limiteQuantidade = item.material_quantidade;
      console.log(this.limiteQuantidade)
      this.form.unidade_id = item.unidade_id;
      

      this.formularioDialog = !this.formularioDialog;
    },

    initializeForm() {
      this.form = {
        material_disponibilizados_id: "",
        quantidade: "",
        patrimonio: "",
        vinculo_sigrh_id: "",
        limiteQuantidade: "",
        unidade_id: "",
      };
    },

    initializeFilters() {
      this.filter = {
        material: "",
        codigo: "",
        quantidade: "",
        unidade_id: "",
        data_criacao: "",
        status_id: "",
      };
    },

    async prepareForm(){
      // const keycloak = Vue.prototype.$keycloak;
      // const userinfo = await keycloak.loadUserInfo();
      // const unidade_solicitou = await userinfo.groups[0];
      // const usuario = await userinfo.sub;
      const postData = {
        material_disponibilizado_id: this.form.material_disponibilizados_id,
        quantidade_solicitada: Number(this.form.quantidade),
        unidade_id: this.form.unidade_id,
        // unidade_solicitou: unidade_solicitou,
        // usuario
      }
      
      return postData;
    },

    async solicitarDisponibilizacao() {
      if(!this.$refs.form.validate()){
        return;
      }
      const postData = await this.prepareForm();

      const response = await MateriaisService.solicitarMaterial(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("O material foi solicitado com sucesso");
      this.initializeForm();
      this.$refs.form.resetValidation();
    },
  },
};
