import DialogMovimentacao from "../dialogMovimentacao/DialogMovimentacao.vue";
import DialogVincular from "../dialogVincular/DialogVincular.vue";
import DialogDisponibilizar from "../dialogDisponibilizar/DialogDisponibilizar.vue";
import DialogDescarga from "../dialogDescarga/DialogDescarga.vue";
import DialogDescarte from "../dialogDescarte/DialogDescarte.vue";

export default {
  name: "DialogItensSelecionados",
  components: {
    DialogMovimentacao,
    DialogVincular,
    DialogDisponibilizar,
    DialogDescarga,
    DialogDescarte,
  },
  
  props: {
    materiais: {
      default: () => [],
      required: true,
    },
  },

  watch: {
    materiais() {
      if(this.materiais){
        const materiaisOrdenados = this.groupBy(this.materiais, "descricao");
        let materiaisOrdenadosDetalhes = [];
        Object.keys(materiaisOrdenados).forEach(function(key) {
          const nome = key && key != "undefined" ? key : "";
          const quantidade = materiaisOrdenados[key].length;
          const itens = materiaisOrdenados[key];
          materiaisOrdenadosDetalhes.push({
            nome,
            quantidade,
            itens
          })        
        });
        
        this.materiaisPorNome = materiaisOrdenadosDetalhes;
      }
    },
  },
  
  data: () => ({
    dialog: false,
    materiaisPorNome: [],
    expanded: [],
    headers: [
      { text: "Material", value: "nome", sortable: false },
      { text: "Quantidade", value: "quantidade", sortable: false },
    ],
    headersMaterial: [ { "text": "Código", "value": "codigo_catmas", "sortable": false, "width": 120 }, { "text": "Descrição", "value": "descricao", "sortable": false, "width": 120 }, { "text": "Patrimônio", "value": "patrimonio_serie", "sortable": false, "width": 120 }, { "text": "Vínculo", "value": "vinculo", "sortable": false, "width": 120 }, { "text": "Disponibilizado", "value": "disponibilizadoInfo", "sortable": false, "width": 120 }, { "text": "Tipo", "value": "tipo", "sortable": false, "width": 120 }, { "text": "Administrador", "value": "admType", "sortable": false, "width": 120 }, { "text": "Categoria", "value": "categoria", "sortable": false, "width": 120 }, { "text": "Subcategoria", "value": "subcategoria", "sortable": false, "width": 120 }, { "text": "Tags", "value": "tags", "sortable": false, "width": 120 }, { "text": "Descrição CATMAS", "value": "desc_catmas", "sortable": false, "width": 120 }, { "text": "Marca", "value": "marca", "sortable": false, "width": 120 }, { "text": "Modelo", "value": "modelo", "sortable": false, "width": 120 }, { "text": "Template", "value": "template", "sortable": false, "width": 120 }, { "text": "Unidade", "value": "unidade", "sortable": false, "width": 120 }, { "text": "Data de entrada", "value": "dataEntrada", "sortable": false, "width": 120 }, { "text": "Data de validade", "value": "dataVal", "sortable": false, "width": 120 }, { "text": "Ações", "value": "actions", "sortable": false, "align": "center" } ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  created() {},

  methods: {
    groupBy(data, key) { 
      return data.reduce(function(storage, item) {
        var group = item[key];
        storage[group] = storage[group] || [];    
        storage[group].push(item);   
  
        return storage; 
      }, {});
    }
  }
};
