import BaseService from "./BaseService";
import api from "./Api"

export default {
  async getMateriais(params = "") {  
    const config = {params: params}
    const response = await api.get(`material/search?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },


  async getMateriaisVinculado(params) { 
    const config = {params: params}

    const response = await api.get(`/material/vinculado/search`, config)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async getMateriaisSemPaginacao() {  
    const response = await api.get(`/material`)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  

  async getMateriaisIdItem(idItem, params) {  
        const config = {params: params}
        const response = await api.get(`/material/item/${idItem}`, config)
        .then((response) => response)
        .catch(err => err);

        return response;
    },

  async getMateriaisDisponibilizadosAprovacao(filters = "") {
    let response;
    const url = "public/material/listardisponibilizadofaltandoaprovacao";

    if (filters) {
      response = await BaseService.fetchResponseFromAPIGet(`${url}?${filters}`);
    } else {
      response = await BaseService.fetchResponseFromAPIGet(`${url}`);
    }

    const data = response.error ? [] : response.result;

    return data;
  },

  async aprovarDisponibilizacaoMaterial(materialid) {
    const response = await BaseService.fetchResponseFromAPIGet(
      `material/aprovardisponibilizado/${materialid}`,
    );

    return response.error ? false : response.result;
  },

  async cancelarDisponibilizacaoMaterial(materialid) {
    const response = await BaseService.fetchResponseFromAPIPost(
      `material/cancelardisponibilizacao/${materialid}`,
    );


    return response.error ? false : response;
  },


  async deleteItem(itemid) {
    const response = await BaseService.fetchResponseFromAPIDelete(
      `item/${itemid}`,
    );

    return response;
  },

  async deletaMaterial(materialid) {
    const response = await api.delete(`material/${materialid}`)
      .then((response) => response)
      .catch(err => err);

    return response
  },

  async getMaterialByID(materialid) {
    const response = await BaseService.fetchResponseFromAPIGet(
      `material/${materialid}`,
    );

    return response.error ? false : response.result;
  },

  async recusarMovimentacao(materialid) {
    const response = await BaseService.fetchResponseFromAPIGet(
      `material/cancelarvinculardisponibilizado/${materialid}`,
    );

    return response.error ? false : response.result;
  },


  async getAdmType() {
    const response = await BaseService.fetchResponseFromAPIGet(`public/adm`);
    const data = response.error ? [] : response.result;

    return data;
  },

  async cadastrarMaterial(postData) {
        const response = await api.post(`/material`,postData)
        .then((response) => response)
        .catch(err => err);

    return response;
  },

  async vincularMaterial(postData) {
    const response = await api.post(`/material/vinculado`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async movimentarMaterial(postData) {
    const response = await api.post(`/material/movimentar`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async aprovarVinculacao(postData) {
    const response = await BaseService.fetchResponseFromAPIPut(
      `material/vinculado`,
      JSON.stringify(postData),
    );

    const responseMessage = await response.message;

    if(responseMessage.status.code != 200){
      response.error = true;
    }

    return response;
  },

  async desvincularMaterial(postData) {
    const response = await BaseService.fetchResponseFromAPIPost(
      `material/vinculado/desvincular`,
      JSON.stringify(postData),
    );

    const responseMessage = await response.message;

    if(responseMessage.status.code != 200){
      response.error = true;
    }

    return response;
  },

  async disponibilizarMaterial(params) {
    const config = {params: params}
    const response = await api.get(`/material/disponibilizar/search?`, config)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async solicitacaoMaterial(params) {
    const config = {params: params}
    const response = await api.get(`/material/disponibilizar/solicitacao/search?`, config)
      .then((response) => response)
      .catch(err => err);
 
    return response;
  },

  async disponibilizarMaterialPost(postData) {
    const response = await api.post(`/material/disponibilizar`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async solicitarMaterial(postData) {
    const response = await api.post(`/material/disponibilizar/solicitar-disponibilizacao`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async aceitarMaterial(postData) {
    const response = await api.post(`/material/disponibilizar/aceitar-disponibilizacao`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async recusarMaterial(postData) {
    const response = await api.post(`/material/disponibilizar/recusar-disponibilizacao`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async aceitarSolicitacao(postData) {
    const response = await api.post(`/material/disponibilizar/solicitacao/aceitar-disponibilizacao`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async recusarSolicitacao(postData) {
    const response = await api.post(`/material/disponibilizar/solicitacao/recusar-disponibilizacao`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async descartarMaterial(postData) {
        const response = await api.post(`/material/descarte`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;

  },

    async descargaMaterial(postData) {
        const response = await api.post(`/material/descarga`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;

  },

  async getDescarteOuDescargaMaterial(params) {
    const config = {params: params}
    const response = await api.get(`material/descarte-descarga?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async editarMaterial(postData, materialid) {
    const response = await api.put(`material/${materialid}`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async alteraStatusMaterialMovimentar(postData, materialid) {
    const response = await api.put(`material/movimentar/${materialid}`,postData)
      .then((response) => response)
      .catch(err => err);

    return response
  },
      
  async getStatusMaterial(){
    const response = await api.get(`/material/status`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },
    
  async getEstadosMaterial(){
    const response = await api.get(`/material/estado`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  async getMovimentacoes(params){
    const config = {params: params}
    const response = await api.get(`/material/movimentar/search`, config)
        .then((response) => response)
        .catch(err => err);
    return response;
  },

  async getExportMaterialPDF(params){
    const config = {params: params}
    const response = await api.get(`/material/relatorio-geral/pdf`, config)
        .then((response) => response)
        .catch(err => err);
    return response;
  },

  async getExportMaterialCSV(params){
    const config = {params: params}
    const response = await api.get(`/material/relatorio-geral/csv`, config)
        .then((response) => response)
        .catch(err => err);
    return response;
  },

};
