import FormularioAlertas from "./formulario/FormularioAlertas.vue";
import FormDialog from "../../components/dialog/form/FormDialog.vue";
import TextAreaField from "../../components/fields/textAreaField/TextAreaField.vue";
import TextField from "../../components/fields/textField/TextField.vue";
import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
// import MateriaisService from "../../services/MateriaisService";
import AlertaService from "../../services/AlertaService";
import moment from "moment";
import { AssinaturaMixin, SnackbarMixin, FiltroMixin } from "../../mixins";
import materiaisMixins from "../../mixins/materiaisMixins";
import tipoAlertasMixins from "../../mixins/tipoAlertasMixins";
import ConfirmPasswordDialog from "../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import ConfirmDialogIconButton from "../../components/dialog/confirmIconButton/ConfirmDialogIconButton.vue";
import ConfirmDialogButton from "../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import Vue from "vue";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "Alertas",

  components: { AutoCompleteField, FormularioAlertas, FormDialog, TextAreaField, TextField, ConfirmPasswordDialog, ConfirmDialogIconButton, ConfirmDialogButton },

  data: () => ({
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
    headers: [
      { text: "Título", value: "titulo", sortable: false },
      { text: "Material", value: "patrimonio", sortable: false },
      // { text: "Código", value: "codigo", sortable: false },
      { text: "Data", value: "JsonObject.tipoDataTratada", sortable: false },
      { text: "Tipo de alerta", value: "tipo_alerta.nome", sortable: false },
      { text: "Unidade", value: "unidade.codigo", sortable: false },
      { value: "actions1", sortable: false },
      { value: "actions2", sortable: false },
    ],
    FuncionalidadeEnum: FuncionalidadeEnum,
    materiais: [],
    data: [],
    itemsSelected:[],
    permissionsUser:[],
    page: 1,
    pageCount: 0,
    lastPage:1,
    itemsPerPage: 10,
    pageDialog: 1,
    pageCountDialog: 0,
    lastPageDialog:1,
    itemsPerPageDialog: 10,
    formularioDialog: false,
    formularioDialogAlert: false,
    formularioDialogTwo:false,
    formTitle: "",
    deletarObservacao: "",
    formType: "",
    alert: {},
    showDialogDayAlerts: false,
    dialogDayTitle: "teste",
    dialogDayDados: [],
    arrayEvents: [],
    loading: {
      materiais: false,
      table: false,
    },
    options: {
      materiais: [],
    },
    initialForm: {},
    initialFormProps: {},
  }),

  mixins: [AssinaturaMixin, SnackbarMixin, FiltroMixin,  materiaisMixins, tipoAlertasMixins],

  created(){
    this.initializeForm();
    this.permissionsUser = Vue.prototype.$permissionsUser
  },

  mounted(){
    this.getAlertas();
  },
  watch: {
    page() {
      this.getAlertas();
    },
    pageDialog() {
      this.activeDayDialog();
    },
  },

  methods: {
    initializeForm(){
      this.initialForm = {
        materialID: "",
        tipo: "",
        observacao: "",
        comparativo: "",
        titulo: "",
        unidade: "",
        dataBase: "",
        utilizaDataBase: "",
        auxDataQuantidade: "",
        auxTipoData: ""
      }
    },
    initializeFormProps() {
      this.initialFormProps = {
        materialID: null,
        tipo: null,
        observacao: null,
        condicao: null,
        comparativo: null,
        titulo: null,
        unidade: null,
        dataBase: null,
        utilizaDataBase: null,
        auxDataQuantidade: null,
        auxTipoData: null,
        data_fabricacao:null,
        data_validade: null,
        created_at: null,
        quantidade: null,
        demanda_ideal: null,
      }
    },
    
    async getAlertas() {
      this.loading.table = true;
      const params = {
        lista_nao_concluido: true,
        page: this.page
      
      }

      const response = await AlertaService.getAlertas(params);
    
      this.data = this.trataJson(response.data.data);
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;

      // Verifica se a página atual é maior que a última página após a exclusão
      if (this.page > this.lastPage) {
        this.page = 1; // Define a página atual como 1
        await this.getAlertas(); // Chama novamente getTags para recarregar os dados da página 1
      }
      this.loading.table = false;
    },

    trataJson(alertas) {
      
      alertas.forEach(alerta => {
        alerta.JsonObject = JSON.parse(alerta.json)
        alerta.JsonObject.tipoDataTratada =  this.formatDate(alerta.JsonObject.tipoData)
        });
        
      return alertas
    },

    validaAssinaturaAlerta(id){
      if(this.deletarObservacao != ''){
        this.validaAssinatura(this.deletaAlerta, id)

      }
    },

    async deletaAlerta(idAlerta){
      const response = await AlertaService.deletaAlerta(idAlerta, this.deletarObservacao);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O alerta foi deletado com sucesso!");
      this.showDialogDayAlerts = false;
      this.getAlertas();
    },

    async concluirAlerta(idAlerta){
      let idsAlertas = [];
      if(Array.isArray(idAlerta)){
        idsAlertas = this.itemsSelected.map((item) => item.id);
      } else {
        idsAlertas = [idAlerta];
      }

      const postData = {
        ids: idsAlertas
      }
      console.log(postData)
      const response = await AlertaService.concluirAlerta(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O alerta foi concluído com sucesso!");
      this.showDialogDayAlerts = false;
      this.getAlertas();
    },

    async setAlerta(alertaSelecionado) {
      this.initialFormProps.id = alertaSelecionado.id;
      this.initialFormProps.materialID = [alertaSelecionado.material_id];
      this.initialFormProps.tipo = alertaSelecionado.tipo_alerta_id;
      this.initialFormProps.observacao = alertaSelecionado?.observacao;
      this.initialFormProps.condicao = alertaSelecionado?.JsonObject?.condicao ?? null;
      this.initialFormProps.comparativo = alertaSelecionado?.JsonObject?.valor ?? null;
      this.initialFormProps.titulo = alertaSelecionado.titulo;
      this.initialFormProps.unidade = alertaSelecionado.unidade_id;
      this.initialFormProps.dataBase = alertaSelecionado.JsonObject?.tipoData ?? null;
      this.initialFormProps.demanda_ideal = alertaSelecionado.demanda_ideal ?? null;
      this.initialFormProps.quantidade = alertaSelecionado.quantidade ?? null;
      this.initialFormProps.data_fabricacao = alertaSelecionado.data_fabricacao ?? null;
      this.initialFormProps.data_validade = alertaSelecionado.data_validade ?? null;
      this.initialFormProps.created_at = alertaSelecionado.created_at ?? null;

      // Verifica e define utilizaDataBase
      this.initialFormProps.utilizaDataBase = (
          alertaSelecionado?.JsonObject?.periodoApos?.quantidade === undefined &&
          alertaSelecionado?.JsonObject?.periodoApos?.quantidade !== null
      );

      // Define auxDataQuantidade
      this.initialFormProps.auxDataQuantidade = alertaSelecionado?.JsonObject?.periodoApos?.quantidade ?? null;

      // Define auxTipoData baseado em periodoApos.unidade
      if (alertaSelecionado?.JsonObject?.periodoApos?.unidade) {
          switch (alertaSelecionado?.JsonObject?.periodoApos?.unidade) {
              case 'dias':
                  this.initialFormProps.auxTipoData = "days";
                  break;
              case 'meses':
                  this.initialFormProps.auxTipoData = "months";
                  break;
              case 'anos':
                  this.initialFormProps.auxTipoData = "years";
                  break;
              default:
                  this.initialFormProps.auxTipoData = "outro valor";
          }
      } else {
          this.initialFormProps.auxTipoData = null;
      }
    console.log(this.initialFormProps, 'this.initialFormProps');
    },

    async activeFormDialog(title, type, alertaSelecionado = {}) {
      this.formTitle = title;
      this.formType = type;
      this.initializeForm();
      this.initializeFormProps();
      console.log(alertaSelecionado,'alertaSelecionadoalertaSelecionado');
      if(type == "Edit"){
        this.setAlerta(alertaSelecionado)
      }
      if(type == "Duplicar"){
        this.setAlerta(alertaSelecionado)  
      }

      // this.initialFormProps.tipo = 1;
      this.formularioDialog = !this.formularioDialog;
      this.showDialogDayAlerts = false;
    },

    async activeFormDialogTwo(title, type, alertaSelecionado = {}) {
      this.formTitle = title;
      this.formType = type;
      this.initializeForm();
      this.initializeFormProps();

      if(type == "Edit"){

        this.setAlerta(alertaSelecionado)
        
    
      }
      if(type == "Duplicar"){
        this.formType = "New";
        this.setAlerta(alertaSelecionado)
        
      }

      // this.initialFormProps.tipo = 1;
      this.formularioDialogTwo = !this.formularioDialogTwo;
      this.showDialogDayAlerts = false;
    },

    async activeDayDialog(daySelected) {
      this.showDialogDayAlerts = true;

      if (daySelected) {
        this.dialogDayTitle = moment(daySelected, "YYYY-MM-DD").format("DD/MM/YYYY");
      }

      const params = {
        tipoData: daySelected,
        page: this.pageDialog
      }
      const response = await AlertaService.getAlertas(params);
      
      response.data.data.forEach(alerta => {
       alerta.createdAtTratado= moment(alerta.created_at, "YYYY-MM-DD").format("DD/MM/YYYY");      
      });

      this.dialogDayDados = response.data.data
      this.pageCountDialog = response.data.total;
      this.lastPageDialog = response.data.last_page;
    
      // console.log( this.data.filter(alerta => alerta.JsonObject.tipoData === this.dialogDayTitle))
    },

    showDialogAlert(event = null){
      this.formularioDialogAlert = !this.formularioDialogAlert;
      console.log("data clicada", event);
    },

    formatDate(dateString) {
      // Crie um objeto de data a partir da string
      const dateObject = new Date(dateString);
      
      // Adicione um dia à data atual
      dateObject.setDate(dateObject.getDate() + 1);
      
      // Extraia o ano, mês e dia
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adiciona um zero à esquerda se o mês for menor que 10
      const day = String(dateObject.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se o dia for menor que 10
      
      const formattedDate = `${day}/${month}/${year}`;
      
      return formattedDate;
    }
  },
};
