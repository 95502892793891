export default {
  name: "VinculoMaterial",

  props: {
    activeDialog: {
      required: true,
      type: Boolean,
    },
  },

  watch: {
    activeDialog() {
      this.dialog = true;
    },
  },

  data: () => ({
    dialog: false,
    headers: [
      { text: "Tipo de Material", value: "nmMaterial", sortable: false },
      { text: "Material", value: "nmMaterial", sortable: false },
      { text: "Código", value: "tamanhoPossui", sortable: false },
      { text: "Categoria", value: "tamanhoDesejado", sortable: false },
      { text: "TAGS", value: "status", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    items: [
      {
        nmMaterial: "Capacete",
        tamanhoPossui: "XX",
        tamanhoDesejado: "YY",
        dtSolicitacao: "22/07/2021",
        status: "Pendente",
      },
    ],
    page: 1,
    pageCount: 0,
  }),

  methods: {
    close() {
      this.$emit("on-close");
    },
  },
};
