<template>
  <v-navigation-drawer app clipped v-model="drawer">
    <v-list dense nav>
      <template v-for="item in items">
        <v-list-item
          :key="item.title"
          :to="item.path"
          active-class="red--text"
          link
          v-if="!item.items && ($store.state.funcionalidades.includes(item.permissionRequired) || item.permissionRequired == true) "
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
        v-if="item.items && ($store.state.funcionalidades.includes(item.permissionRequired) || item.permissionRequired == true)"      
        :key="item.title"
          active-class="red--text"
        >
          <!-- this template is for the title of top-level items with children -->
          <template #activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="subItem in item.items">
            <!-- another v-if to determine if there's a 3rd level -->
            <!-- if there is NOT a 3rd level -->
              <!-- " -->
             

            <v-list-item
            v-if="$store.state.funcionalidades.includes(subItem.permissionRequired)"              
            class="ml-5"
              :key="subItem.title"
              active-class="red--text"
              :to="item.path + subItem.path"
            >
              <v-list-item-icon>
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import Vue from 'vue';
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum"
export default {
  name: "Navigation",
  props: {
    drawerClicked: {
      required: true,
      type: Boolean,
    },
  },

 

  mounted(){

    const menu = [
      {
        title: "Home",
        icon: "mdi-home",
        path: "/",
        permissionRequired: FuncionalidadeEnum.ACESSAR_PAGINA_MENU


      },
      {
        title: "Relação de Itens",
        icon: "mdi-relation-many-to-many",
        path: "/relacao-itens",
        permissionRequired: FuncionalidadeEnum.ACESSAR_ITEM_MENU,

      },
      {
        title: "Controle",
        icon: "mdi-toolbox-outline",
        path: "/controle",
        permissionRequired: FuncionalidadeEnum.ACESSAR_MOVIMENTACAO_MENU || FuncionalidadeEnum.ACESSAR_HISTORICO_MOVIMENTACAO_MENU || FuncionalidadeEnum.ACESSAR_HISTORICO_DESCARTE_MENU,
        items: [
          {
            path: "/movimentacoes",
            title: "Movimentações",
            icon: "mdi-toolbox-outline",
            permissionRequired: FuncionalidadeEnum.ACESSAR_MOVIMENTACAO_MENU,
          },
          {
            path: "/historico-movimentacoes",
            title: "Histórico Movimentações",
            icon: "mdi-book-clock-outline",
            permissionRequired: FuncionalidadeEnum.ACESSAR_HISTORICO_MOVIMENTACAO_MENU,
          },
          {
            title: "Histórico Descarte Descarga",
            icon: "mdi-book-clock-outline",
            path: "/historico-descarte-descarga",
            permissionRequired: FuncionalidadeEnum.ACESSAR_HISTORICO_DESCARTE_MENU,
          },
        ]
      },
      {
        title: "TAGs",
        icon: "mdi-tag-outline",
        path: "/tags",
        permissionRequired: FuncionalidadeEnum.ACESSAR_TAG_MENU,
      },
      {
        title: "Categorias",
        icon: "mdi-clipboard-list-outline",
        path: "/categorias",
        permissionRequired: FuncionalidadeEnum.ACESSAR_CATEGORIA_MENU,
      },
      {
        title: "Disponibilização de Materiais",
        icon: "mdi-tools",
        path: "/disponibilizacao-materiais",
        permissionRequired: FuncionalidadeEnum.ACESSAR_DISPONIBILIZAR_MATERIAL_MENU,
      },
      {
        title: "Meus Dados",
        icon: "mdi-account-box",
        path: "/meus-dados",
        permissionRequired: FuncionalidadeEnum.ACESSAR_MEUS_DADOS_MENU,
      },
      {
        title: "Gerenciamento de Alertas",
        icon: "mdi-calendar-alert",
        path: "/gerenciamento-alertas",
        permissionRequired: FuncionalidadeEnum.ACESSAR_ALERTA_MENU,
      },
      {
        title: "Cadastro de Alertas",
        icon: "mdi-calendar-alert",
        path: "/alertas",
        permissionRequired: FuncionalidadeEnum.ACESSAR_ALERTA_MENU,
      },
      {
        title: "Visualizar Mapas de Calor",
        icon: "mdi-map-search-outline",
        path: "/mapa-calor",
        permissionRequired: FuncionalidadeEnum.ACESSAR_MAPA_CALOR_MENU,
      },
      {
        title: "Relatório Geral",
        icon: "mdi-book-clock-outline",
        path: "/relatorio-geral",
        permissionRequired: FuncionalidadeEnum.ACESSAR_RELATORIO_GERAL_MENU,
      },
      {
        title: "Relatório de Auditoria",
        icon: "mdi-book-check-outline",
        path: "/relatorio-auditoria",
        permissionRequired: FuncionalidadeEnum.ACESSAR_LOG_MENU,
      },
      {
        title: "Painel",
        icon: "mdi-view-dashboard-outline",
        path: "/painel",
        permissionRequired: FuncionalidadeEnum.ACESSAR_PAINEL_MENU,
      },
      {
        title: "Templates",
        icon: "mdi-square-edit-outline",
        path: "/templates",
        permissionRequired: FuncionalidadeEnum.ACESSAR_TEMPLATE_MENU,
      },
      {
        title: "Gerenciamento de Perfis",
        icon: "mdi-account-cog",
        path: "/gerenciar-usuarios",
        permissionRequired: FuncionalidadeEnum.ACESSAR_PERFIL_MENU,
      },
    ];

    // this.items = menu.filter(link => {
    //   if(link.items){
    //     link.items = link.items.filter(sublink => !sublink.permissionRequired || this.$store.state.funcionalidades.includes(sublink.permissionRequired));
    //   }
    //   return !link.permissionRequired || this.$store.state.funcionalidades.includes(link.permissionRequired);
    // });

    this.items = menu

  },

  watch: {
    drawerClicked() {
      this.drawer = !this.drawer;
    },
  },

  data: () => ({
    drawer: false,
    items: [],
    right: null,
  }),
};
</script>
