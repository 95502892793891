import TextField from "../../../../components/fields/textField/TextField.vue";
import TextAreaField from "../../../../components/fields/textAreaField/TextAreaField.vue";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import FormDialog from "../../../../components/dialog/form/FormDialog.vue";
import PerfilService from "../../../../services/PerfilService";
import { SnackbarMixin, AssinaturaMixin } from "../../../../mixins";

export default {
  name: "FormPefis",
  components: {
    TextField,
    TextAreaField,
    ConfirmPasswordDialog,
    FormDialog,
  },
  mixins: [SnackbarMixin, AssinaturaMixin],
  data: () => ({
    perfilid: null,
    formTitle: "",
    formType: "New",
    showFormDialog: false,
    disabled: {
      nome: false,
      permissoes: false,
    },
    form: {
      nome: "",
      descricao: "",
    },
    formValid: true,
    showDialogConfirmPassword: false,
    formConfirmPassword: {},
    send: {
      function: () => {},
      params: null,
    },
    errors: [],
    permissions: [],
  }),

  created() {
    this.initializeFormConfirmPassword();
    this.initializeForm();
    if (this.$route.params.perfilid) {
      this.editMode = true;
      this.perfilid = this.$route.params.perfilid;
      this.getEditData();
    }
  },

  methods: {
    goTo(path) {
      this.$router.push(path);
    },

    initializeForm() {
      this.form = {
        nome: "",
        descricao: "",
      };
    },

    validaForm() {
      this.errors = [];
      if (this.$refs.form.validate()) {
        if (this.formType == "New") {
          this.validaAssinatura(this.save);
        }
        return;
      }
      this.errors.push("Preencha os campos obrigatórios");
    },

    async save() {
      this.errors = [];
      if (this.$refs.form.validate()) {
        const postData = {
          nome: this.form.nome,
        };

        let response = await PerfilService.insertPerfil(postData);

        if (!response.error) {
          const perfilid = await response.data;
          this.showSnackbar("O perfil foi criado com sucesso");
          this.showFormDialog = !this.showFormDialog;
          this.$emit("perfilCriado", perfilid);
        } else {
          const message = await response.response;
          this.showSnackbar(message, "error");
          this.errors.push(message);
        }
        return;
      }
    },

    activeFormDialog(title, type, perfilid = null) {
      this.perfilid = perfilid;
      this.errors = [];
      this.initializeForm();
      this.$refs.form.resetValidation();
      this.initializeForm();
      if (type === "Edit") {
        this.getDataForm(perfilid);
      }
      this.formTitle = title;
      this.formType = type;
      this.showFormDialog = !this.showFormDialog;
    },
  },
};
