import TextField from "../../../components/fields/textField/TextField.vue";
import SelectField from "../../../components/fields/selectField/SelectField.vue";
import FormDialog from "../../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import PermutaService from "../../../services/PermutaService";
import moment from "moment";
import Vue from "vue";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";

export default {
  name: "EPIDisponibilizadas",

  components: { TextField, SelectField, FormDialog, ConfirmDialogButton },

  data: () => ({
    headers: [
      { text: "Material", value: "material", sortable: false },
      { text: "Código", value: "codigo", sortable: false },
      { text: "Tamanho que possui", value: "tamanhoPossuido", sortable: false },
      { text: "Tamanho desejado", value: "tamanhoQuero", sortable: false },
      { text: "Data de solicitação", value: "created_at", sortable: false },
      // { text: "Material", value: "tpMaterial", sortable: false },
      // { text: "Tamanho", value: "titulo", sortable: false },
      // { text: "Tamanho solicitado", value: "titulo", sortable: false },
      { text: "Responsável", value: "usuario", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      // { text: "Contato", value: "tpMaterial", sortable: false },
      // { text: "Ações", value: "actions", align: "center", sortable: false },
    ],
    items: [],
    FuncionalidadeEnum: FuncionalidadeEnum,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    lastPage:1,
    form: {},
    filter: {},
    permissionsUser: [],
    options: {
      administrador: [],
    },
    loading: {
      tablePermutas: false,
    },
    formularioDialog: false,
    formTitle: "",
    formType: "",
  }),

  created() {
    this.permissionsUser = Vue.prototype.$permissionsUser
    this.initializeForm();
  },

  async mounted(){
    this.getPermutas();
  },

  watch: {
    page(){
      this.getPermutas();
    }
  },

  methods: {
    activeFormDialog(title, type) {
      this.formTitle = title;
      this.formType = type;
      this.formularioDialog = !this.formularioDialog;
    },

    initializeForm() {
      this.form = {
        titulo_categoria: "",
        subcategoria: "",
        administrador: "",
      };
    },

    initializeFilters() {
      this.filter = {
        material: "",
        codigo: "",
        quantidade: "",
        unidade: "",
        data: "",
        status: "",
      }
    },
    

    async getPermutas() {
      this.loading.tablePermutas = true;

      const params = {
        page: this.page
      };
      
      const response = await PermutaService.getPermuta(params);
    
      this.items = this.trataData(response.data.data);
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;
      this.loading.tablePermutas = false;
    },

    trataData(data){
      let dataTratada = [];

      data.forEach((permuta) => {
        dataTratada.push(
        {
          // id: permuta.id,
          material: permuta.material_patrimonio,
          codigo: permuta.codigo_catmas,
          tamanhoPossuido: permuta.tam_tenho,
          unidade: permuta.unidade_codigo,
          tamanhoQuero: permuta.tam_quero,
          created_at: moment(permuta.created_at).format("DD/MM/YYYY HH:mm"),
          usuario: permuta.nome
          }
        );
      });
      return dataTratada;
    },

    
    async realizarPermuta(permutaID) {
      this.loading.tablePermutas = true;
      try{
        await PermutaService.excluirPermuta(permutaID);
        this.loading.tablePermutas = false;
      } finally {
        this.getPermutas();
      }
    },
  },
};
