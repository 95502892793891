import FuncionalidadesService from "../../src/services/FuncionalidadesService";
// import { mapActions } from "vuex";
import opcoesMixins from "./opcoesMixins";


export default {
  data() {
    return {
     funcionalidadesOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getFuncionalidades()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getFuncionalidades() {
      const response = await FuncionalidadesService.getFuncionalidade();
      if (!response.error) {
        this.funcionalidadesOpcoes = this.trataOptions(response.data.data);

        // this.ActionSetTipoAdmin(this.categoriaOpcoes);
      }
    },


    verificarSeExisteCategoria() {
      if (!this.$store.getters["opcoes/existe_funcionalidades"]) {
        return this.getFuncionalidades();
      }

      this.funcionalidadesOpcoes = this.$store.state.opcoes.funcionalidades;
    },
  },
};
