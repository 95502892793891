import TextField from "../../../components/fields/textField/TextField.vue";
import ConfirmPasswordDialog from "../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import { AssinaturaMixin } from "../../../mixins";
import PerfilService from "../../../services/PerfilService";
import { SnackbarMixin } from "../../../mixins";

export default {
  name: "GerenciarPefis",
  components: {
    TextField,
    ConfirmPasswordDialog,
  },
  mixins: [SnackbarMixin, AssinaturaMixin],
  data: () => ({
    perfilid: null,
    headers: [
      { text: "Funcionalidade", value: "nome", sortable: false },
      { text: "", value: "atribuir", sortable: false, width: "100" },
    ],
    data: [],
    disabled: {
      nome: false,
      permissoes: false,
    },
    form: {},
    formValid: true,
    loading: {
      table: false,
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",

    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: "",
    },
    send: {
      function: () => {},
      params: null,
    },

    permissions: [],
  }),

  created() {
    if (this.$route.params.perfilid) {
      this.perfilid = this.$route.params.perfilid;
      // this.verificaPermissaoRoute(["I_NOVO_PERFIL"]);
    } else {
      // this.verificaPermissaoRoute(["A_EDITAR_PERFIL"]);
    }
    this.initializeFormConfirmPassword();

    this.initializeForm();
    this.getPermissions();
    if (this.$route.params.perfilid) {
      this.editMode = true;
      this.perfilid = this.$route.params.perfilid;
      this.getEditData();
    }
  },

  methods: {
    goTo(path) {
      this.$router.push(path);
    },

    async getEditData() {
      this.disabled.permissoes = true;
      // const response = await PerfilService.getPerfilById(this.perfilid);
      const response = {};
      this.form.nome = response.nome;
      this.form.permicoes = response.permicoes;
      this.disabled.permissoes = false;
    },

    async getPermissions() {
      this.loading.table = true;
      const response = await PerfilService.getFuncionalidades();

      this.permissions = await response;
      this.loading.table = false;
    },

    initializeForm() {
      this.form = {
        nome: "",
        descricao: "",
        permicoes: [],
      };
    },

    validaForm() {
      if (this.$refs.form.validate()) {
        this.validaFormAssinatura(this.save);
      }
      window.scrollTo(0, 0);
    },

    async save() {
      if (this.$refs.form.validate()) {
        let response = null;
        if (this.editMode) {
          // response = await PerfilService.editarPerfil(this.perfilid, this.form);
        } else {
          // response = await PerfilService.cadastrarPerfil(this.form);
        }

        if (response) {
          this.goTo("/gerenciar-usuarios");
        }
        return;
      }
      window.scrollTo(0, 0);
    },
  },
};
