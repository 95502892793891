import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ModeloDialog from "../../components/dialog/modelo/ModeloDialog.vue";
import MarcaDialog from "../../components/dialog/marca/MarcaDialog.vue";
import TextField from "../../components/fields/textField/TextField.vue";
import TextCheckboxField from "../../components/fields/text-checkbox-field/TextCheckboxField.vue";
import SelectCheckboxField from "../../components/fields/select-checkbox-field/SelectCheckboxField.vue";
import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../components/fields/file-input-field/FileInputField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import ConfirmDialogButton from "../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import categoriaMixins from "../../mixins/categoriaMixins";
import subcategoriaMixins from "../../mixins/subcategoriaMixins";
import tagsMixins from "../../mixins/tagsMixins";
import templatesMixins from "../../mixins/templatesMixins";
import tipoAdminMixins from "../../mixins/tipoAdminMixins";
import statusMaterialMixins from "../../mixins/statusMaterialMixins";
import estadosMaterialMixins from "../../mixins/estadosMaterialMixins";
import itemTipoMixins from "../../mixins/itemTipoMixins";
import unidadesMixins from "../../mixins/unidadesMixins";
import emitirPDFMixins from "../../mixins/emitirPDFMixins";
import geraCSVMixins from "../../mixins/geraCSVMixins";
// import ItensService from "../../services/ItensService";
// import { ITENS } from "../../constants";
import { FiltroMixin, SnackbarMixin } from "../../mixins";
// import TagsService from "../../services/TagsService";
// import TemplatesService from "../../services/TemplatesService";
// import CategoriasService from "../../services/CategoriasService";
// import SubCategoriasService from "../../services/SubCategoriasService";
import MateriaisService from "../../services/MateriaisService";
// import moment from "moment";
// import GrupoService from "../../services/GrupoService";
// import html2pdf from 'html2pdf.js'
// import Vue from "vue";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "RelatorioGeral",
  components: {
    FormDialog,
    TextField,
    AutoCompleteField,
    FileInputField,
    SelectField,
    TextCheckboxField,
    SelectCheckboxField,
    ModeloDialog,
    MarcaDialog,
    ConfirmDialogButton,
  },

  mixins: [FiltroMixin, SnackbarMixin, categoriaMixins,subcategoriaMixins,tagsMixins, templatesMixins,tipoAdminMixins, 
          statusMaterialMixins, estadosMaterialMixins,itemTipoMixins, unidadesMixins, emitirPDFMixins, geraCSVMixins],

  data: () => ({
    formularioDialog: false,
    formTitle: "",
    formType: "",
    FuncionalidadeEnum: FuncionalidadeEnum,
    loading: {
      table: false,
      marcas: false,
      modelos: false,
    },

    filter: {},
    camposTemplate: [],
    filterCamposTemplate: [], 
    filterCampoTempleteSelected:[],

    form: {},

    itemsClear: [],
    options: {
      filterTipo: [],
      categoriaMaterial: [],
      subcategoriaMaterial: [],
      templates: [],
      tags: [
        "Atendimento pré-hospitalar",
        "Operações aéreas",
        "Salvamento em altura",
      ],
    },

    headers: [],
    items: [],
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
  }),

  created() {
    this.initializeFilters();
    this.initializeHeaders();
    this.initializeForm();
  },

  mounted() {
    this.getMateriais();
  },

  watch:{
    page() {
      this.getMateriais();
    },
  },

  methods: {
    async getMateriais() {
      this.loading.table = true;
      const params = {
        categoria_id: this.filter.categoria.value,
        subcategoria_id: this.filter.subcategoria.value,
        codigo_catmas: this.filter.codigo_catmas.value,
        descricao_catmas: this.filter.descricao_catmas.value,
        tag_id: this.filter.tags.value,
        tipo_administrador_id: this.filter.tipo_administrador.value,
        template_id: this.filter.template.value,
        patrimonio: this.filter.patrimonio.value,
        unidade: this.filter.unidade.value,
        data_entrada: this.filter.data_entrada.value,
        data_validade: this.filter.data_validade.value,
        estado_id: this.filter.estado_id.value,
        page: this.page
      }

      const data = await MateriaisService.getMateriais(params);
      this.items = this.trataItems(data.data.data);
      this.itemsClear = [...this.items];
      this.pageCount = data.data.meta.total;
      this.lastPage = data.data.meta.last_page;
      this.loading.table = false;
    },

    initializeForm() {
      this.form = {
        administrador: "",

        tipo: "",
        idMarca: "",
        idModelo: "",
        idCategoria: "",
        idTemplate: "",
        idSubcategoria: "",
        codigo_catmas: "",
        desc_catmas: "",
        descricao: "",
        tags: [],
      };
    },


    getHeaders() {
      this.initializeHeaders();
      Object.keys(this.filter).forEach((key) => {
        const indexAux = this.headers.length - 1;
        let value
        if(this.filter[key].name){
          value = `${key}[nome]`;
        } else {
          value = `${key}`;
        }
        console.log(value)
        if (this.filter[key]?.check) {
            this.headers.splice(indexAux, 0, {
              text: this.filter[key].headerName,
              value: value,
              sortable: false,
              width: 120,
          });
        }
      });
    },

    trataItems(data) {
      let dataTratada = data.map(item => {
      const arrayTags = [];
      item.tags.forEach(tagItem => {
        arrayTags.push(tagItem.nome);
      });
      item.tagsString = { nome: arrayTags.join(", ") };
      item.template = { nome: item.templates?.map(template => template.nome).join(", ")};
      item.descricao_catmas = { nome: item.codigo_catmas.codigo_catmas + ' - ' + item.codigo_catmas.descricao_catmas };
      item.unidade_numero = item.unidade.codigo;
      item.data_entrada = item.created_at;
      return item;
    });
    console.log(dataTratada)
    return dataTratada;
  },

  async gerarPDF() {
    try{
      this.loading.recibo = true;
      const data = await MateriaisService.getExportMaterialPDF(this.filter);

      this.exportBlob(data.data); 
    } finally {
      
      this.loading.recibo = false;
    }
  },

  async downloadCSV() {
    //const headers = { atividade: 'Atividade', detalhes: "Detalhes",  dataCriacao: "Data", unidade: "Unidade", usuario: "Usuário"};
    try {
      // Chame sua API que gera o arquivo CSV
      const response = await MateriaisService.getExportMaterialCSV(this.filter);
  
      this.geraBlobCSV(response.data)
    } catch (error) {
      console.error('Erro ao chamar a API', error);
    }
  },
      
    clear() {
      this.initializeFilters();
      this.initializeHeaders();
    },

    activeFormDialog(title, type, itemid = null) {
      this.formTitle = title;
      this.formType = type;
      if (type == "Edit") {
        this.itemid = itemid;
        this.getEditData();
      }
      this.formularioDialog = !this.formularioDialog;
    },

    initializeHeaders() {
      this.headers = [
        {
          text: "Código",
          value: "codigo_catmas.codigo_catmas",
          sortable: false,
          width: 120,
        },
        {
          text: "Descrição",
          value: "descricao",
          sortable: false,
          width: 120,
        },
      ];
    },

    initializeFilters() {
      this.filter = {
        codigo_catmas: {
          value: null,
        },
        descricao: {
          value: "",
          },
          item_tipo: {
          headerName: "Tipo",
          check: false,
          value: null,
          name: true
          },
        id: {
          headerName: "Item",
          check: false,
          value: null,
          name: false
        },
        categoria: {
          headerName: "Categoria",
          check: false,
          value: null,
          name: true
          },
        tags: {
          headerName: "Tags",
          check: false,
          value: null,
        },
        template: {
          headerName: "Template",
          check: false,
          value: null,
          name: true
        },
        unidade: {
          value: null,
        },
        estado_id: {
          headerName: "Estado Conservação",
          check: false,
          value: null,
          name: true
        },
        patrimonio: {
          headerName: "Patrimônio/Série",
          value: null,
          check: false,
          name: false
        },
        data_entrada:{
          headerName: "Data de Entrada",
          value: null,
          check: false,
          name: false
        },
        data_validade:{
          headerName: "Data de Validade",
          value: null,
          check: false,
          name: false
        },
        tipo_administrador: {
          filterName: "tipo_administrador",
          headerName: "Administrador",
          check: false,
          value: null,
          name: true
        },   
        descricao_catmas: {
          headerName: "Descrição Catmas",
          value: null,
          check: false,
          name: true
        },
        subcategoria: {
          filterName: "idSubcategoria",
          headerName: "Subcategoria",
          check: false,
          value:null,
          name: true
        },
        tagsString: {
          headerName: "Tags",
          check: false,
          name: true
        },
        unidade_numero: {
          headerName: "Codigo",
          check: false,
          name: false
        },
      };
    },


    async search() {
          this.getHeaders();
    
        },    
  },
};
