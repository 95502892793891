import CategoriaService from "../../src/services/CategoriasService";
// import { mapActions } from "vuex";
import opcoesMixins from "./opcoesMixins";


export default {
  data() {
    return {
      categoriaOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getCategorias()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getCategorias() {
      const response = await CategoriaService.getCategoriasSemFiltro();
      if (!response.error) {
        this.categoriaOpcoes = this.trataOptionsParaCategoria(response.data);
          console.log( this.categoriaOpcoes)
        // this.ActionSetTipoAdmin(this.categoriaOpcoes);
      }
    },


    verificarSeExisteCategoria() {
      if (!this.$store.getters["opcoes/existe_tipo_tempo"]) {
        return this.getCategorias();
      }

      this.categoriaOpcoes = this.$store.state.opcoes.categoria;
    },
  },
};
