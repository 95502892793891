import TextField from "../../../../components/fields/textField/TextField.vue";
import FileField from "../../../../components/fields/file-input-field/FileInputField.vue";
import AutoCompleteField from "../../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
import ItensService from "../../../../services/ItensService";
import MateriaisService from "../../../../services/MateriaisService";
import { SnackbarMixin, FiltroMixin, AssinaturaMixin } from "../../../../mixins";
import FormDialog from "../../../../components/dialog/form/FormDialog.vue";
import DialogMovimentacao from "../dialogMovimentacao/DialogMovimentacao.vue";
import DialogVincular from "../dialogVincular/DialogVincular.vue";
import DialogDesvincular from "../dialogDesvincular/DialogDesvincular.vue";
import DialogEmitirRecibo from "../dialogEmitirRecibo/DialogEmitirRecibo.vue";
import DialogDisponibilizar from "../dialogDisponibilizar/DialogDisponibilizar.vue";
import DialogDescarga from "../dialogDescarga/DialogDescarga.vue";
import DialogDescarte from "../dialogDescarte/DialogDescarte.vue";
import DialogAutosoma from "../dialogAutosoma/DialogAutosoma.vue";
import TextCheckboxField from "../../../../components/fields/text-checkbox-field/TextCheckboxField.vue";
import SelectCheckboxField from "../../../../components/fields/select-checkbox-field/SelectCheckboxField.vue";
import CheckboxAutoCompleteField from "../../../../components/fields/checkboxAutoComplete/CheckboxAutoCompleteField.vue";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import CampoTemplateEnum from "../../../../enums/CampoTemplateEnum";
import itensMixins from "../../../../mixins/itensMixins";
import statusMaterialMixins from "../../../../mixins/statusMaterialMixins";
import estadosMaterialMixins from "../../../../mixins/estadosMaterialMixins";
import ReciboService from "../../../../services/ReciboService";

export default {
  name: "DialogItensSelecionados",
  components: {
    TextCheckboxField,
    CheckboxAutoCompleteField,
    SelectCheckboxField,
    FormDialog,
    FileField,
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    DialogMovimentacao,
    DialogVincular,
    DialogDesvincular,
    DialogDisponibilizar,
    DialogDescarga,
    DialogDescarte,
    DialogEmitirRecibo,
    ConfirmPasswordDialog,
    DialogAutosoma
  },

  mixins: [SnackbarMixin, FiltroMixin, AssinaturaMixin, itensMixins, statusMaterialMixins, estadosMaterialMixins],

  props: {
    itensSelecteds: {
      default: () => [],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
    headers: [
      { text: "Código", value: "codigo_catmas", sortable: false },
      { text: "Descrição", value: "descricao", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    form: {},
    options: {},
    formValid: true,
    loading: {
      itens: false,
      fieldsItens: false,
      table: false,
    },
    infoItem: {},
    formularioDialog: false,
    showFilters: true,
    materialid: null,
    formTitle: "",
    formType: "",
    filter: {},
    itemsClear: [],
    selected: [],
    activeDialogVincular: false,
    items: [],
    materiaisSemVinculo: [],
    materiaisComVinculo: [],
    materiaisDisponibilizar: [],
    materiaisDescarga: [],
    materiaisDescarte: [],
    materiaisMovimentacao: [],
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    materialEmitirRecibo: [{}],
    emitirReciboModal:false,
    btnTextFormDialog: "Voltar",
    CampoTemplateEnum: CampoTemplateEnum,
  }),

  created() {
    this.initializeInfoItem();
    this.initializeForm();
  },

  watch: {
    selected(value){
      console.log(value)
      if(value.length > 0){
        this.verificaVinculados();
        this.verificaDisponibilizados();
        this.verificaDescarteDescarga();
        this.verificarMovimentacao();
      }
    }
  },

  methods: {
    async verificarMovimentacao(){
      let materiaisConsumo = [];
      let materiaisPermanente = [];

      this.materiaisMovimentacao = [];

      await this.selected.forEach(material => {
        if(material.item.tipo == 1 && (material.quantidade > 0 || material.quantidade == '')){
          materiaisPermanente.push(material);
        } else if(material.quantidade > 0) {
          materiaisConsumo.push(material);
        }
      });

      if(materiaisConsumo.length === this.selected.length) {
        this.materiaisMovimentacao = materiaisConsumo;
      } else if (materiaisPermanente.length === this.selected.length) {
        this.materiaisMovimentacao = materiaisPermanente;
      }
    },
    
    async verificaDisponibilizados(){
      let materiaisConsumo = [];
      let materiaisPermanente = [];

      this.materiaisDisponibilizar = [];

      await this.selected.forEach(material => {
        if(material.item.tipo == 1){
          materiaisPermanente.push(material);
        } else {
          materiaisConsumo.push(material);
        }
      });

      if(materiaisConsumo.length === this.selected.length) {
        this.materiaisDisponibilizar = materiaisConsumo;
      } else if (materiaisPermanente.length === this.selected.length && materiaisPermanente.length == 1) {
        this.materiaisDisponibilizar = materiaisPermanente;
      }
    },

    verificaDescarteDescarga() {
      this.materiaisDescarga = [];
      this.materiaisDescarte = [];

      let materiaisDescarte = [];
      let materiaisDescarga = [];

      this.materiaisDisponibilizar = [];

      this.selected.forEach(material => {
        if(material.item_tipo_id == 1){
          materiaisDescarga.push(material);
        } else {
          materiaisDescarte.push(material);
        }
      });

      if(materiaisDescarte.length === this.selected.length) {
        this.materiaisDescarte = materiaisDescarte;
      } else if (materiaisDescarga.length === this.selected.length) {
        this.materiaisDescarga = materiaisDescarga;
      }
    },

    verificaVinculados() {
      this.materiaisSemVinculo = [];
      this.materiaisComVinculo = [];

      this.selected.forEach(material => {
        if(material.vinculado != 1){
          this.materiaisSemVinculo.push(material);
        } else {
          this.materiaisComVinculo.push(material);
        }
      });
    },


    activeFormDialog(title, type, itemid = null, material = null) {
      this.initializeForm();
      this.initializeInfoItem();
      this.$refs.form.resetValidation()
      this.formTitle = title;
      this.formType = type;
      this.btnTextFormDialog = "Salvar"
  
      if (type == "Edit") {
        this.getInfoItem(itemid, material, type);
      }

      if (type == "Detalhe") {
        this.btnTextFormDialog = "Voltar"
        this.getInfoItem(itemid, material, type);
      }
      
      this.formularioDialog = !this.formularioDialog;
    },

    async getInfoItem(itemid, material, type) {
      if (!itemid) return;
      this.initializeInfoItem();
      const response = await ItensService.getItemByID(itemid);
      const data = response.data.data[0];
      this.populateBasicInfo(data);
      if (type == 'Edit' || type == "Detalhe") {
        this.getEditData(material)
        this.form.administrador_id = material.administrador_id;
        this.form.codigo = itemid;
        this.materialId = data.id;
      }
      this.infoItem.campos = this.extractCamposTemplate(data, material?.campo_template_respostas, type);
      

      this.form.item_id = itemid;
      // this.form.codigo = itemid;
      this.loading.fieldsItens = false;
     
    },

    populateBasicInfo(data) {
      this.infoItem.administrador_id = data.tipo_administrador.id;
      this.infoItem.administrador_nome = data.tipo_administrador.nome;
      this.infoItem.tipo_id = data.item_tipo.id;
      this.infoItem.tipo_nome = data.item_tipo.nome;
      this.infoItem.marcas = this.extractIds(data.marcas);
      this.infoItem.modelos = this.extractIds(data.modelos);
      this.infoItem.tags_id = this.extractIds(data.tags);
      this.infoItem.categoria_id = data.categoria?.nome;
      this.infoItem.subcategoria_id = data.subcategoria?.nome;
      this.infoItem.catmas_id = data.codigo_catmas.codigo_catmas;
      this.infoItem.descricao_catmas = data.codigo_catmas.descricao_catmas;
      this.infoItem.descricao = data.descricao;
    },

    extractIds(array) {
      return (array || []).map(item => item.nome).join(', ');
    },

    extractCamposTemplate(data, campo_template_respostas, type) {
      return data.templates.flatMap(template =>
        template.campo_template.map(campo => this.transformCampoTemplate(campo, campo_template_respostas, type))
      );
    },

    transformCampoTemplate(campo, campo_template_respostas, type) {
      const transformedCampo = {
        id: campo.id,
        template_id: campo.template_id,
        tipo_campo_template_id: campo.tipo_campo_template_id,
        nome: campo.nome,
        texto_ajuda: campo.texto_ajuda,
        tamanho_maximo: campo.tamanho_maximo,
        mascara_id: campo.mascara_id,
        obrigatorio: campo.obrigatorio,
      };

      if ([CampoTemplateEnum.TIPO_CAMPO_TEMPLATE_SELECAO_UNICA,
        CampoTemplateEnum.TIPO_CAMPO_TEMPLATE_SELECAO_MULTIPLA].includes(campo.tipo_campo_template_id)) {
            transformedCampo.options = campo.opcoes.map(opcao => ({ value: opcao.id, text: opcao.nome }));
      }

      if (campo.tipo_campo_template_id === CampoTemplateEnum.TIPO_CAMPO_TEMPLATE_ARQUIVO) {
        transformedCampo.formatosString = this.formatosToString(campo.formatos);
      }

      if (type === "Edit"|| type == "Detalhe") {
        this.montaCampoResapostaMaterial(transformedCampo, campo_template_respostas)

      }

      return transformedCampo;
    },

    montaCampoResapostaMaterial(transformedCampo,campo_template_respostas) {
      // Encontra o campo correspondente pelo ID no array item.campo_template_respostas
    const campoCorrespondente = campo_template_respostas.find(campo_template_resposta => campo_template_resposta.campo_template_id === transformedCampo.id);
      // Se encontrou o campo correspondente, adiciona a resposta
      if (campoCorrespondente) {
        transformedCampo.resposta = campoCorrespondente.resposta;
      }
  },

  formatosToString(formatos) {
    return formatos.map(formato => this.getFormatoName(formato.tipo_template_formato_id)).join(', ');
  },

  getFormatoName(tipo_template_formato_id) {
    const formatoNames = {
      1: 'JPG', 2: 'PNG', 3: 'BMP', 4: 'DOC', 5: 'PDF', 6: 'MP3', 7: 'MP4',
      8: 'DOCX', 9: 'XLS', 10: 'XLSX', 11: 'PPT', 12: 'PPS', 13: 'SVG',
      14: 'CSV', 15: 'TXT',
    };
    return formatoNames[tipo_template_formato_id] || '';
  },

  async getEditData(material) {
    this.loading.fieldsItens = true;
    this.form.patrimonio = material.patrimonio;
    this.form.administrador_id = material.administrador_id;
    this.form.data_fabricacao = material.data_fabricacao;
    this.form.data_validade = material.data_validade;
    this.form.estado_id = material.estado_id;
    this.form.quantidade = material.quantidade
    this.form.demanda_ideal = material.demanda_ideal
    this.form.status_id = material.status_id;
    this.form.isBloco = false,

    this.loading.fieldsItens = false;
  },

  validaRules(resposta, campo) {
    if (campo.obrigatorio == 1 && !resposta && resposta != 0 && resposta == "") {
      return `Preencha o campo ${campo.nome.toLowerCase()}`;
    }

    if(campo?.op_aux?.mascara == 'email'){
      const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
      if(!pattern.test(resposta)){
        return "Campo é um email";
      }
    }

    return true;
  },

  initializeInfoItem() {
    this.infoItem = {
      administrador_id: "",
      images: "",
      marcas: "",
      modelos: "",
      templates: [],
      tags_id: "",
      tipo_id: "",
      categoria_id: "",
      subcategoria_id: "",
      catmas_id: "",
      descricao: "",
      descricao_catmas: "",
      item_id: "",
    };
  },
      
    
    clear() {
      this.selected = []
    },

    async prepareForm(){
      let campos = [];
      for (const campo of this.infoItem.campos) {
             campos.push({
            campo_template_id: campo.id,
            resposta: campo.resposta,
          })
      }
      this.form.campo_template_respostas = campos; 
      return this.form;
    },

    validaForm(){
      if(!this.$refs.form.validate()){
        return;
      }

      this.validaAssinatura(this.saveNew)
    },
    
    async saveNew(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();
      
      const response = await MateriaisService.cadastrarMaterial(postData);
      if (response.error) {
        const errorMessage = response.message?.status?.response || "Não foi possível completar a ação";
        this.showSnackbar(errorMessage, "error");
        return;
      }

      this.showSnackbar("O material foi cadastrado com sucesso");
      this.formularioDialog = !this.formularioDialog;
      this.clear();
    },

    async deletaMaterial(itemid) {
      this.loading.table = true;
      const response = await MateriaisService.deletaMaterial(itemid);
      
      if (response.error) {
        this.showSnackbar(response.message, "error");
        this.loading.table = false;
        return;
      }

      this.showSnackbar("O material foi deletado com sucesso!");
      this.clear();
    },

    async saveEdit(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await MateriaisService.editarMaterial(postData, this.materialId);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O material foi editado com sucesso");
      this.formularioDialog = !this.formularioDialog;
      this.clear();
    },

    goTo(path) {
      this.$router.push(path);
    },

    validaTamanhoArquivo(value, maxLength) {
      if (value) {
        let totalSize = 0;

        totalSize += value.size;

        return totalSize/1024/1000 <= maxLength;
      }
      return true;

    },

    validaObrigatoriedadeArquivo(resposta, obrigatoriedade) {
      if (obrigatoriedade != "Sim") {
        return false;
      }

      return resposta.length != 0
        ? false
        : "É necessário selecionar um arquivo";
    },

    emitirReciboOpen(item){
      this.materialEmitirRecibo = item
      this.emitirReciboModal = !this.emitirReciboModal;
    },

    async emitirRecibo(reciboid) {
      this.loading.recibo = true;
      const data = await ReciboService.emitirRecibo(reciboid);
      
      this.exportBlob(data.result);

      this.initializeForm();
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.loading.recibo = false;
    },

    exportBlob(base64str){
      const url = this.geraBlob(base64str);
      window.open(url);
    },

    geraBlob(base64str){
      var binary = atob(base64str.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
      }
         
      var blob = new Blob( [view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);

      return url;
    },

    initializeForm() {
      this.form = {
        item_id: "",
        campo_template_respostas: [],
        isBloco: false,
        patrimonio: "",
        administrador_id: "",
        data_fabricacao: "",
        data_validade: "",
        estado_id: "",
        quantidade: "",
        demanda_ideal: "",
        status_id: "",
      };
    },
    openDialog() {
      // Verificar se há itens selecionados
 
        this.emitirReciboModal = true; // Abrir o diálogo se houver itens selecionados
    

  }

  },
};
