import SecureLS from "secure-ls";

const ls = new SecureLS({
  isCompression: false,
  encodingType: "aes",
  encryptionSecret: "m0u*@@#KLKJALALK><MKJLKJH###",
});

export default {
  get(key) {
    return ls.get(key);
  },
  remove(key) {
    return ls.remove(key);
  },
  set(key, value) {
    return ls.set(key, value);
  },
  removeAll() {
    return ls.removeAll();
  },

  setTemporaryCache(key, value, expirationTimeInSeconds) {
    const currentTime = new Date().getTime();
    const expirationTime = currentTime + expirationTimeInSeconds * 1000;
    const data = {
      value: value,
      expirationTime: expirationTime,
    };
    ls.set(key, data);
  },

   getTemporaryCache(key) {
    const data = ls.get(key);
    if (data && data.expirationTime > new Date().getTime()) {
      return data.value;
    } 

    this.removeTemporaryCache(key);
    return null;
  },

  removeTemporaryCache(key) {
    ls.remove(key);
  },
};
