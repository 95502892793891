import AlertaService from "../services/AlertaService";
// import { mapActions } from "vuex";
import opcoesMixins from "../mixins/opcoesMixins";


export default {
  data() {
    return {
      tipoAlertasOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getTipoAlertas()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getTipoAlertas() {
      const response = await AlertaService.getTipoAlertas();
      if (!response.error) {
        this.tipoAlertasOpcoes = this.trataOptions(response.data.data);

        // this.ActionSetTipoAdmin(this.tipoAdminOpcoes);
      }
    },


    verificarSeExisteTipoAdmin() {
      if (!this.$store.getters["opcoes/existe_tipo_alerta"]) {
        return this.getTipoAlertas();
      }

      this.tipoAlertasOpcoes = this.$store.state.opcoes.tipoAlertas;
    },
  },
};
