import ItensService from "../services/ItensService";
// import { mapActions } from "vuex";
import opcoesMixins from "../mixins/opcoesMixins";


export default {
  data() {
    return {
      itensOpcoes: [],
    };
  },

  beforeMount() {
    this.getItensOptions()
  },

  mixins:[opcoesMixins],

  methods: {
    async getItensOptions() {
      const response = await ItensService.getItensSemPaginacao();
      if (!response.error) {
        this.itensOpcoes = this.trataOptionsParaItens(response.data);
      }
    },


    verificarSeExisteItemTipo() {
      if (!this.$store.getters["opcoes/existe_itens"]) {
        return this.getItensOptions();
      }
      this.itensOpcoes = this.$store.state.opcoes.itens;
    },
  },
};
