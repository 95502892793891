import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ModeloDialog from "../../components/dialog/modelo/ModeloDialog.vue";
import uploadCsvModeloMarca from "../../components/dialog/uploadCsvModeloMarca/uploadCsvModeloMarca.vue";
import MarcaDialog from "../../components/dialog/marca/MarcaDialog.vue";
import TextField from "../../components/fields/textField/TextField.vue";
import FileField from "../../components/fields/fileField/FileField.vue";
import TextCheckboxField from "../../components/fields/text-checkbox-field/TextCheckboxField.vue";
import SelectCheckboxField from "../../components/fields/select-checkbox-field/SelectCheckboxField.vue";
import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../components/fields/file-input-field/FileInputField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import ConfirmDialogIconButton from "../../components/dialog/confirmIconButton/ConfirmDialogIconButton.vue";
import ItensService from "../../services/ItensService";
import { AssinaturaMixin, FiltroMixin, SnackbarMixin } from "../../mixins";
import ConfirmPasswordDialog from "../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import Vue from "vue";
import categoriaMixins from "../../mixins/categoriaMixins";
import subcategoriaMixins from "../../mixins/subcategoriaMixins";
import tagsMixins from "../../mixins/tagsMixins";
import templatesMixins from "../../mixins/templatesMixins";
import tipoAdminMixins from "../../mixins/tipoAdminMixins";
import modelosMixins from "../../mixins/modelosMixins";
import marcasMixins from "../../mixins/marcasMixins";
import codigoCatmasMixins from "../../mixins/codigoCatmasMixins";
import itemTipoMixins from "../../mixins/itemTipoMixins";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";


export default {
  name: "RelacaoItens",
  components: {
    uploadCsvModeloMarca,
    ConfirmPasswordDialog,
    FormDialog,
    TextField,
    AutoCompleteField,
    FileInputField,
    SelectField,
    TextCheckboxField,
    SelectCheckboxField,
    ModeloDialog,
    MarcaDialog,
    ConfirmDialogIconButton,
    FileField
  },

  mixins: [FiltroMixin, SnackbarMixin, AssinaturaMixin, categoriaMixins, subcategoriaMixins, tagsMixins, templatesMixins,
           tipoAdminMixins, modelosMixins, marcasMixins, codigoCatmasMixins, itemTipoMixins],

  data: () => ({
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    FuncionalidadeEnum: FuncionalidadeEnum,
    send: {
      function: () => { },
      params: null,
    },
    
    formularioDialog: false,
    formTitle: "",
    formType: "",

    loading: {
      table: false,
      marcas: false,
      modelos: false,
    },

    selected:[],
    categoria:[],
    filter: {},
    form: {},
    imagesEdit: [],
    initialEditBase64: "",
    catmasSelected: [],
    options: {
      filterTipo: [],
      categoriaMaterial: [],
      subcategoriaMaterial: [],
      templates: [],
      tags: [],
      catmas: [],
    },

    permissionsUser: [],
    inputImagens: [],
    headers: [],
    images: [],
    items: [],
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    formValid: true,
  }),

  beforeMount(){
    this.permissionsUser = Vue.prototype.$permissionsUser
  },

  created() {
    this.initializeFilters();
    this.initializeHeaders();
    this.initializeForm();
  },

  mounted() {
    this.getItens();
  },

  watch:{
    "form.categoria_id"() {
      if (this.categoriaOpcoes !== undefined) {     
        this.categoriaOpcoes.map((categoria) => {
          if(this.form.categoria_id == categoria.value) {
            this.form.subcategoria_id = categoria.subcategoria_id;
          }
        })
      }
    },

    page() {
      this.getItens();
    },
  },

  methods: {
    async getImg() {
      this.images = [];
      this.form.imagem = [];
      if (this.inputImagens.length > 0) {
        await this.inputImagens.forEach((file, indexFile) => {
          let reader = new FileReader();

          reader.onload = (e) => {
            this.images.push({
              src: e.target.result,
              name: this.inputImagens[indexFile].name,
            });
            
            this.form.imagem.push({
              src: e.target.result,
              name: this.inputImagens[indexFile].name,
            });
          };

          reader.readAsDataURL(file);
        });

      }
    },

    search() {
      this.getHeaders();
      this.getItens();
    },

    teste(e) {
      console.log(e);
    },

    initializeForm() {
      this.inputImagens = [];
      this.imagesEdit = "";
      this.catmasSelected = "";
      this.form = {
        administrador_id: "",
        images: "",
        marcas: [],
        modelos: [],
        templates: [],
        tags: "",
        tipo_id: "",
        categoria_id: "",
        subcategoria_id: "",
        catmas_id: "",
        descricao: "",
      };
    },  

    getHeaders() {
      this.initializeHeaders();
      Object.keys(this.filter).forEach((key) => {
        const indexAux = this.headers.length - 1;
        if (this.filter[key]?.check) {
            this.headers.splice(indexAux, 0, {
              text: this.filter[key].headerName,
              value: `${key}[nome]`,
              sortable: false,
              width: 120,
          });
        }
      });
    },

    async getItens() {
      this.loading.table = true;
      const params = {
        tipo_id: this.filter.item_tipo.value,
        categoria_id: this.filter.categoria.value,
        subcategoria_id: this.filter.subcategoria.value,
        codigo_catmas: this.filter.codigo_catmas.value,
        descricao_catmas: this.filter.descricao_catmas.value,
        descricao: this.filter.descricao.value,
        tag_id: this.filter.tags.value,
        tipo_administrador_id: this.filter.tipo_administrador.value,
        page: this.page
      }
      const response = await ItensService.getItens(params);
      this.items = this.trataItems(response.data.data);
      this.pageCount = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;
      this.loading.table = false;
    },

      trataItems(data) {
        let dataTratada = data.map(item => {
        const arrayTags = [];
        item.tags.forEach(tagItem => {
          arrayTags.push(tagItem.nome);
        });
        item.tagsString = { nome: arrayTags.join(", ") };
        item.template = item.templates?.map(template => template.nome).join(", ");
        item.descricao_catmas = { nome: item.codigo_catmas.descricao_catmas };
        return item;
      });

      return dataTratada;
    },

    clear() {
      this.initializeFilters();
      this.initializeHeaders();
      this.getItens();
    },

    activeFormDialog(title, type, item = null) {
      this.formTitle = title;
      this.formType = type;
      this.initializeForm();

      if (type == "Edit") {
          this.itemid = item.id;
          this.getEditData(item);
      } else {
        this.$refs.form.reset();
      }

      if (type == "EditMultiple") {
        this.itemid = this.selected.map(value => value.id );
      }
      this.formularioDialog = !this.formularioDialog;
    },

    initializeHeaders() {
      this.headers = [
        {
          text: "Código",
          value: "codigo_catmas.codigo_catmas",
          sortable: false,
          width: 120,
        },
        {
          text: "Descrição",
          value: "descricao",
          sortable: false,
          width: 120,
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "center",
          width: 300,
        },
      ];
    },

    initializeFilters() {
      this.filter = {
        item_tipo: {
          filterName: "nome",
          headerName: "Tipo",
          check: false,
          value:null,
        },
        tipo_administrador: {
          filterName: "admType",
          headerName: "Administrador",
          check: false,
          value:null,
        },
        categoria: {
          filterName: "idCategoria",
          headerName: "Categoria",
          check: false,
          value:null,
        },
        codigo_catmas: {
          value:null,
          filterName: "codigo_catmas"
        },
        subcategoria: {
          filterName: "idSubcategoria",
          headerName: "Subcategoria",
          check: false,
          value:null,
        },
        tags: {
          filterName: "tags",
          check: false,
          value:null,
        },
        descricao_catmas: {
          filterName: "descricao_catmas",
          headerName: "Descrição CATMAS",
          check: false,
          value:null,
        },
        descricao: {
          filterName: "descricao",
          value:null,
        },
        tagsString: {
          headerName: "Tags",
          check: false,
        },
      };
    },

    async deletaItem(itemid) {
      await ItensService.deleteItem(itemid);

      this.showSnackbar("O item foi deletado com sucesso!");
      this.clear();
    },

    async editarItem() {
      if(!this.$refs.form.validate()){
        this.showSnackbar("Favor preencher os campos obrigátorios *", "error");
        return;
      }
      this.form.imagem = this.form.imagem.concat(this.imagesEdit);

      const response = await ItensService.editarItem(
        this.itemid,
        this.form,
      );

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }
      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A edição do item foi salva com sucesso");
      this.clear();
    },

     clean(obj) {
      const emptyValues = [undefined, '']
      Object.keys(obj).forEach((value) => {
        if (emptyValues.includes(obj[value]) || obj[value].length === 0) {
          delete obj[value];
        }
      })
      
      return obj
    },

    async editarMultiplosItens(){
      console.log(this.form)
      const form = this.clean(this.form);

      console.log(form);
      const response = await ItensService.editarMultiplosItens(
        this.itemid,
        form,
      );
      
      let imagensEdit = this.form.imagem ? [...this.form.imagem] : [];
      imagensEdit = imagensEdit.concat(this.imagesEdit);
      this.form.imagem = imagensEdit ? JSON.stringify(imagensEdit) : "";

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }
      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A edição do item foi salva com sucesso");
      this.clear();
    },

    async cadastrarItem() {
      if(!this.$refs.form.validate()){
        this.showSnackbar("Por Favor preencher os campos obrigátorios *", "error");
        return;
      }

      if(typeof this.form.imagem == 'object'){
        this.form.imagem = this.form.imagem ? JSON.stringify(this.form.imagem) : "";

      }

      if(this.form.subcategoria_id == ""){
        this.form.subcategoria_id = null
      }

      const response = await ItensService.cadastrarItem(this.form);

      if (response.error) {
        const errorMessage = response.data.errors?.catmas_id[0] || "Não foi possível completar a ação";
        this.showSnackbar(errorMessage, "error");
        return;
      }

      this.formularioDialog = !this.formularioDialog;

      this.images = [];
      this.form.imagem = [];
      this.showSnackbar("O item foi cadastrado com sucesso");
      this.clear();
    },

    removerImagem(index){
      this.imagesEdit.splice(index, 1);
    },

    convertStringToJson(string){
     return typeof string == 'string' ? JSON.parse(string) : string;
      
    },

    getEditData(item) {
      this.loading.fields = true;
      this.form.tipo_id = item.item_tipo.id;
      this.form.marcas = item?.marcas?.map(marca => marca.id);
      this.form.modelos = item?.modelos?.map(modelo => modelo.id);
      this.form.materiais = item?.materiais?.map(materiais => materiais.id);
      this.form.categoria_id = item?.categoria?.id;
      this.form.subcategoria_id = item?.subcategoria?.id;
      this.form.catmas_id = item.codigo_catmas.id;
      this.form.descricao = item.descricao;
      this.form.templates = item?.templates?.map(template => template.id);

      const tags = item.tags ? item.tags : []
      this.form.tags = tags.map(tag => tag.id);
      this.form.administrador_id =  item.tipo_administrador.id;
      if(item.imagens){

        this.inputImagens = [];
        this.imagesEdit = this.convertStringToJson(item.imagens);
      } else {
        this.imagesEdit = [];
      }
      // this.form.subcategoria = await response.categoria.id;
      // this.form.tipo = await response.tipo;

      this.loading.fields = false;
    },
  },
};
