const FuncionalidadeEnum = {
    CRIAR_CATEGORIA: 1,
    EDITAR_CATEGORIA: 2,
    VISUALIZAR_CATEGORIA: 3,
    DELETAR_CATEGORIA: 4,
    ACESSAR_CATEGORIA_MENU: 5,

    CRIAR_SUBCATEGORIA: 6,
    EDITAR_SUBCATEGORIA: 7,
    VISUALIZAR_SUBCATEGORIA: 8,
    DELETAR_SUBCATEGORIA: 9,
    ACESSAR_SUBCATEGORIA_MENU: 10,

    CRIAR_PERFIL: 11,
    EDITAR_PERFIL: 12,
    VISUALIZAR_PERFIL: 13,
    DELETAR_PERFIL: 14,
    ACESSAR_PERFIL_MENU: 15,

    CRIAR_PESSOA_PERFIL: 16,
    EDITAR_PESSOA_PERFIL: 17,
    VISUALIZAR_PESSOA_PERFIL: 18,
    DELETAR_PESSOA_PERFIL: 19,
    ACESSAR_PESSOA_PERFIL_MENU: 20,

    CRIAR_TAG: 26,
    EDITAR_TAG: 27,
    VISUALIZAR_TAG: 28,
    DELETAR_TAG: 29,
    ACESSAR_TAG_MENU: 30,

    VISUALIZAR_LOG: 31,
    ACESSAR_LOG_MENU: 32,

    CRIAR_MARCA: 33,
    CRIAR_MODELO: 34,

    CRIAR_ITEM: 35,
    EDITAR_ITEM: 36,
    VISUALIZAR_ITEM: 37,
    DELETAR_ITEM: 38,
    ACESSAR_ITEM_MENU: 39,

    CRIAR_TEMPLATE: 40,
    EDITAR_TEMPLATE: 41,
    VISUALIZAR_TEMPLATE: 42,
    DELETAR_TEMPLATE: 43,
    ACESSAR_TEMPLATE_MENU: 44,

    CRIAR_PAGINA: 45,
    EDITAR_PAGINA: 46,
    VISUALIZAR_PAGINA: 47,
    DELETAR_PAGINA: 48,
    ACESSAR_PAGINA_MENU: 49,

    MOVIMENTAR_MATERIAL: 50,

    CRIAR_ALERTA: 51,
    EDITAR_ALERTA: 52,
    VISUALIZAR_ALERTA: 53,
    DELETAR_ALERTA: 54,
    ACESSAR_ALERTA_MENU: 55,
    ACESSAR_PAINEL_MENU: 56,
    ACESSAR_MAPA_CALOR_MENU: 57,
    ACESSAR_MEUS_DADOS_MENU: 58,
    ACESSAR_MOVIMENTACAO_MENU: 59,
    ACESSAR_HISTORICO_MOVIMENTACAO_MENU: 60,
    ACESSAR_HISTORICO_DESCARTE_MENU: 61,
    ACESSAR_RELATORIO_GERAL_MENU: 62,
    ACESSAR_DISPONIBILIZAR_MATERIAL_MENU: 63,
    
    VISUALIZAR_PAINEL: 64,
    DESCARTE_MATERIAL: 65,
    DESCARGA_MATERIAL: 66,
    ACEITAR_DISPONIBILIZACAO_MATERIAL: 67,
    RECUSAR_DISPONIBILIZACAO_MATERIAL: 68,
    SOLICITAR_MATERIAL_DISPONIBILIZADO: 69,
    ACEITAR_DISPONIBILIZACAO_MATERIAL_UNIDADE: 70,
    VISUALIZAR_RELATORIO_GERAL: 71,
    EMITIR_RECIBO: 72,
    VISUALIZAR_MATERIAL: 73,
    CRIAR_MATERIAL: 74,
    EDITAR_MATERIAL: 75,
    DELETAR_MATERIAL: 76,
    VINCULAR_MATERIAL: 77,

    CLONAR_TEMPLATE: 78,

    SOLICITAR_DISPONIBILIZACAO: 79,
    DISPONIBILIZAR_MATERIAL: 80,
    APROVAR_SOLICITACAO_DISPONIBILIZACAO: 81,
    APROVAR_DISPONIBILIZACAO: 82,


    VISUALIZAR_PERMUTA: 83,
    CRIAR_PERMUTA: 84,
    EDITAR_PERMUTA: 85,
    DELETAR_PERMUTA: 86,

    ACEITAR_SOLICITACAO_MATERIAL: 87,
    RECUSAR_SOLICITACAO_MATERIAL: 88,
}

export default FuncionalidadeEnum
