import TextField from "../../../../components/fields/textField/TextField.vue";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import { AssinaturaMixin } from "../../../../mixins";
import FormDialog from "../../../../components/dialog/form/FormDialog.vue";
import { SnackbarMixin } from "../../../../mixins";
import PessoaPerfilService from "../../../../services/PessoaPerfilService";

// import PerfilService from "../../services/PerfilService";

export default {
  name: "DialogAssociar",
  components: {
    TextField,
    ConfirmPasswordDialog,
    FormDialog,
  },
  props: {
    activeDialog: {
      type: Boolean,
      default: false,
    },
    userSelected: {
      required: true,
    },
    dataPerfis: {
      required: true,
    },
  },
  mixins: [SnackbarMixin, AssinaturaMixin],
  data: () => ({
    headers: [
      { text: "Perfil", value: "nome", sortable: false },
      { text: "", value: "atribuir", sortable: false, width: "100" },
    ],
    items: [],
    errors: [],
    disabled: {
      perfil: false,
    },
    form: {},
    formValid: true,
    loading: {
      table: false,
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    showFormDialog: false,
    showDialogConfirmPassword: false,
    formConfirmPassword: {},
    send: {
      function: () => {},
      params: null,
    },

    perfis: [],
  }),

  watch: {
    userSelected(userInfo) {
      this.form.sigrh_id = userInfo.id;
      console.log('userInfo',userInfo)
      this.form.perfil_id = userInfo.perfis.map(perfil => perfil.id);
    },

    dataPerfis(perfis) {
      this.items = [...perfis];
    },

    activeDialog() {
      this.showFormDialog = !this.showFormDialog;
    },
  },

  created() {
    this.initializeFormConfirmPassword();
    this.initializeForm();
  },

  methods: {
    goTo(path) {
      this.$router.push(path);
    },

    initializeForm() {
      this.form = {
        sigrh_id: "",
        perfil_id: [],
      };
    },

    async save() {
      this.errors = [];
      this.disabled.perfil = true;
      let response = await PessoaPerfilService.insertPessoaPerfil(
        this.form,
        );
      this.disabled.perfil = false;

      if (!response.error) {
        this.showSnackbar(
          "Os perfis do usuário foram atualizados com sucesso",
        );
        this.closed();
      } else {
        this.showSnackbar("Não foi possível completar a solicitação", "error");
      }
    },

    closed() {
      this.showFormDialog = !this.showFormDialog;
      this.$emit("closed");
    },
  },
};
