export default {
  name: "FormDialog",
  props: {
    type: {
      default: "New",
      type: String,
    },
    activeDialog: {
      default: false,
      type: Boolean,
    },
    hiddenButtom: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    btnText: {
      default: "Salvar",
      type: String,
    },
  },

  data: () => ({
    dialog: false,
  }),

  watch: {
    activeDialog() {
      this.dialog = !this.dialog;
     
    },

    dialog(value){
      if(!value){
        this.$emit('close')
      }
    }
  },
  methods: {

    close(){
      this.$emit('close')
    },
    save() {
      if (this.type === "New") {
        this.$emit("on-save-new");
        return;
      }

      if (this.type === "Edit") {
        this.$emit("on-save-edit");
        return;
      }
      if (this.type === "Duplicar") {
        this.$emit("on-save-duplicar");
        return;
      }
      if (this.type === "EditMultiple") {
        this.$emit("on-save-edit-multiple");
        return;
      }

      if(this.type == 'Detalhe'){
        this.$emit('close')
      }
    },
  },
};
