import TipoAdminService from "../services/TipoAdminService";
// import { mapActions } from "vuex";
import opcoesMixins from "../mixins/opcoesMixins";


export default {
  data() {
    return {
      tipoAdminOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getTipoAdmin()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getTipoAdmin() {
      const response = await TipoAdminService.getTipoAdmin();
      if (!response.error) {
        this.tipoAdminOpcoes = this.trataOptions(response.data.data);

        // this.ActionSetTipoAdmin(this.tipoAdminOpcoes);
      }
    },


    verificarSeExisteTipoAdmin() {
      if (!this.$store.getters["opcoes/existe_tipo_tempo"]) {
        return this.getTipoAdmin();
      }

      this.tipoAdminOpcoes = this.$store.state.opcoes.tipoAdmin;
    },
  },
};
