import { quillEditor } from "vue-quill-editor";
// import { Button, Input, Select } from "iview";
import "quill/dist/quill.snow.css"; // for snow theme
import Vue from "vue";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "RichTextEditor",
  components: {
    quillEditor,
  },

  props: {
    text: {
      required: true,
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    isSaved: {
      default: false,
      type: Boolean,
    },
  },
  
  watch: {
    text() {
      this.content = this.text;
      this.initialContent = this.text;
    },
    
    isSaved() {
      this.editMode = false;
      this.initialContent = this.content;
    },

    editMode(value) {
      if (value) {
        this.editorOption.modules.toolbar = this.fullToolbar;
        return;
      }
      this.editorOption.modules.toolbar = null;
    },
  },

  created(){
    this.permissionsUser = Vue.prototype.$permissionsUser;
    this.hasEditOrCreatePermission()
  },

  data: () => ({
    FuncionalidadeEnum: FuncionalidadeEnum,
    fullToolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: "1" }, { header: "2" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "super" }, { script: "sub" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["direction"],
      [{ font: [] }, { size: [] }],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["clean"],
    ],
    permissionsUser:[],
    editMode: false,
    content: "",
    initialContent: "",
    editorOption: {
      debug: "info",
      placeholder: "",
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: "1" }, { header: "2" }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "super" }, { script: "sub" }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["direction"],
          [{ font: [] }, { size: [] }],
          [{ color: [] }, { background: [] }],
          ["link", "image"],
          ["clean"],
        ],
      },
    },
  }),

  methods: {
    save() {
      this.$emit("save", this.content);
    },

    verificarPermissaoBotao(){
      return !this.content ? this.permissionsUser.includes('pagina.store') : this.permissionsUser.includes('pagina.update');
    },

    clear() {
      this.content = this.initialContent;
      this.editMode = false;
    },

    hasEditOrCreatePermission() {
      return this.$store.state.funcionalidades.includes(this.FuncionalidadeEnum.EDITAR_PAGINA) ||
             this.$store.state.funcionalidades.includes(this.FuncionalidadeEnum.CRIAR_PAGINA);
    }
  },
};
