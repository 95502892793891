import api from "./Api"

export default  {


    async getPessoaPerfilTemporario(params){
        const config = {params: params}
        const response = await api.get(`/pessoa_perfil_temporario`, config)
        .then((response) => response)
        .catch(err => err);
        return response;
    },

    async insertPessoaPerfilTemporario(postData){
        
        const response = await api.post('/pessoa_perfil_temporario', postData)
        .then((response) => response)
        .catch(err => err);
 

        return response;
    },

    async updatePessoaPerfilTemporario(postData, id){
      
        const response = await api.put(`/pessoa_perfil_temporario/${id}`, postData)
        .then((response) => response)
        .catch(err => err); 

        return response;
    },



 
}