import api from "./Api"
import BaseService from "./BaseService";

export default  {


    async getPerfil(params){
        const config = {params: params}
        const response = await api.get('/perfil/search', config)
        .then((response) => response)
        .catch(err => err);
        return response;
    },

    async getPerfilOptions(params){
        const config = {params: params}
        const response = await api.get('/perfil', config)
        .then((response) => response)
        .catch(err => err);
        return response;
    },


    async insertPerfil(postData){
        
        const response = await api.post('/perfil', postData)
        .then((response) => response)
        .catch(err => err);
 

        return response;
    },

    async updatePerfil(postData, id){
      
        const response = await api.post(`/funcionalidade-perfil/${id}`, postData)
        .then((response) => response)
        .catch(err => err); 

        return response;
    },

    
    async deletePerfil(id){
        
        const response = await api.delete(`/perfil/${id}`)
        .then((response) => response)
        .catch(err => err); 

        return response;
    },

    async getNotificacao() {
    const response = await BaseService.fetchResponseFromAPIGet(
      `notificacao`,
    );

    return response.content
  },

  async deleteNotificacao(id) {
    const response = await BaseService.fetchResponseFromAPIDelete(
      `notificacao/${id}`,
    );

    return response;
  },
 
}
