import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ConfirmDialog from "../../components/dialog/confirm/ConfirmDialog.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import TextField from "../../components/fields/textField/TextField.vue";
import DialogFormPerfil from "./gerenciar-perfis/formPerfil/DialogFormPerfil.vue";
import DialogFuncionalidades from "./gerenciar-perfis/formFuncionalidades/DialogFuncionalidades.vue";
import DialogAssociar from "./gerenciar-perfis/formAssociar/DialogAssociar.vue";
import PerfilService from "../../services/PerfilService";
import { SnackbarMixin, AssinaturaMixin } from "../../mixins";
import ConfirmPasswordDialog from "../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import Vue from "vue";
import GrupoService from "../../services/GrupoService";
import PessoaService from "../../services/PessoaService";
import PessoaPerfilTemporarioService from "../../services/PessoaPerfilTemporarioService";

export default {
  name: "GerenciarUsuarios",
  components: {
    FormDialog,
    ConfirmDialog,
    SelectField,
    TextField,
    DialogFormPerfil,
    DialogAssociar,
    DialogFuncionalidades,
    ConfirmPasswordDialog,
  },
  mixins: [AssinaturaMixin, SnackbarMixin],
  data: () => ({
    perfilid: null,
    unidadeId: null,
    usersSelecteds: [],
    showDialogConfirmPassword: false,
    formConfirmPassword: "",
    send: {
      function: () => {},
      params: null,
    },
    dialogShow: {
      associarPerfil: false,
      editPerfil: false,
      funcionalidades: false,
    },
    userselected: {},
    options: {
      perfis: [],
    },
    perfiSelectedlid: 0,
    form: {},
    disable: {
      perfis: false,
    },
    loading: {
      table: false,
      tablePerfis: false,
      tableUsers: false,
      tableAcesso: false,
      perfis: false,
    },
    dialogDeletePerfil: false,
    perfil_id: [],
    tablePerfis: {
      data: [],
      headers: [
        { text: "Perfil", value: "nome", sortable: false },
        {
          text: "Ações",
          width: "200",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      page: 1,
      pageCount: 1,
    },
    tableUsers: {
      data: [],
      headers: [
        { text: "Matrícula", value: "numero", sortable: false },
        { text: "Nome", value: "nome", sortable: false },
        { text: "Perfis", value: "perfis_string", sortable: false },
        {
          text: "Ações",
          width: "200",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      page: 1,
      pageCount: 1,
      filters: {},
      permissionsUser:[]
    },
    tableAcesso: {
      data: [],
      headers: [
        { text: "Matricula", value: "pessoa.numero", sortable: false },
        { text: "Nome", value: "pessoa.nome", sortable: false },
        { text: "Perfil atual", value: "perfisAtuais", sortable: false },
        { text: "Perfil Solicitado", value: "perfis.nome", sortable: false },
        { text: "Data", value: "data_inicio", sortable: false },
        { text: "Data Limite", value: "data_fim", sortable: false },
        { text: "Justificativa", value: "justificativa", sortable: false },
        { text: "Data de solicitação", value: "created_at", sortable: false },
        { text: "Status", value: "status.nome", sortable: false },
        {
          text: "Ações",
          width: "200",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      page: 1,
      pageCount: 1,
    },
  }),

  created() {
    // this.initializeFormConfirmPassword();
    // this.initializePermissions();
    // this.permissionsUser = Vue.prototype.$permissionsUser;

  },

  async mounted() {
    // await this.setGrupoName();
    this.getUsers();
    this.getPerfis();
    this.getPessoaPerfilTemporario()
    // this.getPessoaPerfilTemporario();
  },

  watch:{
    "tablePerfis.page"(){
      this.getPerfis()

    },

    "tableUsers.page"(){
      this.getUsers()

    },

    "tableAcesso.page"(){
      this.getPessoaPerfilTemporario()

    }
  },

  methods: {
    initializePermissions() {
      this.permissions = {
        criar_perfil: false,
        listar_perfil: false,
      };
    },

    goTo(path) {
      this.$router.push(path);
    },

    initializeFilters() {
      this.tableUsers.filters = {
        nome: "",
        numero: "",
      };
    },



    async setGrupoName(){
      return new Promise((resolve) => { 
        const keycloak = Vue.prototype.$keycloak;
        keycloak.loadUserInfo().then(userinfo => {
          const unidade = userinfo.groups[0];
          
          GrupoService.getGruposPorNome(unidade).then(response => {
            this.unidadeId = response[0].id;
            resolve(true);
          });
        });
      })
    },

    async atualizarPerfil(id, status_id){
        this.loading.tableAcesso = true;
        const postData = {
          status_id: status_id
        }
        const response = await PessoaPerfilTemporarioService.updatePessoaPerfilTemporario(postData,id);
        if (!response.error) {
          this.getPessoaPerfilTemporario();
          this.showSnackbar("Ação realizada com sucesso");
          this.loading.tableAcesso = false;
          return 
        }

        this.showSnackbar("Não foi possível completar a solicitação", "error");
    },

 
   
    async getPerfis() {
      this.loading.tablePerfis = true;
      const params = {page: this.tablePerfis.page}
      const response = await PerfilService.getPerfil(params);
      if(!response.error){

        this.tablePerfis.data = response.data.data
        this.loading.tablePerfis = false;
        this.tablePerfis.pageCount = response.data.last_page

      }
    },

    async getPessoaPerfilTemporario() {
      this.loading.tablePerfis = true;
      const params = {page: this.tablePerfis.page}
      const response = await PessoaPerfilTemporarioService.getPessoaPerfilTemporario(params);
      if(!response.error){

        response.data.data.map((value,key) => {
          let perfis = ""
          value['pessoa']['perfis'].map((perfil) => {
            perfis += `${perfil.nome} `
          })
  
          response.data.data[key]['perfisAtuais'] = perfis
        })

        this.tableAcesso.data = response.data.data
        this.loading.tableAcesso = false;
        this.tableAcesso.pageCount = response.data.last_page

      }
    },

    deletePerfil(id){
      this.dialogDeletePerfil = !this.dialogDeletePerfil;
      this.perfil_id = id;
    },

    async getUsers() {
      this.loading.tableUsers = true;
      const params = {page: this.tableUsers.page}
      const response = await PessoaService.getPessoa(params)


      if(!response.error){
        
       response.data.data.map((value,key) => {
        let perfis = ""
        value['perfis'].map((perfil) => {
          perfis += `${perfil.nome} `
        })

        response.data.data[key]['perfis_string'] = perfis
      })
        this.tableUsers.data = response.data.data
        this.loading.tableUsers = false;
        this.tableUsers.pageCount = response.data.last_page

      }

    },

    showDialogFuncionalidade(perfil) {
      this.dialogShow.funcionalidades = !this.dialogShow.funcionalidades;
      this.perfiSelectedlid = perfil;
    },

    showDialogAssociacao(userinfo) {
      this.dialogShow.associarPerfil = !this.dialogShow.associarPerfil;
      this.userselected = userinfo;
    },

    perfilCriado(perfilid) {
      this.getPerfis();
      this.showDialogFuncionalidade(perfilid);
    },

    funcionalidadeCriado() {
      this.getPerfis();
    },

    async deletarPerfil(perfilid) {
      const response = await PerfilService.deletePerfil(perfilid);

      if (response.error) {
        const messageError = await response.response;
        this.showSnackbar(messageError, "error");

        return;
      }

      this.showSnackbar("O perfil foi deletado com sucesso");
      this.page = 1;
      this.getPerfis();
    },
  },
};
