import BaseService from "./BaseService";
import api from "./Api"

export default {
  async getModelos() {
    const response = await api.get(`/modelo`)
    .then((response) => response)
    .catch(err => err);
    return response;
  },

  async cadastrarModelo(formData) {
    const postData = JSON.stringify(formData);
    console.log(postData)
    const response = await BaseService.fetchResponseFromAPIPost(
      `modelo`,
      postData,
    );

    return response;
  },
};
