import TextField from "../../../components/fields/textField/TextField.vue";
import SelectField from "../../../components/fields/selectField/SelectField.vue";
import FormDialog from "../../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import moment from "moment";
import MateriaisService from "../../../services/MateriaisService";
import { SnackbarMixin, FiltroMixin } from "../../../mixins";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";



export default {
  name: "DisponibilizacaoMaterial",

  components: { TextField, SelectField, FormDialog, ConfirmDialogButton},

  mixins: [SnackbarMixin, FiltroMixin],


  data: () => ({
    headers: [
      { text: "Material", value: "material_patrimonio", sortable: false },
      { text: "Código do Material", value: "codigo_catmas", sortable: false },
      { text: "Quantidade", value: "solicitacao_material_quantidade_solicitada", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      { text: "Data", value: "created_at", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    items: [],
    FuncionalidadeEnum: FuncionalidadeEnum,
    itemsClear: [],
    searchReset:false,
    materiais:[],

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    lastPage:1,
    form: {},
    filter: {},
    options: {
      administrador: [],
    },
    formValid: false,
    formularioDialog: false,
    formTitle: "",
    formType: "",
    loading: {
      table: false
    }
  }),
  
  async mounted () {
    this.getSolicitacaoMaterial();
  },

  watch: {
    page(){
      this.getDisponibilizarMaterial();
    }
  },

  created(){
    this.initializeFilters()
  },

  methods: {
    async getSolicitacaoMaterial() {
      this.loading.table = true;

      const params = {
        material: this.filter.material,
        codigo: this.filter.codigo,
        quantidade: this.filter.quantidade,
        unidade: this.filter.unidade,
        data_criacao: this.filter.data_criacao,
        page: this.page
      };
          
      const response = await MateriaisService.solicitacaoMaterial(params);
      this.items = this.trataData(response.data.data);
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;

      this.loading.table = false;
    },
  
    search() {
      this.getDisponibilizarMaterial();
    },

    trataData(data){ 
      const dataTratada = data.map(solicitacao => {
        return {
            solicitacao_material_id: solicitacao.solicitacao_material_id,
            solicitacao_material_quantidade_solicitada: solicitacao.solicitacao_material_quantidade_solicitada,
            created_at: moment(solicitacao.solicitacao_material_created_at).format("DD/MM/YYYY HH:mm"),
            status_solicitacao_material_id: solicitacao.status_solicitacao_material_id,
            status_solicitacao_material_nome: solicitacao.status_solicitacao_material_nome,
            material_id: solicitacao.material_id,
            material_quantidade: solicitacao.material_quantidade,
            material: solicitacao.descricao_catmas,
            material_disponibilizados_id: solicitacao.material_disponibilizados_id,
            codigo_catmas_id: solicitacao.catmas_id,
            codigo_catmas: solicitacao.codigo_catmas,
            descricao_catmas: solicitacao.descricao_catmas,
            unidade_id: solicitacao.unidade_id,
            unidade: solicitacao.unidade_codigo,
            material_patrimonio: solicitacao.material_patrimonio ? solicitacao.material_patrimonio : "", 
        }
      });

      return dataTratada;
    },

    async confirmarSolicitacao(item) {
      this.loading.table = true;
      const postData = {
        material_disponibilizado_id: item.solicitacao_material_id,
      };

      const response = await MateriaisService.aceitarSolicitacao(postData);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.table = false;
        return;
      }
      
      this.showSnackbar("A aprovação da movimentação foi realizada com sucesso");
      this.getSolicitacaoMaterial();
      this.loading.table = false; 
    },

    async recusarSolicitacao(item) {
      this.loading.table = true;
      const postData = {
        material_disponibilizado_id: item.solicitacao_material_id,
      };

      const response = await MateriaisService.recusarSolicitacao(postData);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.table = false;
        return;
      }
      
      this.showSnackbar("A aprovação da movimentação foi realizada com sucesso");
      this.getSolicitacaoMaterial();
      this.loading.table = false;
      
    },

    initializeFilters() {
      this.filter = {
        material: "",
        codigo: "",
        quantidade: "",
        unidade: "",
        data_criacao: "",
        status_id: "",
      }
    },
  },
};
