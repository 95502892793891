import TextField from "../../fields/textField/TextField.vue";
import TextAreaField from "../../fields/textAreaField/TextAreaField.vue";
import ValidaUsuarioService from "../../../services/ValidaUsuarioService";
import { SnackbarMixin } from "../../../mixins";

export default {
  name: "ConfirmPasswordDialog",

  components: {
    TextAreaField,
    TextField,
  },

  mixins: [SnackbarMixin],

  props: {
    activeDialog: {
      default: false,
      type: Boolean,
    },

    vModelPassword: {
      required: true,
      type: String,
    },

    showUser:{
      default: false,
      required:false,
      type: Boolean,
    },

    vModelUser:{
      default: false,
      required:false,
    }
  },

  data: () => ({
    showPassword: false,
    dialog: false,
    formValid: true,
    valuePassword: null,
    valueUser: null,

  }),

  beforeMount() {
    this.valuePassword = this.vModelPassword;
    this.valueUser = this.vModelUser;
  },

  watch: {
    activeDialog() {
      this.showPassword = false;
      this.$refs.form.resetValidation();
      this.dialog = !this.dialog;
    },

    valuePassword(input) {
      this.$emit("inputPassword", input);
    },
    valueUser(input) {
      this.$emit("inputUser", input);
    },

    vModelPassword(input) {
      this.valuePassword = input;
    },

    vModelUser(input) {
      this.valueUser = input;
    },
  },
  methods: {
    close() {
      this.$emit("closed");
      this.dialog = !this.dialog;
    },

    async send() {
      if (this.$refs.form.validate()) {
        const postData = {
          pass: this.valuePassword,
        };
        const response = await ValidaUsuarioService.validaUsuario(postData, this.valueUser);
        if (response.error) {
          this.showSnackbar(response.message.response, "error");
          return;
        }

        this.$emit("send");
        this.close();
      }
    },
  },
};
