// import Vue from "vue";


export default {
  name: "Disponibilizacao",

  data: () => ({
    items: [
      {
        text: "Disponibilização de Materiais",
        // show: Vue.prototype.$permissionsUser.includes('material.listarDisponibilizado'),
        show: true,
        to: {
          name: "DisponibilizarMateriais",
        },
      },
      {
        text: "Solicitação de Materiais",
        show:true,
        to: {
          name: "SolicitacaoMateriais",
        },
      },
      {
        text: "Materiais Disponibilizados Sem Aprovação",
        show:true,
        to: {
          name: "MateriaisDisponibilizadosSemAprovacao",
        },
      },
      {
        text: "EPIS's disponibilizados",
        show:true,
        to: {
          name: "EPISDisponibilizadas",
        },
      },
    ],
  }),
};
