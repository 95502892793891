import UnidadeService from "../services/UnidadeService";
// import { mapActions } from "vuex";
import opcoesMixins from "../mixins/opcoesMixins";


export default {
  data() {
    return {
      unidadesOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getUnidades()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getUnidades() {
      const response = await UnidadeService.getUnidades();
      if (!response.error) {
        this.unidadesOpcoes = this.trataOptions(response.data.data);

        // this.ActionSetTipoAdmin(this.tipoAdminOpcoes);
      }
    },


    verificarSeExisteUnidades() {
      if (!this.$store.getters["opcoes/existe_unidade"]) {
        return this.getUnidades();
      }

      this.unidadesOpcoes = this.$store.state.opcoes.unidades;
    },
  },
};
