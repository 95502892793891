import api from "./Api"

export default {
  async getSubcategorias(params = "") {  
    const config = {params: params}
    const response = await api.get(`subcategoria/search?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async getSubcategoriasSemFiltro() {
    const response = await api.get(`/subcategoria`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  async cadastrarSubcategoria(formData) {
    const response = await api.post( `subcategoria`,formData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async deleteSubcategoria(subcategoriaid) {
    const response = await api.delete(`subcategoria/${subcategoriaid}`)
      .then((response) => response)
      .catch(err => err);

  return response;
  },

  async editarSubcategoria(subcategoriaid, postData) {
    const response = await api.put(`subcategoria/${subcategoriaid}`, postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async verificaNome(formData) {  
    const response = await api.post(`/subcategoria/verificar-nome`,formData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
};
