import Vue from "vue";

const VUE_APP_URL_BASE_SERVICES = process.env.VUE_APP_URL_BASE_SERVICES;

export default {
  async fetchResponseFromAPIGet(endpoint) {
    const response = {
      content: [],
      error: "",
    };
    try {
      const data = await fetch(`${VUE_APP_URL_BASE_SERVICES}/${endpoint}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: Vue.prototype.$keycloak.token,
        },
      });

      response.content = await data.json();
    } catch (e) {
      response.error = await e;
    }

    return response;
  },

  async fetchResponseFromAPIDirectGet(endpoint) {
    let response = {
      error: false,
      result: null,
    };

    try {
      let data = await fetch(`${VUE_APP_URL_BASE_SERVICES}/${endpoint}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: Vue.prototype.$keycloak.token,
        },
      });
      data = await data.json();

      response.result = await data;
    } catch (e) {
      response.error = await e;
    }

    return response;
  },

 async fetchResponseFromAPIPost(endpoint, postData) {
    const data = {
        content: [],
        error: false,
    };

    let headers = {
        Accept: "application/json",
        token: Vue.prototype.$keycloak.token,
    };

    if (!(postData instanceof FormData)) {
        headers["Content-Type"] = "application/json";
    }

    try {
        const response = await fetch(`${VUE_APP_URL_BASE_SERVICES}/${endpoint}`, {
            method: "POST",
            headers,
            body: postData,
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw responseJson;
        }

        data.content = responseJson;
    } catch (e) {
        data.error = true;
        data.message = e; // Não precisa do await aqui, pois e já é uma Promise resolvida/rejeitada
    }

    return data;
},


  async fetchResponseFromAPIPut(endpoint, postData) {
  const data = {
    message: "",
    error: false,
  };

  let headers = {
    Accept: "application/json",
    token: Vue.prototype.$keycloak.token,
  };

  if (!(postData instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  try {
    const response = await fetch(`${VUE_APP_URL_BASE_SERVICES}/${endpoint}`, {
      method: "PUT",
      headers,
      body: postData,
    });
    
  const responseJson = await response.json();

        if (!response.ok) {
            throw responseJson;
        }

        data.content = responseJson;
    } catch (e) {
        data.error = true;
        data.message = e; // Não precisa do await aqui, pois e já é uma Promise resolvida/rejeitada
    }

    return data;
},

  async fetchResponseFromAPIDelete(endpoint) {
    const data = {
      message: "",
      error: false,
    };

    try {
      let response;
      await fetch(`${VUE_APP_URL_BASE_SERVICES}/${endpoint}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: Vue.prototype.$keycloak.token,
        },
      }).then((result) => {
        response = result;
        const responseJson = response.json();

        if (!response.ok) {
          throw responseJson;
        }

        data.message = responseJson;
      });
    } catch (e) {
      data.error = true;
      data.message = await e;
    }

    return data;
  },


  async downloadFileWithFetch(url) {
    // Adiciona o token e outros cabeçalhos se necessário
    const headers = new Headers();
    const token = localStorage.getItem('token'); // Supondo que o token esteja armazenado no localStorage
    if (token) {
      headers.append("Token", token);
    }
  
    fetch(url, {
      method: 'GET',
      headers: headers,
    })
    .then(response => {
      if (response.ok) return response.blob();
      throw new Error('Network response was not ok.');
    })
    .then(blob => {
      // Cria um URL para o blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = "relatorio_simplificado.pdf";
      document.body.appendChild(a); // Append the anchor to body
      a.click();
      window.URL.revokeObjectURL(url); // Limpa o URL
      a.remove(); // Remove o elemento anchor
    })
    .catch(error => console.error('Download error:', error));
  }

};
