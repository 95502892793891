import axios from "axios";
import LocalStorageService from "./LocalStorageService";

const token = LocalStorageService.get('token');

const api = axios.create({
  baseURL: `${process.env.VUE_APP_URL_BASE_SERVICES}`,
});

if (token) {
  api.defaults.headers.common["Token"] = token
}

api.interceptors.request.use(
  (config) => {
    // Incrementa o contador antes de cada requisição
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
  
    // if(response.config.method == "post"){
    //   Notify.create({
    //     message: "Salvo com sucesso",
    //     color: "green",
    //   });
    // }

    // response['error'] = false


    return { error: false, data: response.data };
  },
  (error) => {

    console.log('error', error)
    
    // const messagesDefault = {
    //   get: ["Tente novamente mais tarde"],
    //   post: ["Verifique os dados"],
    //   patch: ["Verifique os dados"],
    // };

    if (typeof error.response.data == "string") {
      // let message = { error: messagesDefault[error.response.config.method] };
      // Notify.create({
      //   message: message,
      //   color: "red",
      // });
      return Promise.reject({ error: true, data: error.response.data });
    }

    if (error.response.data?.detail) {
      // Notify.create({
      //   message: error.response.data.detail,
      //   color: "red",
      // });
      return Promise.reject({ error: true, data: error.response.data });
    }

    return Promise.reject({ error: true, data: error.response.data });
  }
);

export default api;
