export default {
  name: "ConfirmDialog",
  props: {
    text: {
      required: true,
      type: String,
    },
    showDialog: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    dialog: false,
  }),

  watch: {
    showDialog() {
      this.dialog = true;
    },
  },

  methods: {
    confirmAction() {
      this.$emit("confirmAction");
      this.dialog = false;
    },

    recuseAction() {
      this.$emit("recuseAction");
      this.dialog = false;
    },
  },
};
