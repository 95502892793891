// import { TEXT, OPTIONS } from "@/constants/index.js";
// import TemplatesService from "../../../services/TemplatesService";
import ConfirmDialogButton from "../../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import TextField from "../../../components/fields/textField/TextField.vue";
import SelectField from "../../../components/fields/selectField/SelectField.vue";
import PeriodoField from "../../../components/fields/periodoField/PeriodoField.vue";
import FormDialog from "../../../components/dialog/form/FormDialog.vue";
import TextAreaField from "../../../components/fields/textAreaField/TextAreaField.vue";
import ConfirmDialog from "../../../components/dialog/confirm/ConfirmDialog.vue";
// import ConfirmPasswordDialog from "../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import { FiltroMixin, SnackbarMixin , VerificarTituloMixin} from "../../../mixins";
import itemTipoMixins from "../../../mixins/itemTipoMixins";
import TemplatesService from "../../../services/TemplatesService";
import { ITENS } from "../../../constants";
import Vue from "vue";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";

export default {
  name: "ConsultarPage",
  components: {
    ConfirmDialogButton,
    TextField,
    SelectField,
    PeriodoField,
    FormDialog,
    TextAreaField,
    ConfirmDialog
  },
  mixins: [SnackbarMixin, FiltroMixin, VerificarTituloMixin, itemTipoMixins],

  data: () => ({
    filters: [],
    data: [],
    loading: {
      table: false,
    },
    FuncionalidadeEnum: FuncionalidadeEnum,
    text: null,
    itemDelete: null,
    options: {
      filterTipo: [],
    },
    headers: [
      { text: "Nome", value: "nome", sortable: false },
      // { text: "Tipo", value: "tipo_template", sortable: false },
      { text: "Ações", value: "actions", align: "center", sortable: false },
    ],
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    permissionsUser:[],
  }),

  created() {
    this.initializeFilters();
    this.permissionsUser = Vue.prototype.$permissionsUser;
  },

  watch:{
    page(){
      this.getTemplates();
    }
  },

  mounted() {
    this.getOptions();
    this.getTemplates();
  },

  methods: {
    search() {
      this.getTemplates();
    },

    async copiarTemplate(item) {
      await TemplatesService.copyTemplate({template_id:item.id});
      this.clear();
    },

    trataDadosItens(data) {
      data.forEach(
        (item) => (item.tipo_template = ITENS.options.TIPO[item.tipo - 1]),
      );

      return data;
    },

    clear() {
      this.initializeFilters();
      this.getTemplates();
    },

    async getTemplates() {
      this.loading.table = true;
      const params = {
        page: this.page,
        tipo_template_id: this.filters.tipo,
        nome: this.filters.nome
      }
      const response = await TemplatesService.getTemplates(params);
      this.data = response.data.data;
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;

      // Verifica se a página atual é maior que a última página após a exclusão
      if (this.page > this.lastPage) {
        this.page = 1; // Define a página atual como 1
        await this.getTemplates(); // Chama novamente getTags para recarregar os dados da página 1
      }
      this.loading.table = false;
    },

    newItem() {
      this.$router.push("/templates/cadastrar");
    },

    editItem(item) {
      console.log(item)
      this.$router.push({
        name: "TemplateIdentificacao",
        params: {
          templateid: item.id,
          nome: item.nome,
          descricao: item.descricao,
          tipo_id: item.tipo_id
        },
      });
    },

    async deletarTemplate(templateid) {
      const response = await TemplatesService.deleteTemplates(templateid);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O template foi deletado com sucesso!");
      this.clear();
    },

    getOptions() {
      this.options.filterTipo = this.geraOptions(ITENS.options.TIPO, "Todos");
    },

    geraOptions(arrayOptions, defaultOption = "Selecione") {
      let options = [];
      options.push({ text: defaultOption, value: "" });

      arrayOptions.forEach((option, indexOption) => {
        options.push({ text: option, value: indexOption + 1 });
      });

      return options;
    },

    initializeFilters() {
      this.filters = {
        tipo: null,
        nome: null,
      };
    },
  },
};
