import api from "./Api"

export default {
  async cadastrarPagina(postData) {
    const response = await api.post('/pagina', postData)
        .then((response) => response)
        .catch(err => err);
         
    return response;
  },

  async getPagina() {
    const response = await api.get(`/pagina`)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async editarPagina(paginaid, postData) {
    const response = await api.put(`/pagina/${paginaid}`, postData)
        .then((response) => response)
        .catch(err => err);

    return response;
  },
};
