<template>
  <div>
    <v-app-bar
      height="90px"
      color="tertiary"
      class="py-20"
      app
      clipped-left
      src="@/assets/bg-topo.jpg"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="ml-3"
      ></v-app-bar-nav-icon>

      <v-spacer v-if="$vuetify.breakpoint.smAndDown ? true : false"></v-spacer>

      <v-img
        class="mr-4"
        src="@/assets/logo_cbmmg.png"
        contain
        max-width="120"
        max-height="70"
      ></v-img>

      <v-toolbar-title
        class="headline mt-2"
        v-if="$vuetify.breakpoint.smAndDown ? false : true"
      >
        Controle de Materiais
      </v-toolbar-title>


      <v-spacer></v-spacer>

      <span class="subtitle-2"> Usuário: {{userinfo.name}} - {{userinfo.preferred_username}}  <br/>
          Unidade: {{userinfo.groups[0]}}  </span>

      <div class="ms-8"> <Notify /> </div>

      <v-btn icon class="ms-3 me-3" @click="logout()">
        <v-icon color="secondary">mdi-export</v-icon>
      </v-btn>
      
    </v-app-bar>
    <Navigation :drawerClicked="drawer" />
  </div>
</template>

<script>
import Navigation from "@/components/navigation/Navigation.vue";
import Notify from "@/components/notify/Notify.vue";
import Vue from "vue";

export default {
  components: {
    Notify,
    Navigation
  },
  name: "Header",

  data: () => ({
    drawer: false,
    userinfo: [],
  }),

  async beforeMount(){
    const keycloak = Vue.prototype.$keycloak;
    this.userinfo = await keycloak.loadUserInfo();
  },

  methods: {
    logout(){
      this.$keycloak.logout();
    }
  }
};

</script>
