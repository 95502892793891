import api from "./Api"

export default {
  async getTags(params = "") {  
    const config = {params: params}
    const response = await api.get(`tag/search?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async getTagSemFiltro() {
    const response = await api.get(`/tag`)
      .then((response) => response)
      .catch(err => err);
      
    return response;
  },

  async cadastrarTag(postData) {
    const response = await api.post(`/tag`,postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async deleteTag(tagid) {
    const response = await api.delete(`tag/${tagid}`,)
      .then((response) => response)
      .catch(err => err);

    return response;
  },


  async editarTag(tagid, formData) {
    const response = await api.put(`tag/${tagid}`,formData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async verificaNome(formData) {  
    const response = await api.post(`/tag/verificar-nome`,formData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
};
