export default {
  name: "TextCheckboxField",
  props: {
    rules: {
      default: () => [],
      type: Array,
    },
    type: {
      default: "text",
      type: String,
    },
    suffix: {
      default: "",
      type: String,
    },
    counter: {
      default: false,
    },
    appendIcon: {
      default: "",
      type: String,
    },
    responsive: {
      default: () => {
        return {
          cols: "12",
          sm: "12",
          md: "12",
          lg: "12",
        };
      },
      type: Object,
    },
    vModel: {
      required: true,
    },
    vModelCheck: {
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
  },

  created() {
    this.value = this.vModel;
    this.checkvalue = this.vModelCheck;
  },

  methods: {
    emitAppend() {
      this.$emit("appendClicked");
    },
  },

  watch: {
    value() {
      this.$emit("input", this.value);
    },

    checkvalue() {
      this.$emit("inputCheck", this.checkvalue);
    },

    vModel() {
      this.value = this.vModel;
    },

    vModelCheck() {
      this.checkvalue = this.vModelCheck;
    },
  },

  data: () => ({
    value: null,
    checkvalue: null,
  }),
};
