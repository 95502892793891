import {
  SnackbarMixin,
  CSV
} from "../../../mixins";

import TextField from "../../fields/textField/TextField.vue";

export default {
  name: "uploadCsvPatrimonio",
  components: {
    TextField,
  },
  data: () => ({
    dialog: false,
    loading: false,
    file: [],
    arrayCsv: [],
  }),
  mixins: [SnackbarMixin, CSV],

  methods: {
    initializeForm() {
      this.file = [];
      this.arrayCsv = [];
    },


    async readTextFile() {
      const input = document.getElementById("fileCsvPatrimonio").files[0]

      const reader = new FileReader();

      reader.onload = async (e) => {
        const text = e.target.result;
        this.arrayCsv = await this.csvToArray(text);

      };

      reader.readAsText(input);


    },

    async save() {
      this.loading = true;
      await this.readTextFile()

      this.arrayCsv = this.arrayCsv.map(({patrimonio}) => patrimonio)

      this.$emit("update", this.arrayCsv)

      this.loading = false;

      // this.showSnackbar("O array foi lido com sucesso");
      this.initializeForm();
      this.dialog = false;


    },
  },
};