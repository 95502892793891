import BaseService from "./BaseService";
import api from "./Api"

export default {
  async getMarcas() {
    const response = await api.get(`/marca`)
    .then((response) => response)
    .catch(err => err);
    return response;
  },

  async cadastrarMarca(formData) {
    const postData = JSON.stringify(formData);
    const response = await BaseService.fetchResponseFromAPIPost(
      `marca`,
      postData,
    );

    return response;
  },
};
