import SelectField from "../selectField/SelectField.vue";
import TextField from "../textField/TextField.vue";
import TemplatesService from "../../../services/TemplatesService";

export default {
  name: "CampoSelectField",
  components: {
    SelectField,
    TextField,
  },
  props: {
    vModelTipo: {
      required: true,
    },
    vModelText: {
      required: true,
      type: Object,
    },
    vModelMultipleSelect: {
      required: true,
      type: Array,
    },
    vModelSingleSelect: {
      required: true,
      type: Array,
    },
    vModelUploadFiles: {
      required: true,
      type: Object,
    },
    rulesSelect: {
      default: () => [],
      type: Array,
    },
    validateForm: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    valueText: null,
    valueMultipleSelect: null,
    valueSingleSelect: null,
    valueUploadFiles: null,
    formValid: true,
    options: {},
    errors: [],
    fieldAddOptions: null,
    headers: [
      { text: "Opção", value: "nome", sortable: false },
      { text: "Ações", value: "actions", align: "center", sortable: false },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),

  created() {
    this.getMascara();
    this.getFormatoArquivo();
  },


  beforeMount() {
    this.valueText = this.vModelText;
    this.valueMultipleSelect = this.vModelMultipleSelect;
    this.valueSingleSelect = this.vModelSingleSelect;
    this.valueUploadFiles = this.vModelUploadFiles;
  },

  watch: {
    validateForm() {
      if (this.validateField()) {
        this.$emit("formIsValid", true);
      }
    },

    valueText(input) {
      this.$emit("inputText", input);
    },
    vModelText(input) {
      this.valueText = input;
    },

    valueMultipleSelect(input) {
      this.$emit("inputMultipleSelect", input);
    },
    vModelMultipleSelect(input) {
      this.valueMultipleSelect = input;
    },

    valueSingleSelect(input) {
      this.$emit("inputSingleSelect", input);
    },
    vModelSingleSelect(input) {
      this.valueSingleSelect = input;
    },

    valueUploadFiles(input) {
      this.$emit("inputUploadFiles", input);
    },

    vModelUploadFiles(input) {
      this.valueUploadFiles = input;
    },
  },

  methods: { 
  async getMascara() {
      const response = await TemplatesService.getMascara();
      this.options.CAMPOS_TEXT_MASCARA = this.geraOptions(
        response.data,
        "Selecione",
      );
  },

  async getFormatoArquivo() {
    const response = await TemplatesService.getFormato();
 
      this.options.CAMPOS_FILES_FORMAT = this.geraOptions(
        response.data,
        null
    );
       console.log(this.options.CAMPOS_FILES_FORMAT)
  },
    
  geraOptions(arrayOptions, defaultOption) {
    let options = [];
    if (defaultOption) {
      options.push({ text: defaultOption, value: "" });
    }
      arrayOptions.forEach((option) => {
        options.push({ text: option.nome, value: option.id });
      });

      return options;
    },

    addOption(selectName) {
      if (this.fieldAddOptions) {
        this[selectName].push({
          nome: this.fieldAddOptions,
        });
        this.fieldAddOptions = "";
      }
    },

    removeOption(selectName, item) {
      this[selectName].splice(this[selectName].indexOf(item), 1);
    },

    teste() {
      console.log(this.valueText);
    },

    validateField() {
      const errors = [];
      
        if(!this.$refs.formCampoPropriedades.validate()) {
          errors.push("Preencha os campos obrigatórios")
        }
        if (this.vModelTipo == 3) {
          if (this.valueMultipleSelect.length == 0) {
            errors.push(
              "É necessário adicionar pelo menos uma opção no campo de seleção múltipla",
            );
          }
        }

        if (this.vModelTipo == 2) {
          if (this.valueSingleSelect.length == 0) {
            errors.push(
              "É necessário adicionar pelo menos uma opção no campo de seleção única",
            );
          }
        }

        if (this.vModelTipo == 1) {
          if (
            this.valueText.mascara != "tel"
          ) {
            if (!this.valueText.maxLength || !this.valueText.mascara) {
              errors.push("Preencha os campos obrigatórios");
            }
          }
        }

        if (this.vModelTipo == 5) {
          if (this.valueUploadFiles.formatos.length == 0) {
            errors.push(
              "É necessário selecionar pelo menos um formato de arquivo",
            );
          }

          if (!this.valueUploadFiles.maxLength) {
            errors.push("Preencha os campos obrigatórios");
          }
        }
      

      this.$emit("errorsForm", errors);
      return errors.length == 0 ? true : false;
    },
  },
};
