import FormDialog from "../../../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import CampoSelectField from "../../../../components/fields/campoSelectField/CampoSelectField.vue";
import TextField from "../../../../components/fields/textField/TextField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
import { FiltroMixin, SnackbarMixin } from "../../../../mixins";
import TemplatesService from "../../../../services/TemplatesService";

import Vue from "vue";

export default {
  name: "CamposPage",
  components: {
    FormDialog,
    ConfirmDialogButton,
    CampoSelectField,
    TextField,
    SelectField,
  },
  mixins: [SnackbarMixin, FiltroMixin],
  data: () => ({
    form: {},
    showDialogConfirmPassword: false,
    formTitle: "",
    formType: "",
    formEditid: null,
    templateid: null,
    campoid: null,
    formValid: true,
    text: null,
    options: {},
    formAux: {
      text: {
        maxLength: "",
        mascara: "",
      },
      multipleSelect: [],
      singleSelect: [],
      uploadFiles: {
        maxLength: "",
        formatos: [],
      },
    },
    showFormDialog: false,
    validateForm: false,
    loadingFields: false,
    errors: [],
    data: [],
    headers: [
      { text: "Campo", value: "nome", sortable: false },
      // {
      //   text: "Obrigatório",
      //   value: "obrigatorio",
      //   sortable: false,
      // },
      // { text: "Tipo", value: "tipo", sortable: false },
      { text: "Ações", value: "actions", align: "center", sortable: false },
    ],
    loadingTable: true,
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    permissionsUser:[],

  }),

  created() {
    this.permissionsUser = Vue.prototype.$permissionsUser;
    console.log("CREATED")
    this.initializeForm();
    this.initializeFormAux();
    this.options = {
      tipo_campo_template_id: [],
      CAMPOS_OBRIGATORIO: [
        {
          text: "Selecione",
          value: "",
        },
        {
          text: "Sim",
          value: true,
        },
        {
          text: "Não",
          value: false,
        },
      ],
    };
  },

  mounted() {
    console.log("this.$route.params", this.$route.params)
    this.$emit("atualizaRotas", this.$route.params);
    this.templateid = this.$route.params.templateid;

    this.getOptions();


    this.getCampos(`template_id=${this.templateid}&`);
  },


  methods: {
    resetValidation() {
      this.$refs.formCampo.resetValidation();
      this.initializeForm();
      this.initializeFormAux();
    },

    async getOptions() {

      const response = await TemplatesService.getTipoCampo();
      this.options.imput = this.geraOptions(
        response.data,
        "Selecione",
      );
    },

    geraOptions(arrayOptions, defaultOption) {
      let options = [];
      options.push({ text: defaultOption, value: "" });

      arrayOptions.forEach((option) => {
        options.push({ text: option.nome, value: option.id });
      });

      return options;
    },

    async getCampos() {
      this.loadingTable = true;
        const params = {
          page: this.page,
          template_id: this.templateid,
      }
      const response = await TemplatesService.getCampos(params);
      this.data = response.data.data;
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;
      this.loadingTable = false;
    },

    initializeForm() {
      this.form = {
        template_id: this.templateid,
        tipo_campo_template_id: "",
        nome: "",
        obrigatorio: "",
        texto_ajuda: "",
        tamanho_maximo: "",
        formatos: [],
        mascara_id: "",
        opcoes: []
      };
    },

    initializeFormAux() {
      this.formAux = {
        text: {
          maxLength: "",
          mascara: "",
        },
        multipleSelect: [],
        singleSelect: [],
        uploadFiles: {
          maxLength: "",
          formatos: [],
        },
      };
    },

    async getDataForm(item) {
      this.loadingFields = true;
      this.form.nome = await item.nome;
      this.form.obrigatorio = await !!item.obrigatorio;
      this.form.tipo_campo_template_id = await item.tipo_campo_template_id;
      this.form.texto_ajuda = await item.texto_ajuda;

      if (this.form.tipo_campo_template_id == 3) {
        item.opcoes.forEach((opcoes) => {
          this.formAux.multipleSelect.push({nome:opcoes.nome });
        });
      } else if (this.form.tipo_campo_template_id == 2) {
        item.opcoes.forEach((opcoes) => {
           this.formAux.singleSelect.push({nome:opcoes.nome });
        });
      } else if (this.form.tipo_campo_template_id == 1) {
        this.formAux.text.maxLength = await item.tamanho_maximo;
        this.formAux.text.mascara = await item.mascara_id;
      } else if (this.form.tipo_campo_template_id == 5) {
        this.formAux.uploadFiles.maxLength = item.tamanho_maximo
        item.formatos.forEach((formato) => {
        this.formAux.uploadFiles.formatos.push(formato.tipo_template_formato_id);
        });
      }

      this.loadingFields = false;
    },


    async activeFormDialog(title, type, item = null) {
      this.resetValidation();
      this.formTitle = title;
      this.formType = type;
      this.showFormDialog = !this.showFormDialog;

      if (type == "Edit") {
        this.campoid = item.id;
        this.getDataForm(item);
      }
    },

    validateCampoForm() {
      this.errors = [];
      if (this.$refs.formCampo.validate()) {
        this.validateForm = !this.validateForm;
        return;
      }
      this.errors.push("Preencha os campos obrigatórios");
    },

    verificaOpAux(){

      if (this.form.tipo_campo_template_id == 3) {
        this.form.opcoes = this.formAux.multipleSelect;
      } else if (this.form.tipo_campo_template_id == 2) {
        this.form.opcoes = this.formAux.singleSelect;
      } else if (this.form.tipo_campo_template_id == 1) {
        this.form.tamanho_maximo = this.formAux.text.maxLength
        this.form.mascara_id = this.formAux.text.mascara
      } else if (this.form.tipo_campo_template_id == 5) {     
        this.form.tamanho_maximo = this.formAux.uploadFiles.maxLength
        this.formAux.uploadFiles.formatos.forEach((formato) => {
        this.form.formatos.push({ tipo_template_formato_id: formato});
        });
      }
    },

    async save() {
      this.verificaOpAux();
      const postData = this.form;
      let response;
      if (this.formType == "Edit") {
        response = await TemplatesService.editarCampo(this.campoid,postData,);
        if (!response.error) {
          this.showSnackbar("As alterações no template foram salvas com sucesso");
        }
      } else {
        response = await TemplatesService.cadastrarCampo(postData,);

        if (!response.error) {
          this.showSnackbar("O campo foi adicionado ao template com sucesso");
        }
      }
      this.showFormDialog = !this.showFormDialog;
      this.getCampos();
    },

    async deletarCampo(campoid) {
      await TemplatesService.deletarCampo(campoid);
      this.getCampos();
      this.showSnackbar("O campo foi deletado com sucesso");
    },

    goTo(url) {
      this.$router.push(url);
    },
  },
};
