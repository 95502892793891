import TextField from "../../../../components/fields/textField/TextField.vue";
import AutoCompleteField from "../../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
import MateriaisService from "../../../../services/MateriaisService";
import { AssinaturaMixin, SnackbarMixin } from "../../../../mixins";
import ConfirmPasswordDialog from "../../../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import FuncionalidadeEnum from "../../../../enums/FuncionalidadeEnum";

export default {
  name: "DialogDisponibilizar",
  components: {
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    ConfirmPasswordDialog
  },

  mixins: [AssinaturaMixin, SnackbarMixin],

  data: () => ({
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    FuncionalidadeEnum: FuncionalidadeEnum,
    send: {
      function: () => { },
      params: null,
    },

    dialog: false,
    formValid: true,
    form: {},
    options: {},
    menorQuantidade: "",
  }),

  props: {
    activeDialog: {
      default: true,
      type: Boolean
    },
    materiais: {
      default: () => [],
      type: Array
    }
  },

  created() {
    this.initializeForm();
  },

  methods: {
    setMaterialInfo(){
      const arrayDescricao = this.materiais.map(material => material.item.descricao);
      const descricoes = arrayDescricao.join(", ");
      this.form.material = descricoes;
      
      this.form.idMaterial = this.materiais.map(material => material.id);

      const item_tipo_id =     this.materiais.map(material => material.item_tipo_id);

      if(item_tipo_id != 1 ){
        this.menorQuantidade = this.materiais.map(material => material.quantidade).reduce((a, b) => Math.min(a, b));
      } else {
        this.menorQuantidade = 1
      }
      // this.form.tipo = this.materiais[0].item.tipo;

      
      const arrayCodigos = this.materiais.map(material => material.codigo_catmas);
      const codigos = arrayCodigos.join(", ");
      this.form.codigo = codigos;

      
      if(this.form.tipo == 1){
        this.form.patrimonio_serie = this.materiais[0].patrimonio_serie;
      }
    },

    initializeForm() {
      this.form = {
        material: "",
        codigo: "",
        idMaterial: [],
        patrimonio_serie: "",
        quantidade: "",
        tipo: "",
      };
    },

    async prepareForm(){
      const postData = {
        material_id: this.form.idMaterial,
      }

      if(this.form.tipo == 1){
        postData.quantidade = 1
      } else {
        postData.quantidade = this.form.quantidade;
      }
      
      return postData;
    },
    
    async disponibilizarMaterial(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();
      console.log(postData)
      const response = await MateriaisService.disponibilizarMaterialPost(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("O material foi disponibilizado com sucesso");
      this.initializeForm();
      this.$refs.form.resetValidation();
      this.$emit("updateData");

      this.dialog = false;
    },
  },
};
