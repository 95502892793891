import Vue from "vue";
import App from "./views/App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";

import router from "./router";
import Keycloak from "keycloak-js";
import moment from 'moment'
import {VueMaskDirective , VueMaskFilter } from 'v-mask'
import LocalStorageService from "./services/LocalStorageService";


Vue.prototype.moment = moment
Vue.filter('VMask', VueMaskFilter)
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

let VUE_APP_KEYCLOAK_URL = process.env.VUE_APP_KEYCLOAK_URL;
let VUE_APP_KEYCLOAK_REALM = process.env.VUE_APP_KEYCLOAK_REALM;
let VUE_APP_KEYCLOAK_CLIENT = process.env.VUE_APP_KEYCLOAK_CLIENT;

let initOptions = {
  url: VUE_APP_KEYCLOAK_URL,
  realm: VUE_APP_KEYCLOAK_REALM,
  clientId: VUE_APP_KEYCLOAK_CLIENT,
  onLoad: "login-required",
  
};

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

let keycloak = Keycloak(initOptions);

// new Vue({
//   vuetify,
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");

keycloak
  .init({ onLoad: initOptions.onLoad, redirectUri: window.location.href.replace(/(https?:\/\/[^\/]+).*/, '$1') }) //eslint-disable-line
  .then(  (auth) => {
    if (!auth) {
      window.location.reload();
    } else {

      new Vue({
          vuetify,
          router,
          store,
          render: (h) => h(App),
        }).$mount("#app"); 
        
    }

    LocalStorageService.set("token", keycloak.token)
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            Vue.prototype.$keycloak = keycloak;
            if(!!keycloak.token){ //eslint-disable-line
              LocalStorageService.set("token", keycloak.token)

            }
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    console.error("Authenticated Failed");
  });
Vue.prototype.$keycloak =  keycloak;


