export default {
  methods: {
    geraBlobCSV(base64str){
       // Crie um Blob com os dados do CSV
       const blob = new Blob([base64str], { type: 'text/csv' });

       // Crie um link para o Blob
       const link = document.createElement('a');
       link.href = window.URL.createObjectURL(blob);

       // Crie um nome de arquivo com a data e hora atual
       const dataHoraAtual = new Date().toLocaleString().replace(/[/\s,:]/g, '-');
       link.download = `arquivo_${dataHoraAtual}.csv`;

       // Adicione o link ao documento e clique nele para iniciar o download
       document.body.appendChild(link);
       link.click();

       // Remova o link após o download
       document.body.removeChild(link);

    },
  },
};
