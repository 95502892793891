import BaseService from "./BaseService";
import api from "./Api"

export default {
  async getTemplates(params = "") {
    const config = {params: params}
    const response = await api.get(`template/search?`, config)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  async getTemplatesSemFiltro() {
    const response = await api.get(`/template`)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  async deleteTemplates(templateid) {
   const response = await api.delete(`template/${templateid}`)
    .then((response) => response)
    .catch(err => err);

    return response;
  },

  async deletarCampo(campoid) {
    const response = await api.delete(`campoo-template/${campoid}`,)
    .then((response) => response)
    .catch(err => err);

    return response;
  },

  async cadastrarTemplate(postData) {
    const response = await api.post(`template`, postData)
    .then((response) => response)
    .catch(err => err);

    return response;
  },

  async editarTemplate(templateid, postData) {
    const response = await api.put(`template/${templateid}`, postData)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },
  
  async cadastrarCampo(postData) {
    const response = await api.post(  `campoo-template`, postData)
    .then((response) => response)
    .catch(err => err);

    return response;
  },

  async editarCampo(campoid, postData) { 
    const response = await api.put(`campoo-template/${campoid}`, postData)
      .then((response) => response)
      .catch(err => err);
    
      return response;
    },

  async getCampos(params = "") {
    const config = { params: params }
    const response = await api.get(`campoo-template/search?`, config)
      .then((response) => response)
      .catch(err => err);
    return response;
  },

  async copyTemplate(postData) {
    const response = await api.post(`template/clone`, postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
  
  async getMascara() {
    const response = await BaseService.fetchResponseFromAPIGet(`template/mascara`);
    return response.content;
  },

  async getFormato() {
    const response = await BaseService.fetchResponseFromAPIGet(`template/formato`);
    return response.content;
  },
  

   async getTipoCampo() {
    const response = await BaseService.fetchResponseFromAPIGet(`template/tipo-campo`);
    return response.content;
  },
};
