import moment from "moment";
import TextField from "../../../components/fields/textField/TextField.vue";
import MateriaisService from "../../../services/MateriaisService";
import { SnackbarMixin } from "../../../mixins";
import Vue from "vue";
import FuncionalidadeEnum from "../../../enums/FuncionalidadeEnum";

export default {
  name: "MateriaisDisponibilizadosSemAprovacao",
  components: {
    TextField
  },

  mixins: [SnackbarMixin],

  data: () => ({
    dialog: false,
    loading: {
      table: false,
      tableAll: false,
      recibo: false,
    },
    FuncionalidadeEnum: FuncionalidadeEnum,
    headers:[
      { text: "Material", value: "material_descricao", sortable: false },
      { text: "Código do Material", value: "codigo_catmas", sortable: false },
      { text: "Quantidade", value: "quantidade", sortable: false },
      { text: "Patrimonio/Série por", value: "material_patrimonio", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      { text: "Data", value: "created_at", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    
    filter: {
      unidade_descarte: "",
      unidade_descarga: "",
    },

    items: [],
    page: 1,
    pageCount: 1,
    lastPage:1,
  }),

  created() {
    this.getDisponibilizarMaterial();
    this.permissionsUser = Vue.prototype.$permissionsUser;

  },

  watch:{
    page (){
      this.getDisponibilizarMaterial();
    }
  },

  methods: {
    async getDisponibilizarMaterial() {
      this.loading.table = true;
      const params = {
        status_id: [1, 4], //Em Analise = 1, Unidade Solicitada = 4
        page: this.page
      };

      const response = await MateriaisService.disponibilizarMaterial(params);
      this.items = this.trataData(response.data.data);
      this.pageCount = response.data.total;
      this.lastPage = response.data.last_page;
      this.loading.table = false;
    },

    async confirmarMovimentacao(item) {
      this.loading.table = true;
      const postData = {
        material_disponibilizado_id: item.material_disponibilizados_id,
      };

      const response = await MateriaisService.aceitarMaterial(postData);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        this.loading.table = false;
        return;
      }
      
      this.showSnackbar("A aprovação foi realizada com sucesso");
      this.loading.table = false;
      
      this.getDisponibilizarMaterial();
    },

    async recusarDisponibilizacaoMaterial(item) {
      this.loading.table = true;

      const postData = {
        material_disponibilizado_id: item.material_disponibilizados_id,
      };

      const response = await MateriaisService.recusarMaterial(postData);
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }
      
      this.showSnackbar("A recusa foi realizada com sucesso");
      this.getDisponibilizarMaterial();
      this.loading.table = false;
    },
    

    trataData(data){
      let dataTratada = [];

      data.forEach((material) => {
        dataTratada.push(
          {
            material_id: material.material_id,
            material_descricao: material.descricao,
            material_quantidade: material.material_quantidade,
            material: material.descricao_catmas,
            material_disponibilizados_id: material.material_disponibilizados_id,
            codigo_catmas_id: material.catmas_id,
            codigo_catmas: material.codigo_catmas,
            descricao_catmas: material.descricao_catmas,
            unidade_id: material.unidade_id,
            unidade: material.unidade_codigo,
            created_at: moment(material.created_at).format("DD/MM/YYYY HH:mm"),
            material_patrimonio: material.material_patrimonio ? material.material_patrimonio : "",
            quantidade: material.quantidade
          }
        );
      });
      return dataTratada;
    },

  },
};
