import api from "./Api"

export default {
  async getCategorias(params = "") {  
    const config = {params: params}
    const response = await api.get(`categoria/search?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async getCategoriasSemFiltro() {  
    const response = await api.get(`/categoria`)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async cadastrarCategoria(formData) {
    const response = await api.post( `categoria`,formData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async deleteCategoria(categoriaid) {
    const response = await api.delete(`categoria/${categoriaid}`)
      .then((response) => response)
      .catch(err => err);

  return response;
  },

  async editarCategoria(categoriaid, postData) {
    const response = await api.put(`categoria/${categoriaid}`, postData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },

  async verificaNome(formData) {  
    const response = await api.post(`/categoria/verificar-nome`,formData)
      .then((response) => response)
      .catch(err => err);

    return response;
  },
};
