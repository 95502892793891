export default {
  name: "ConfirmDialogButton",
  props: {
    text: {
      required: true,
      type: String,
    },
    btnText: {
      required: true,
      type: String,
    },
    small: {
      default: true,
      type: Boolean,
    },
    typeText: {
      default: true,
      type: Boolean,
    },
    fieldRequired:{
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    confirmAction() {
      this.$emit("confirmAction");
      this.dialog = false;
    },

    recuseAction() {
      this.$emit("recuseAction");
      this.dialog = false;
    },
  },
};
