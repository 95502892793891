import TextField from "../../components/fields/textField/TextField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ConfirmDialogButton from "../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import ConfirmDialog from "../../components/dialog/confirm/ConfirmDialog.vue";
import CategoriasService from "../../services/CategoriasService";
import ConfirmPasswordDialog from "../../components/dialog/confirmPassword/ConfirmPasswordDialog.vue";
import { AssinaturaMixin, SnackbarMixin,VerificarTituloMixin } from "../../mixins";
import tipoAdminMixins from "../../mixins/tipoAdminMixins";
import subcategoriaMixins from "../../mixins/subcategoriaMixins";
import Vue from "vue";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";
import SelectCheckboxField from "../../components/fields/select-checkbox-field/SelectCheckboxField.vue";
import categoriaMixins from "../../mixins/categoriaMixins";

export default {

  mixins: [SnackbarMixin, AssinaturaMixin, VerificarTituloMixin, tipoAdminMixins, subcategoriaMixins, categoriaMixins],

  name: "Categoria",

  components: {SelectCheckboxField,
    TextField, SelectField, FormDialog, ConfirmDialogButton, ConfirmPasswordDialog, ConfirmDialog, AutoCompleteField},

  data: () => ({
    headers: [
      // { text: "Administrador", value: "tpMaterial", sortable: false },
      { text: "Categoria", value: "nome", sortable: false },
      { text: "Subcategoria", value: "subcategoria.nome", sortable: false },
      { text: "Data de modificação", value: "updated_at", sortable: false },
      { text: "Modificado por", value: "criado_por.nome", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    data: [],
    FuncionalidadeEnum: FuncionalidadeEnum,
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
    permissionsUser:[],
    editMode:false,
    showDialogName: false,
    formValid: true,
    formularioDialog: false,
    formTitle: "",
    formType: "",
    categoriaid: null,
    loading: {
      table: false,
      fields: false,
      newItem:false
    },
    filter: {},
    tituloExiste: false,
    tituloAnteriorValid: "",
    nomeOriginalEdit: "",
    showDialogConfirmPassword: false,
    formConfirmPassword: {
      password: ""
    },
    send: {
      function: () => { },
      params: null,
    },
    // filters: [],
  }),




  created() {
    this.initializeForm();
    this.initializeFilters();
    this.permissionsUser = Vue.prototype.$permissionsUser
  },
  mounted() {
    this.getCategoriasPesquisa();
  },

  watch:{
    page(){
      this.getCategoriasPesquisa();
    }
  },

  methods: {
  
    initializeForm() {
      this.form = {
        nome: "",
        subcategoria_id: "",
        administrador_id:""
      };
    },

    clear() {
      this.initializeFilters();
      this.getCategoriasPesquisa();
    },

    initializeFilters() {
      this.filter = {
        nome: null,
        categoria_id: null,
        subcategoria_id: null,
        updated_at: null,
        modificado_por: null,
        tipo_administrador_id: null
      };
    },

    async getCategoriasPesquisa(){
      this.loading.table = true;
      const params = {
        nome: this.filter.nome,
        categoria_id: this.filter.categoria_id,
        subcategoria_id: this.filter.subcategoria_id,
        updated_at: this.filter.updated_at,
        tipo_administrador_id: this.filter.tipo_administrador_id,
        modificado_por: this.filter.modificado_por,
        page: this.page
      }
      const response = await CategoriasService.getCategorias(params);
      this.data = response.data.data;
      this.pageCount = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      // Verifica se a página atual é maior que a última página após a exclusão
      if (this.page > this.lastPage) {
        this.page = 1; // Define a página atual como 1
        await this.getCategoriasPesquisa(); // Chama novamente getTags para recarregar os dados da página 1
      }

      this.loading.table = false;
    },

    search() {
      this.getCategoriasPesquisa();
    },

    async deletarCategoria(categoriaid) {
      try {
        const response = await CategoriasService.deleteCategoria(categoriaid);

        if (response.error) {
          this.showSnackbar(response.message.status.response, "error");
          return;
        } else {
          this.showSnackbar("A categoria foi deletada com sucesso!");
        }
      } finally {
        this.clear();
      }

    },

    async activeFormDialog(title, type, categoria = {}) {
      this.formTitle = title;
      this.formType = type;
      this.$refs.form.resetValidation();
      this.initializeForm();

      if(type == "Edit"){
        this.form.nome = categoria.nome
        this.nomeOriginalEdit = categoria.nome;
        this.form.subcategoria_id = categoria.subcategoria.id
        this.form.administrador_id = categoria.tipo_administrador.id
        this.categoriaid = categoria.id    
      }

      this.formularioDialog = !this.formularioDialog;
    },
 

    async editarCategoria(categoria_id) {
      if(!this.$refs.form.validate()){
        return;
      }
    
      const response = await CategoriasService.editarCategoria(
        categoria_id,
        this.form,
      );

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }
      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A edição da categoria foi salva com sucesso");
      this.clear();
      this.loading.newItem = false
    },

    async cadastrarCategoria() {
      if(!this.$refs.form.validate() || this.loading.newItem){
        return;
      }
      this.loading.newItem = true
      
      const response = await CategoriasService.cadastrarCategoria(this.form);
      if (response.error) {
        this.showSnackbar(response.data.errors.nome[0] ? response.data.errors.nome[0] : "Não foi possível completar a ação", "error");
        this.loading.newItem = false
        return;
      }

      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A categoria foi cadastrada com sucesso");
      this.clear();
      this.loading.newItem = false

    },  
    save() {     
      if (this.formType == "Edit") {
        this.editarCategoria(this.categoriaid);
      } else {
        this.cadastrarCategoria();
      }
    },

    async verificarNomeIgual() {
      if (this.form.nome !== this.tituloAnteriorValid && this.form.nome !== this.nomeOriginalEdit) {
        const postman = { nome: this.form.nome };
        const response = await CategoriasService.verificaNome(postman);
        this.tituloExiste = response.data;
        
      } else {
        this.tituloExiste  = false
      }

      this.tituloAnteriorValid = this.form.nome; 
    },

    goTo(path) {
      this.$router.push(path);
    },
  },
};
