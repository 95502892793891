export default {
  name: "PeriodoField",
  props: {
    vModelFirst: {
      required: true,
    },
    vModelLast: {
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    responsive: {
      default: () => {
        return {
          cols: "12",
          sm: "12",
          md: "12",
          lg: "12",
        };
      },
      type: Object,
    },
  },

  mounted() {
    this.valueFirst = this.vModelFirst;
    this.valueLast = this.vModelLast;
  },

  watch: {
    valueFirst() {
      this.$emit("inputFirst", this.valueFirst);
    },

    valueLast() {
      this.$emit("inputLast", this.valueLast);
    },

    vModelFirst() {
      this.valueFirst = this.vModelFirst;
    },

    vModelLast() {
      this.valueLast = this.vModelLast;
    },
  },

  data: () => ({
    valueFirst: null,
    valueLast: null,
  }),
};
