import VueApexCharts from 'vue-apexcharts'
import { FiltroMixin } from "../../mixins";
import materiaisMixins from "../../mixins/materiaisMixins";
import categoriaMixins from "../../mixins/categoriaMixins";
import tagsMixins from "../../mixins/tagsMixins";
import itemTipoMixins from "../../mixins/itemTipoMixins";

import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
import MapaCalorService from '../../services/MapaCalorService';
// import GrupoService from '../../services/GrupoService';
import UnidadeService from '../../services/UnidadeService';

// import moment from 'moment';

export default {
  name: "MapaCalor",

  data: () => ({
    loading: {
      page: false,
      categoria: false,
      tags: false,
    },
    series: [],
    options: {   
      chart: {
        height: 800,
        type: 'heatmap',
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#008FFB"],
    },
    items: [],

    params:{
      categoria_id: null,
      tag_id:null,
      material_id:null,
      tipo_material_id:null,
    },

    unidadesItems:[],
    unidades:[],
    itemsClear: [],
    headers:[
      { text: "Código", value: "codigo_catmas", sortable: false,class: 'light-blue font-weight-black black--text'},
      { text: "Descrição", value: "descricao", sortable: false,class: 'light-blue font-weight-black black--text'},
    ],

  }),

  mixins: [FiltroMixin, materiaisMixins, categoriaMixins, tagsMixins,itemTipoMixins],


  components: {
    AutoCompleteField,
    apexchart: VueApexCharts,
  },

  async created() {
    await this.getGruposArvoreRaiz();
    this.getMapaCalor();
  },

  
  methods: {

    colorBackground(item,valor){
      const value = item[valor.split(".")[1]]
      let style = {'background': '#ff4040', 'font-size':'18px','font-weight':'bolder'}

      const isTotalValue = valor.split(".")[1].search('quantidade_de_material')

      if(isTotalValue != -1){
        style['background'] = 'gray';
        return style;
      }
      
      if(value == 0){
        return style;
      }
      const ranges = {
        '#008000': 100-value,
        '#469536': 80-value,
        '#6eaa5e': 50-value,
        '#93bf85': 30-value,
        '#b7d5ac': 10-value,
        '#dbead5': 9-value,
      };

      style['background'] = this.numberCloseToZero(ranges);
      return style;

    },

    numberCloseToZero(object){
      return Object.keys(object).reduce(function(anterior, corrente) {
        return (Math.abs(object[corrente] - 0) < Math.abs(object[anterior] - 0) ? corrente : anterior);
      });   
    },

    async getGruposArvoreRaiz() {
      const response = await UnidadeService.getUnidadesComSubordinadas();
      this.unidades = response.data;
    },

    search(){
      this.getMapaCalor();

    },

    clear(){
      this.params={
        categoria_id: null,
        tag_id:null,
        material_id:null,
        tipo_material_id:null,
      }
      this.getMapaCalor()
    },



    async getMapaCalor(){

      const params = Object.fromEntries(
        Object.entries(this.params).filter(([_, v]) => v !== "" && v !== null) //eslint-disable-line
      ); 

      const response = await MapaCalorService.getMapCalor(params);
      this.items = this.tratarDados(response.data.data);
    },

    adicionarUnidadeNaTabela(unidade,nome, classes=''){
      const unidadeItem = `item.${unidade}`;

      if(!this.unidadesItems.includes(unidadeItem)){

        this.unidadesItems.push(unidadeItem);

        const adicionarHeader = {
          text: `${nome}`,
          value: unidade,
          sortable:false,
          class: classes,
        }
        this.headers.push(adicionarHeader);

      }
   
    },

    tratarDados(dados){
      let item = [];
      const colorTotal = 'orange font-weight-black black--text';
      const colorUnidade = 'orange lighten-3 font-weight-black black--text';
      dados.forEach((dado, index) => {
        item[index] = {
          codigo_catmas: dado.codigo,
          descricao: dado.descricao,
        }

        this.unidades.forEach((unidade) => {
          const unidadeTotal = `${unidade.codigo}total`;
          const nomeTotal = `${unidade.nome} - Total`;
          const quantidadeUnidadePai = parseInt(dado?.contagem_unidades[unidade.codigo]?.quantidade);
       
          let total = quantidadeUnidadePai;
          this.adicionarUnidadeNaTabela(unidadeTotal, nomeTotal, colorTotal)
          this.adicionarUnidadeNaTabela(unidade.codigo, unidade.nome, colorUnidade)

          unidade.subordinadas.forEach((subordinada) =>{
            if (dado.contagem_unidades[subordinada] == undefined){
              return ;
            }


            const nome =  dado.contagem_unidades[subordinada].nome_abreviado
            const quantidadeSubordinada = dado.contagem_unidades[subordinada].quantidade;

            this.adicionarUnidadeNaTabela(subordinada, nome, colorUnidade)
            
            item[index][subordinada] = quantidadeSubordinada;
            total += parseInt(quantidadeSubordinada);
          })

          item[index][unidadeTotal] = total;
          item[index][unidade.codigo] = quantidadeUnidadePai;

        })
      });
      return item;
    }

  

  
  }
};
