import BaseService from "./BaseService";
import api from "./Api"

export default {
  async getItens(params = "") {  
    const config = {params: params}
    const response = await api.get(`item/search?`, config)
      .then((response) => response)
      .catch(err => err);
    
      return response;
  },

  async getItensSemPaginacao() {  
    const response = await api.get(`/item`, )
      .then((response) => response)
      .catch(err => err);

      return response;
  },

  async deleteItem(id) {
    const response = await api.delete(`/item/${id}`)
    .then((response) => response)
    .catch(err => err); 

    return response;
  },

  async getItemByID(itemid) {
        const response = await api.get(`item/${itemid}`)
        .then((response) => response)
        .catch(err => err); 

        return response;
    },

  async getItemTipo() {  
    const response = await api.get(`item/tipo`)
      .then((response) => response)
      .catch(err => err);
      
      return response;
  },

  async getAdmType() {
    const response = await BaseService.fetchResponseFromAPIGet(`public/adm`);
    const data = response.error ? [] : response.result;

    return data;
  },
  
  async getCatmas() {
    const response = await BaseService.fetchResponseFromAPIGet(`codigo-catmas`);

    return response.content;
  },

  async cadastrarItem(postData) {
    const response = await api.post('/item', postData)
    .then((response) => response)
    .catch(err => err);

    return response;
  },

  async editarItem(itemid, formData) {
    const postData = JSON.stringify(formData);
    const response = await BaseService.fetchResponseFromAPIPut(
      `item/${itemid}`,
      postData,
    );

    return response;
  },

  async editarMultiplosItens(itemid, formData) {
    formData.id = itemid
    const postData = JSON.stringify(formData);
    const response = await BaseService.fetchResponseFromAPIPut(
      `item/update-multiple`,
      postData,
    );
  
    return response;
  },
};
