// import Vue from "vue";


export default {
  name: "Controle",

  data: () => ({
    items: [
      {
        text: "Gerenciar Movimentações",
        show:true,
        to: {
          name: "Movimentacoes",
        },
      },
  
      {
        text: "Histórico Movimentações",
        show: true,
        to: {
          name: "HistoricoMovimentacoes",
        },
      },
      {
        text: "Histórico Descarte Descarga",
        show: true,
        to: {
          name: "HistoricoDescarteDescarga",
        },
      },
    ],
  }),

};
