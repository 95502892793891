export default {
  name: "TextAreaField",
  props: {
    vModel: {
      required: true,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    counter: {
      default: false,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
  },

  created() {
    this.value = this.vModel;
  },

  watch: {
    value() {
      this.$emit("input", this.value);
    },

    vModel() {
      this.value = this.vModel;
    },
  },

  data: () => ({
    value: null,
  }),
};
