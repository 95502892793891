import Vue from "vue";
import BaseService from "./BaseService";

export default {
  async validaUsuario(formData, user=false) {
    const keycloak = Vue.prototype.$keycloak;
    const userinfo = await keycloak.loadUserProfile();
    formData.user = !user  ? userinfo.username : user;
    const postData = JSON.stringify(formData);
    const response = await BaseService.fetchResponseFromAPIPost(
      `keycloak/validausuario`,
      postData,
    );

    return response;
  },
}