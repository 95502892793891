import { SnackbarMixin } from "../../../mixins";
import MarcaService from "../../../services/MarcaService";
import TextField from "../../fields/textField/TextField.vue";

export default {
  name: "MarcaDialog",
  components: {
    TextField,
  },
  data: () => ({
    dialog: false,
    loading: false,
    form: {},
  }),
  mixins: [SnackbarMixin],

  methods: {
    initializeForm() {
      this.form = {
        nome: "",
      };
    },

    async save() {
      this.loading = true;
      const response = await MarcaService.cadastrarMarca(this.form);
      this.loading = false;
      
      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.formularioDialog = !this.formularioDialog;
      this.showSnackbar("A marca foi cadastrada com sucesso");
      this.initializeForm();
      this.$emit("update");
      this.dialog = false;
    },
  },
};
