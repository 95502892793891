// import { mapActions } from "vuex";


export default {
  data() {
    return {
      funcionalidadesLogado: [],
    };
  },

  created() {
    this.funcionalidadesLogado = this.$store.state.funcionalidades;
  },


  methods: {

    verificarFuncionalidade(funcionalidade_id) {

      return this.funcionalidadesLogado.includes(funcionalidade_id);
      
    },
  },
};
