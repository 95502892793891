import FormDialog from "../../components/dialog/form/FormDialog.vue";
import ModeloDialog from "../../components/dialog/modelo/ModeloDialog.vue";
import MarcaDialog from "../../components/dialog/marca/MarcaDialog.vue";
import TextField from "../../components/fields/textField/TextField.vue";
import TextCheckboxField from "../../components/fields/text-checkbox-field/TextCheckboxField.vue";
import SelectCheckboxField from "../../components/fields/select-checkbox-field/SelectCheckboxField.vue";
import AutoCompleteField from "../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../components/fields/file-input-field/FileInputField.vue";
import SelectField from "../../components/fields/selectField/SelectField.vue";
import ConfirmDialogButton from "../../components/dialog/confirmButton/ConfirmDialogButton.vue";
import { FiltroMixin, SnackbarMixin } from "../../mixins";
import funcionalidadeMixins from "../../mixins/funcionalidadeMixins";
import unidadesMixins from "../../mixins/unidadesMixins";
import emitirPDFMixins from "../../mixins/emitirPDFMixins";
import geraCSVMixins from "../../mixins/geraCSVMixins";
import Log from "../../services/Log";
import FuncionalidadeEnum from "../../enums/FuncionalidadeEnum";

export default {
  name: "RelatorioGeral",
  components: {
    FormDialog,
    TextField,
    AutoCompleteField,
    FileInputField,
    SelectField,
    TextCheckboxField,
    SelectCheckboxField,
    ModeloDialog,
    MarcaDialog,
    ConfirmDialogButton,
  },

  mixins: [FiltroMixin, SnackbarMixin, funcionalidadeMixins, unidadesMixins, emitirPDFMixins, geraCSVMixins],

  data: () => ({
    formularioDialog: false,
    formTitle: "",
    formType: "",

    loading: {
      table: false,
      marcas: false,
      modelos: false,
    },
    FuncionalidadeEnum: FuncionalidadeEnum,
    filter: {},

    form: {},
    unidade: [],

    options: {
      users: [],
      funcionalidades: [],
      unidades: [],
      filterTipo: [],
      categoriaMaterial: [],
      subcategoriaMaterial: [],
      templates: [],
      tags: [
        "Atendimento pré-hospitalar",
        "Operações aéreas",
        "Salvamento em altura",
      ],
    },

    headers: [
      {
        text: "Atividade",
        value: "funcionalidade.nome",
        sortable: false,
        width: 120,
      },
      // {
      //   text: "Item",
      //   value: "descricao",
      //   sortable: false,
      //   width: 120,
      // },
      {
        text: "Detalhes",
        value: "detalhes",
        sortable: false,
        width: 120,
      },
      {
        text: "Data de criação",
        value: "created_at",
        sortable: false,
        width: 120,
      },
      // {
      //   text: "Última data atualização",
      //   value: "dataAtualizacao", 
      //   sortable: false,
      //   width: 120,
      // },     // TODO verificar depois
      {
        text: "Unidade",
        value: "unidade.nome",
        sortable: false,
        width: 120,
      },
      {
        text: "Usuário",
        value: "sigrh.nome",
        sortable: false,
        width: 120,
      },      
    ],
    data: [],
    page: 1,
    pageCount: 0,
    lastPage: 1,
    itemsPerPage: 10,
  }),

  created() {
    this.initializeFilters();
    this.initializeForm();
  },

  mounted() {
    this.getLogs();
  },

   watch:{
    page() {
      this.getLogs();
    },
  },

  methods: {
    search() {
      this.getLogs();
    },

    initializeFilters() {
      this.filter = {     
        funcionalidade_id: "",
        unidade_id: "",
        usuario: "",
        detalhes: "",
        data_inicio: "",
        data_final: "",
      };
    },
   
    async getLogs(){
      this.loading.table = true;
      
      const params = {
        funcionalidade_id: this.filter.funcionalidade_id,
        detalhes: this.filter.detalhes,
        unidade_id: this.filter.unidade_id,
        usuario: this.filter.usuario,
        data_inicio: this.filter.data_inicio,
        data_final: this.filter.data_final,
        page: this.page
      }
      console.log( params)
      const response = await Log.getLog(params);
      this.data = response.data.data;
      this.pageCount = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;
      this.loading.table = false;
    },

    async gerarPDF() {
      try{
        this.loading.recibo = true;
        const data = await Log.getExportLogPDF(this.filter);

        this.exportBlob(data.data); 
      } finally {
        
        this.loading.recibo = false;
      }
    },

    async downloadCSV() {
      try {
        // Chame sua API que gera o arquivo CSV
        const response = await Log.getExportLogCSV(this.filter);
    
        this.geraBlobCSV(response.data)
      } catch (error) {
        console.error('Erro ao chamar a API', error);
      }
    },

    initializeForm() {
      this.form = {
        administrador: "",
        tipo: "",
        idMarca: "",
        idModelo: "",
        idCategoria: "",
        idTemplate: "",
        idSubcategoria: "",
        codigo_catmas: "",
        desc_catmas: "",
        descricao: "",
        tags: [],
      };
    },

  
    clear() {
      this.initializeFilters();
      this.getLogs();
    },
  },
};
