import PessoaService from "../services/PessoaService";
// import { mapActions } from "vuex";
import opcoesMixins from "../mixins/opcoesMixins";


export default {
  data() {
    return {
      pessoasOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.getPessoas()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async getPessoas() {
      const response = await PessoaService.getTodasPessoa();
      if (!response.error) {
        this.pessoasOpcoes = this.trataOptionsParaPessoas(response.data);

        // this.ActionSetTipoAdmin(this.tipoAdminOpcoes);
      }
    },


    verificarSeExistePessoa() {
      if (!this.$store.getters["opcoes/existe_pessoas"]) {
        return this.getTipoAlertas();
      }

      this.pessoasOpcoes = this.$store.state.opcoes.pessoas;
    },
  },
};
