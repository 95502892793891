import MateriaisService from "../services/MateriaisService";
// import { mapActions } from "vuex";
import opcoesMixins from "./opcoesMixins";


export default {
  data() {
    return {
      materiaisOpcoes: [],
    };
  },

  beforeMount() {
    // this.verificarSeExisteTipoAdmin()
    this.vergetMateriais()
  },

  mixins:[opcoesMixins],

  methods: {
    // ...mapActions("opcoes", ["ActionSetTipoAdmin"]),


    async vergetMateriais() {
      const response = await MateriaisService.getMateriaisSemPaginacao();
      if (!response.error) {
        this.materiaisOpcoes = this.trataOptionsParaMateriais(response.data);
        // this.ActionSetTipoAdmin(this.categoriaOpcoes);
      }
    },

    verificarSeExisteCategoria() {
      if (!this.$store.getters["opcoes/existe_materiais"]) {
        return this.getMateriais();
      }

      this.materiaisOpcoes = this.$store.state.opcoes.materiais;
    },
  },
};
