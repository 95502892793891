export const SnackbarMixin = {
  name: "snackbarMixin",
  methods: {
    showSnackbar(menssage, type = "success") {
      this.$store.commit("showDialog", { menssage, type });
    },
  },
};

export const AssinaturaMixin = {
  name: "assinaturaMixin",
  methods: {
    validaAssinatura(sendFunction, params = null) {
      this.initializeFormConfirmPassword();
      this.showDialogConfirmPassword = !this.showDialogConfirmPassword;
      this.send.function = sendFunction;
      this.send.params = params;
    },

    validaAssinaturaTwoSteps(sendFunction, params = null) {
      this.initializeFormConfirmPassword();
      this.showDialogConfirmPasswordTwoSteps = !this.showDialogConfirmPasswordTwoSteps
      this.send.function = sendFunction;
      this.send.params = params;
    },

    initializeFormConfirmPassword() {
      this.formConfirmPassword = {
        password: "",
        justificativa: "",
      };
    },
  },
};

export const FiltroMixin = {
  name: "filtroMixin",
  methods: {
    converteBase64(arrayObject) {
      const objJsonStr = JSON.stringify(arrayObject);
      const objJsonB64 = btoa(objJsonStr);

      return objJsonB64;
    },

    createMultipleFiltersSameId(filter){
      const filters = [{}];
      Object.keys(filter).forEach((key) => {
        if(typeof filter[key].value == 'object'){
          filter[key].value.forEach((id, index) =>{

            if (typeof filters[index] != 'object' ) {filters[index] = {}}
            filters[index][key] = {operator: filter[key].operator,value: id};
  
          });
        }
      });
  
      return filters
    },

    createUrlFiltersCustom(filter) {
      const filters = this.createMultipleFiltersSameId(filter)
      Object.keys(filter).forEach((key) => {
        if (filter[key].value && typeof filter[key].value != 'object') {

          filters.forEach((value,index)=>{
            value;
            filters[index][key] = {operator: filter[key].operator,value: filter[key].value};

          });
       

        }
      });

      const filterBase64 = this.converteBase64(filters);
      return Object.keys(filters[0]).length > 0 ? `querry=${filterBase64}` : "";
    }
  },
};

export const CSV = {
  name: "csv",
  methods: {
 
    csvToArray(str, delimiter = ",") {


      // slice from start of text to the first \n index
      // use split to create an array from string by delimiter
      const headers = str.toLowerCase().slice(0, str.indexOf("\n")).split(delimiter);
      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      const rows = str.slice(str.indexOf("\n") + 1).split("\n");
      rows.pop();


      // Map the rows
      // split values from each row into an array
      // use headers.reduce to create an object
      // object properties derived from headers:values
      // the object passed as an element of the array
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
          header = header.replace("\n", "").replace("\r", "");

          if(values[index] != undefined && values[index] != ""){
            object[header] = values[index].replace("\n", "").replace("\r", "");
          }
          return object;
        }, {});
        return el;
      });


      // return the array
      return arr;
    },
  },
};


export const VerificarTituloMixin = {
  name: "verificarTituloMixin",
  methods:{
    verificarTituloIgual(inputTag) {
      const nomeEdit = this.form.editNomeSave;
      const inputTagWithoutSpace = inputTag.replace(/\s/g, '')

      if(inputTag == "" || (this.formType == 'Edit' && (nomeEdit == inputTag || nomeEdit == inputTagWithoutSpace))){
        return false
      }

      const nomeRepetido = this.data.some((tag) => {

        let tagNomeWithoutSpace = tag.nome.replace(/\s/g, '')

        if(tag.nome == inputTag || tagNomeWithoutSpace == inputTagWithoutSpace){
          return true
        }

      });

      return nomeRepetido
    },

    verificaTitulo(editMode=false) {
      // const nomeEdit = this.form.editNomeSave;
      let inputTag = this.form.nome

      if(inputTag == ""){
        return false
      }

      const minLength = 3; // Define o comprimento mínimo para verificar a similaridade

      const nomeRepetido = this.data.some((tag) => {
      

        const inputWords = this.splitWords(inputTag);
        const tagWords = this.splitWords(tag.nome);

        return inputWords.some(inputWord => {
          if (inputWord.length < minLength) {
            return false;
          }

          return tagWords.some(tagWord => {
            if (tagWord.length < minLength) {
              return false;
            }

            return this.isSimilar(inputWord, tagWord);
          });
        });
      });
      
      this.editMode = editMode

      if(nomeRepetido){
        this.showDialogName = !this.showDialogName;
        return;
      }
      return this.save(editMode)
    },

    splitWords(str) {
      return str.toLowerCase().split(/\W+/);
    },
    
    isSimilar(word1, word2) {
      return word1.includes(word2) || word2.includes(word1);
    },
  }

};