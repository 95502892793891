import TextField from "../../../../components/fields/textField/TextField.vue";
import TextAreaField from "../../../../components/fields/textAreaField/TextAreaField.vue";
import AutoCompleteField from "../../../../components/fields/auto-complete/AutoCompleteField.vue";
import FileInputField from "../../../../components/fields/file-input-field/FileInputField.vue";
import RadioField from "../../../../components/fields/radio-field/RadioField.vue";
import SelectField from "../../../../components/fields/selectField/SelectField.vue";
import MateriaisService from "../../../../services/MateriaisService";
import { SnackbarMixin } from "../../../../mixins";

export default {
  name: "DialogDescarga",
  components: {
    TextField,
    AutoCompleteField,
    FileInputField,
    RadioField,
    SelectField,
    TextAreaField
  },

  data: () => ({
    dialog: false,
    formValid: true,
    form: {},
    options: {},
  }),

  props: {
    activeDialog: {
      default: true,
      type: Boolean
    },
    materiais: {
      default: () => [],
      type: Array
    }
  },

  mixins: [SnackbarMixin],

  created() {
    this.initializeForm();
  },

  methods: {
    setMaterialInfo(){
      this.form.idMaterial = this.materiais.map(material => material.id);
   
      const arrayPatrimonioSerie = this.materiais.map(material => material.patrimonio);
      const patrimonioSerie = arrayPatrimonioSerie.join(", ");
      this.form.materiais = this.materiais;
      this.form.patrimonio = patrimonioSerie;
    },

    initializeForm() {
      this.form = {
        idMaterial: [],
        patrimonio_serie: "",
        num_sei: "",
        num_sead: "",
        observacao: "",
      };
    },

    async prepareForm(){
      const postData = {
        id: this.form.idMaterial,
        SEI: this.form.num_sei,
        SEAD: this.form.num_siad,
        observacao: this.form.observacao,
      }
      
      return postData;
    },
    
    async descargaMaterial(){
      if(!this.$refs.form.validate()){
        return;
      }
      
      const postData = await this.prepareForm();

      const response = await MateriaisService.descargaMaterial(postData);

      if (response.error) {
        this.showSnackbar("Não foi possível completar a ação", "error");
        return;
      }

      this.showSnackbar("A descarga do material foi realizada com sucesso");
      this.initializeForm();
      this.$refs.form.resetValidation();
      this.$emit("updateData");

      this.dialog = false;
    },
  },
};
