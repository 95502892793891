import api from "./Api"
export default {
    async getCodigoCatmas() {
    const response = await api.get(`/codigo-catmas`)
    .then((response) => response)
    .catch(err => err);

    return response;
  },
};
